import { Plus } from "phosphor-react"
import theme from "../../../../../../styles/theme"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { ValidatePermission } from "../../../../../../utils/validatePermission"
import { Dashboard } from "../../../../entities/Dashboard"
import { useDashDropdownController } from "./controller/useDashDropdownController"
import * as S from "./styles"
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi"
import { PermissionsProteu } from "../../../../../../store/features/user/user.interfaces"

interface DashDropdownProps {
  selected: Dashboard
  dashboards: Dashboard[]
  onAddDashboard: () => void
  onChangeDashboard: (dashboard: Dashboard) => void
}

export const DashDropdown = ({
  selected,
  dashboards,
  onAddDashboard,
  onChangeDashboard,
}: DashDropdownProps) => {
  const { buttons } = useAppTranslate()

  const { toggleDropdown, isOpen } = useDashDropdownController()

  const handleChangeDashboard = (dashboard: Dashboard) => {
    if (selected.id === dashboard.id) return
    onChangeDashboard(dashboard)
    toggleDropdown()
  }

  const handleCreateDashboard = () => {
    onAddDashboard()
    toggleDropdown()
  }

  return (
    <S.CustomSelectContainer>
      <S.DropdownButton
        data-testid='drop-down__isOpen-btn'
        onClick={toggleDropdown}
      >
        <S.TitleDashboard>{selected.name}</S.TitleDashboard>
        <S.IconDropDown>
          {isOpen ? (
            <PiCaretUpBold color={theme.palette.gray[800]} size={18} />
          ) : (
            <PiCaretDownBold color={theme.palette.gray[800]} size={18} />
          )}
        </S.IconDropDown>
      </S.DropdownButton>
      <S.DropdownContainer>
        {isOpen && (
          <>
            <S.DropdownList data-testid='drop-down__list' $isopen={isOpen}>
              {dashboards.map((dashboard, index) => {
                return (
                  <S.DropdownItem
                    key={dashboard.id}
                    onClick={() => handleChangeDashboard(dashboard)}
                  >
                    {dashboard.name}
                  </S.DropdownItem>
                )
              })}
            </S.DropdownList>
            <S.DropdownItemBtn>
              <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
                <S.AddButton
                  data-testid='drop-down__add-btn'
                  onClick={handleCreateDashboard}
                >
                  {buttons.new_dashboard}
                  <Plus
                    size={16}
                    color={theme.palette.success.main}
                    weight={"bold"}
                  />
                </S.AddButton>
              </ValidatePermission>
            </S.DropdownItemBtn>
          </>
        )}
      </S.DropdownContainer>
    </S.CustomSelectContainer>
  )
}
