import styled from "styled-components"

interface ContainerProps {
  $graphics_line: number
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .highcharts-legend {
    overflow: auto;
  }

  .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }

  .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }

  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }

  .highcharts-data-table td,
  .highcharts-data-table th,
  .highcharts-data-table caption {
    padding: 0.5em;
  }

  .highcharts-data-table thead tr,
  .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }

  .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }
`

export const MainChart = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const MainAwaitData = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 22px;
    font-weight: bold;
  }
  #close {
    position: absolute;
    right: 0px;
    background: none;
    border: none;
    transform: 200ms linear;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  #config {
    position: absolute;
    right: 30px;
    background: none;
    border: none;
    transform: 200ms linear;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  #save-adl {
    position: absolute;
    right: 55px;
    top: -5px;
    background: none;
    border: none;
    transform: 200ms linear;
    /* margin-top: 10px; */
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
`

export const BodyChart = styled.div`
  height: 100%;
  width: 100%;
`

export const Button = styled.button`
  margin-top: 10px;
`

export const Footer = styled.footer`
  font-size: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 16px 0 0 0;
`

export const TextWrapper = styled.div<{ $align: "start" | "end" }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  width: 100px;
`
