import { FileArrowUp, Spinner } from "phosphor-react"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../../components/ui/Text"
import { Container, IconBackground } from "./styles"
import theme from "../../../../../../styles/theme"
import { ChangeEvent, useState } from "react"
import { useUploadAssetAttachMutation } from "../../../../../../store/store"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { toast } from "react-toastify"

interface AttachFileInputProps {
  assetId: number
  attachmentsLength: number
}

export const AttachFileInput = ({
  assetId,
  attachmentsLength,
}: AttachFileInputProps) => {
  const {
    assets: { attachments },
  } = useAppTranslate()

  const MAX_FILES = 3

  const [uploadAttach, mutation] = useUploadAssetAttachMutation()

  const [value, setValue] = useState<string>("")

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const invalidAttachmentsCount = attachmentsLength >= MAX_FILES

  const isInputDisabled =
    mutation.isLoading || isLoading || invalidAttachmentsCount

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (!files || files.length < 1) return

    const file = files[0]

    const fileValidTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/xml",
      "application/json",
      "text/csv",
      "application/vnd.ms-excel",
    ]

    if (!fileValidTypes.includes(file.type)) {
      toast.error(attachments.invalidFileTypeError)
      return
    }

    setIsLoading(true)

    await uploadAttach({
      assetId,
      file: files[0],
    })
      .unwrap()
      .then(() => {
        setValue("")
      })
      .catch((e) => {
        toast.error(attachments.toastError)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <FlexContainer align='start' fullWidth>
      <Container>
        <FlexContainer
          direction='row'
          justify='space-between'
          gap={16}
          fullWidth
        >
          <FlexContainer direction='row' gap={16}>
            <IconBackground $backgroundColor={theme.palette.gray[100]}>
              {isLoading ? (
                <Spinner size={24} color={theme.palette.gray[800]} />
              ) : (
                <FileArrowUp size={24} color={theme.palette.gray[800]} />
              )}
            </IconBackground>

            <FlexContainer align='start' gap={4}>
              <Text>{attachments.label}</Text>

              <input
                type='file'
                disabled={isInputDisabled}
                value={value}
                onChange={handleUpload}
                title={
                  invalidAttachmentsCount
                    ? attachments.maxAttachmentsError
                    : undefined
                }
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Container>
    </FlexContainer>
  )
}
