import { AssetDescription } from "../../../components/Description"
import { AssetProperties } from "../../../components/Properties"
import { AssetType } from "../../../components/Type"
import { Divider } from "../../../../../../components/ui/Divider"
import { Button } from "../../../../../../components/ui/Button"
import { formatedDate } from "../../../../../../utils/date"
import { Container, SensorContainer } from "./styles"
import { MessageContainer } from "../../styles"
import { AssetWithSensor } from "../../../components/AssetWithSensor"
import { AssetWithoutSensor } from "../../../components/AssetWithoutSensor"
import { AssetDetailsSkeleton } from "./Details.skeleton"
import {
  useFetchAssetAttachmentsQuery,
  useFetchAssetDetailsQuery,
} from "../../../../../../store/store"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { AttachFileInput } from "../AttachFileInput"
import { Text } from "../../../../../../components/ui/Text"
import { AttachedFile } from "../AttachedFile"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"

interface AsssetDetailsProps {
  assetId: number
}

const RenderAssetDetails = ({ assetId }: AsssetDetailsProps) => {
  const { assets, buttons } = useAppTranslate()

  const { data, isError, isFetching, isLoading, refetch } =
    useFetchAssetDetailsQuery(assetId)

  const { data: attachments } = useFetchAssetAttachmentsQuery(assetId)

  if (isFetching || isLoading) return <AssetDetailsSkeleton />

  if (isError || !data)
    return (
      <MessageContainer>
        {assets.details.error}
        <Button variant='link' onClick={refetch}>
          {buttons.reload}
        </Button>
      </MessageContainer>
    )

  const {
    id,
    description,
    name,
    assetType,
    createdAt,
    linkedSensor,
    properties,
  } = data

  return (
    <>
      <AssetDescription description={description} details={data} />

      <Divider />

      <AssetType
        typeId={assetType.id}
        typeName={assetType.name}
        createdAt={formatedDate(createdAt)}
      />

      <Divider />

      <AssetProperties properties={properties} />

      <Divider />

      <FlexContainer align='start' padding='0 16px' fullWidth>
        <Text fontWeight='bold'>{assets.attachments.title}</Text>
      </FlexContainer>

      {attachments && (
        <FlexContainer gap={4} padding='12px 16px 0 16px' fullWidth>
          <AttachFileInput
            assetId={id}
            attachmentsLength={attachments.length}
          />

          {attachments.map((file) => (
            <AttachedFile
              key={file.id}
              assetId={assetId}
              attachmentId={file.id}
              name={file.filename}
              sizeInBytes={file.size}
              url={file.url}
            />
          ))}
        </FlexContainer>
      )}

      <Divider />

      <SensorContainer>
        {linkedSensor && Object.keys(linkedSensor).length !== 0 ? (
          <AssetWithSensor
            sensor={linkedSensor}
            asset={{ id: assetId, name }}
          />
        ) : (
          <AssetWithoutSensor assetId={assetId} />
        )}
      </SensorContainer>
    </>
  )
}

const AssetDetails = ({ assetId }: AsssetDetailsProps) => {
  return (
    <Container>
      <RenderAssetDetails assetId={assetId} />
    </Container>
  )
}

export { AssetDetails, AssetDetailsSkeleton }
