import { FlexContainer } from "../../../../components/ui/FlexContainer"
import {
  ButtonItem,
  ChartWrapper,
  Container,
  Tooltip,
  TooltipWrapper,
} from "./styles"
import { Text } from "../../../../components/ui/Text"
import { DotsThreeOutlineVertical } from "phosphor-react"
import theme from "../../../../styles/theme"
import { ValidatePermission } from "../../../../utils/validatePermission"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"
import { cloneElement, useRef, useState } from "react"
import { ChartType } from "../../../../components/Chart/entities/ChartData"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ChartRef } from "../../../../components/NewChart"
import { ModalHedro } from "../../../../components/ModalHedro"
import { FooterFFT } from "./components/FooterFFT"
import { toast } from "react-toastify"

interface NewChartContainerProps {
  children: React.ReactNode
  chartsPerLine: number
  chartId: string
  title: string
  subtitle?: string
  type: ChartType
  onDownloadADL?: () => void
  onDelete: () => Promise<void>
  isDeleteDisabled: boolean
  hideToogleFFT?: boolean
}

export const NewChartContainer = ({
  children,
  chartsPerLine,
  chartId,
  title,
  subtitle,
  type,
  isDeleteDisabled,
  hideToogleFFT = false,
  onDelete,
  onDownloadADL = () => {},
}: NewChartContainerProps) => {
  const {
    buttons,
    chart: { deleteChart, options },
  } = useAppTranslate()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const ref = useRef<ChartRef>(null)

  const handleOpenFullscreen = () => {
    ref.current?.openFullscreen()
    setIsMenuOpen(false)
  }

  const handlePrintChart = () => {
    ref.current?.printChart()
    setIsMenuOpen(false)
  }

  const handleDownloadCSV = () => {
    ref.current?.downloadCSV()
    setIsMenuOpen(false)
  }

  const handleDownloadXLS = () => {
    ref.current?.downloadXLS()
    setIsMenuOpen(false)
  }

  const handleToggleFFT = () => {
    ref.current?.refetch()
  }

  const handleDeleteModal = () => {
    setOpenDeleteModal(true)
    setIsMenuOpen(false)
  }

  const handleDelete = () => {
    onDelete()
      .then(() => {
        toast.success(deleteChart.toastSuccess)
        setOpenDeleteModal(false)
      })
      .catch((error) => {
        console.error(error)
        toast.error(deleteChart.toastError)
      })
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <>
      {openDeleteModal && (
        <ModalHedro
          title={`${deleteChart.title} "${title}"?`}
          onClose={() => setOpenDeleteModal(false)}
          onLeftButton={() => setOpenDeleteModal(false)}
          leftButtonVariant='secondary'
          leftButtonLabel={buttons.cancel}
          onAction={handleDelete}
          actionLabel={buttons.delete}
          actionVariant='destructive'
          seconds={5}
          isActionsDisabled={isDeleteDisabled}
        >
          <Text>{deleteChart.message}</Text>
        </ModalHedro>
      )}
      <Container $chartsPerLine={chartsPerLine}>
        <FlexContainer fullHeight fullWidth padding='16px 16px'>
          <FlexContainer
            direction='row'
            align='center'
            justify='space-between'
            fullWidth
          >
            <FlexContainer align='start'>
              <Text fontWeight='semibold' color='gray.800'>
                {title}
              </Text>

              {subtitle && <Text color='gray.500'>{subtitle}</Text>}
            </FlexContainer>

            <FlexContainer>
              <DotsThreeOutlineVertical
                size={24}
                color={theme.palette.gray[500]}
                weight='fill'
                onClick={() => setIsMenuOpen((prev) => !prev)}
              />

              <TooltipWrapper $open={isMenuOpen}>
                <Tooltip>
                  <FlexContainer fullWidth>
                    <ButtonItem onClick={handleOpenFullscreen}>
                      <Text fontWeight='semibold'>{options.fullscreen}</Text>
                    </ButtonItem>

                    <ButtonItem onClick={handlePrintChart}>
                      <Text fontWeight='semibold'>{options.print}</Text>
                    </ButtonItem>

                    <ButtonItem onClick={handleDownloadCSV}>
                      <Text fontWeight='semibold'>{options.downloadCSV}</Text>
                    </ButtonItem>

                    <ButtonItem onClick={handleDownloadXLS}>
                      <Text fontWeight='semibold'>{options.downloadXLS}</Text>
                    </ButtonItem>

                    {type === ChartType.ADL && (
                      <ButtonItem onClick={onDownloadADL}>
                        <Text fontWeight='semibold'>{options.downloadADL}</Text>
                      </ButtonItem>
                    )}

                    <ValidatePermission
                      permission={PermissionsProteu.DashboardWrite}
                    >
                      <ButtonItem onClick={handleDeleteModal}>
                        <Text fontWeight='semibold' color='error.main'>
                          {options.delete}
                        </Text>
                      </ButtonItem>
                    </ValidatePermission>
                  </FlexContainer>
                </Tooltip>
              </TooltipWrapper>
            </FlexContainer>
          </FlexContainer>

          <ChartWrapper $chartsPerLine={chartsPerLine}>
            {cloneElement(children as React.ReactElement, { ref })}
          </ChartWrapper>

          {!hideToogleFFT && type === ChartType.TWO && (
            <FlexContainer padding='16px 0 0 0'>
              <FooterFFT chartId={chartId} onToggleFFT={handleToggleFFT} />
            </FlexContainer>
          )}
        </FlexContainer>
      </Container>
    </>
  )
}
