import { useEffect, useState } from "react"
import { ChartType } from "../../../../../components/Chart/entities/ChartData"
import { FftType } from "../../../../../utils/entities/FftType"

export const useChartContainerController = ({
  id,
  type,
}: {
  id: string
  type: ChartType
}) => {
  const [fftType, setFftType] = useState<FftType>(FftType.Acceleration)

  useEffect(() => {
    if (type === ChartType.TWO) {
      localStorage.setItem(`FFT-TYPE-${id}`, FftType.Acceleration)
    }
  }, [id, type])

  const handleToggleFFT = () => {
    if (fftType === "Velocity") {
      changeType(FftType.Acceleration)
    } else {
      changeType(FftType.Velocity)
    }
  }

  const changeType = (type: FftType) => {
    localStorage.setItem(`FFT-TYPE-${id}`, type)
    setFftType(type)
  }

  return {
    fftType,
    handleToggleFFT,
  }
}
