import { useCallback, useEffect, useState } from "react"
import { UseChartSeries } from "../../../components/NewChart"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { getHDRServiceAcronym } from "../../../utils/hdr_services_name"
import { MeasureUnit } from "../../../components/Chart/entities/Serie"
import { assetsApi } from "../../../store/api/assets/slice"
import { useAssetData } from "../../../utils/hooks/useAssetData"
import {
  ChartData,
  ChartType,
} from "../../../components/Chart/entities/ChartData"

export const useAssetSeries = (chart: ChartData): UseChartSeries => {
  const dispatch = useAppDispatch()

  const profile = useAppSelector((state) => state.persistedReducer.user.profile)
  const sensors = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const socket = useAppSelector((state) => state.persistedReducer.socket.socket)

  const {
    getProcessedDataFromApi,
    getPoints,
    getProcessData,
    getStartAndEndDate,
  } = useAssetData()

  const [series, setSeries] = useState<Highcharts.SeriesOptionsType[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const getSerieId = (assetId: number, type: number, config: string) =>
    `${assetId} - ${type} - ${config}`

  const getMeasureUnit = (
    type: HDR_SERVICES_TYPE,
    mac: string
  ): MeasureUnit => {
    switch (type) {
      case HDR_SERVICES_TYPE.health:
      case HDR_SERVICES_TYPE.temp:
      case HDR_SERVICES_TYPE.tempMMM:
      case HDR_SERVICES_TYPE.gps:
      case HDR_SERVICES_TYPE.ntc:
        return {
          y: "°C",
        }

      case HDR_SERVICES_TYPE.rms2:
        return {
          y: "m/s²",
        }
      case HDR_SERVICES_TYPE.fft:
        return {
          x: "Hz",
          y: "m/s²",
        }

      case HDR_SERVICES_TYPE.accRaw:
        return {
          x: "ms",
          y: "m/s²",
        }

      case HDR_SERVICES_TYPE.rmms:
        return {
          y: "mm/s",
        }

      case HDR_SERVICES_TYPE.tilt:
        return {
          y: "°",
        }

      case HDR_SERVICES_TYPE._4t20: {
        const extraConfig = localStorage.getItem(`${mac} - _4t20 - ExtraConfig`)
        const objEctg = JSON.parse(extraConfig!)
        if (!objEctg) return { y: "" }
        return {
          y: objEctg.unitOfMeasurement,
        }
      }
      case HDR_SERVICES_TYPE.pot: {
        const extraConfig = localStorage.getItem(`${mac} - pot - ExtraConfig`)
        const objEctg = JSON.parse(extraConfig!)
        if (!objEctg) return { y: "" }
        return {
          y: objEctg.unitOfMeasurement,
        }
      }
    }
  }

  const removePointsOutPeriod = (series: Highcharts.Series, period: number) => {
    for (let index = 0; index < series.points.length; index++) {
      const point = series.points[index]

      const passedTimeInSeconds = (Date.now() - point.x) / 1000

      if (passedTimeInSeconds > period) {
        series.removePoint(index, false)
      } else {
        //Since the points are ordered by date,
        //if a point is within the time period, all data that follows will also be within it
        break
      }
    }
  }

  const getMacOfLinkedSensor = useCallback(
    async (assetId: number): Promise<string | null> => {
      try {
        const details = await dispatch(
          assetsApi.endpoints.fetchAssetDetails.initiate(assetId)
        ).unwrap()

        if (!details.linkedSensor) return null

        const sensor = sensors.find(
          (sensor) => sensor.id === details.linkedSensor?.id
        )

        if (!sensor) return null

        return sensor.mac
      } catch (error) {
        console.error(error)
        return null
      }
    },
    [dispatch, sensors]
  )

  const fetchData = useCallback(async () => {
    setIsLoading(true)

    try {
      const seriesAux: Highcharts.SeriesOptionsType[] = []

      if (!chart.assets) return

      for (let i = 0; i < chart.assets.length; i++) {
        const asset = chart.assets[i]

        const mac = await getMacOfLinkedSensor(asset.id)

        for (const [key, value] of Object.entries(asset.services)) {
          const serviceType = Number(key) as HDR_SERVICES_TYPE

          const { startDate, endDate } = getStartAndEndDate(
            chart.period,
            serviceType
          )

          const chartData = (
            await getProcessedDataFromApi({
              serviceType,
              assetIds: [asset.id],
              startDate,
              endDate,
            })
          ).data

          if (!value.configs.length) {
            const points = getPoints({
              data: chartData,
              type: serviceType,
              chartId: chart.id,
            })

            seriesAux.push({
              type: "line",
              id: getSerieId(asset.id, Number(key), ""),
              name: `${asset.path} - ${getHDRServiceAcronym(Number(key))}`,
              data: points.value,
              custom: {
                measureUnit: mac ? getMeasureUnit(serviceType, mac) : "",
              },
            })
          }

          for (let j = 0; j < value.configs.length; j++) {
            const config = value.configs[j]

            const points = getPoints({
              data: chartData,
              type: serviceType,
              config,
              chartId: chart.id,
            })

            const time = chartData.length > 0 ? chartData[0].time : 0

            const date =
              serviceType === HDR_SERVICES_TYPE.fft ||
              serviceType === HDR_SERVICES_TYPE.accRaw
                ? time
                : undefined

            seriesAux.push({
              type: "line",
              id: getSerieId(asset.id, Number(key), config),
              name: `${asset.path} - ${getHDRServiceAcronym(Number(key))} (${config})`,
              data: points.value,
              custom: {
                date,
                measureUnit: mac ? getMeasureUnit(serviceType, mac) : "",
              },
            })
          }
        }
      }

      setSeries(seriesAux)
      setIsError(false)
    } catch (error) {
      console.error(error)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [
    chart.assets,
    chart.id,
    chart.period,
    getMacOfLinkedSensor,
    getPoints,
    getProcessedDataFromApi,
    getStartAndEndDate,
  ])

  useEffect(() => {
    fetchData()
  }, [chart.assets, dispatch, fetchData])

  const createWebSocketConnection = async (
    chart: ChartData,
    chartRef: Highcharts.Chart
  ) => {
    if (!profile) return

    const companyId = profile.user_data.x1

    if (!chart.assets) return

    for (let i = 0; i < chart.assets.length; i++) {
      const asset = chart.assets[i]

      const mac = await getMacOfLinkedSensor(asset.id)

      if (!mac) continue

      for (const [key, value] of Object.entries(asset.services)) {
        const serviceType = Number(key) as HDR_SERVICES_TYPE

        const event = `WS2CLIENT/${companyId}/${mac}/${serviceType}`

        if (!socket) {
          console.error("Socket not found")
          continue
        }

        socket.on(
          event,
          async (data: {
            applicationVersion: string
            collectorId: string
            companyId: string
            mac: string
            messageType: number
            organizationId: string
            productionSerialNumber: string
            protocolVersion: string
            raw: string
            rssi: number
            serviceType: number
            time: number
          }) => {
            const processedData = getProcessData(
              [
                {
                  applicationVersion: data.applicationVersion,
                  serviceType: data.serviceType,
                  protocolVersion: data.protocolVersion,
                  raw: data.raw,
                  rssi: data.rssi,
                  time: data.time,
                },
              ],
              serviceType
            ).data

            if (!value.configs.length) {
              const points = getPoints({
                data: processedData,
                type: serviceType,
                chartId: chart.id,
              })

              const serie = chartRef.get(
                getSerieId(asset.id, Number(key), "")
              ) as Highcharts.Series | undefined

              if (!serie) {
                console.error("Serie not found")
                return
              }

              for (let index = 0; index < points.value.length; index++) {
                serie.addPoint(points.value[index], false, false)
              }

              removePointsOutPeriod(serie, chart.period)
            }

            for (let j = 0; j < value.configs.length; j++) {
              const config = value.configs[j]

              const points = getPoints({
                data: processedData,
                type: serviceType,
                config,
                chartId: chart.id,
              })

              const serie = chartRef.get(
                getSerieId(asset.id, Number(key), config)
              ) as Highcharts.Series | undefined

              if (!serie) {
                console.error("Serie not found")
                return
              }

              if (
                chart.type === ChartType.TWO ||
                chart.type === ChartType.THREE
              ) {
                serie.setData(points.value, false, false)
              } else {
                removePointsOutPeriod(serie, chart.period)

                for (let index = 0; index < points.value.length; index++) {
                  serie.addPoint(points.value[index], false, false)
                }
              }

              chartRef.redraw()
            }

            chartRef.redraw()
          }
        )
      }
    }
  }

  const closeWebSocketConnection = async (chart: ChartData) => {
    if (!profile) return

    const companyId = profile.user_data.x1

    if (!chart.assets) return

    for (let i = 0; i < chart.assets.length; i++) {
      const asset = chart.assets[i]

      const mac = await getMacOfLinkedSensor(asset.id)

      if (!mac) continue

      for (const [key] of Object.entries(asset.services)) {
        const serviceType = Number(key) as HDR_SERVICES_TYPE

        const event = `WS2CLIENT/${companyId}/${mac}/${serviceType}`
        socket?.off(event)
      }
    }
  }

  const refetch = () => {
    fetchData()
  }

  return {
    series,
    isLoading,
    isError,
    createWebSocketConnection,
    closeWebSocketConnection,
    refetch,
  }
}
