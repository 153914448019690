import styled from "styled-components"

export const Content = styled.div`
  height: 100%;
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
  border-radius: 4px;
`

export const TableWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 52px);
  width: 100%;
`

export const Header = styled.div`
  background-color: ${({ theme }) => theme.palette.gray[200]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[400]};

  display: flex;
  gap: 12px;

  padding: 12px 16px;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 4px 0 16px;
`
