import { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import {
  dashboardSchema,
  DashboardSchema,
} from "../components/ModalDashboardConfig/utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
} from "../../../store/store"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { Dashboard } from "../entities/Dashboard"

export const useConfigDashboard = () => {
  const { dashboard } = useAppTranslate()

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  const isConfigureModalOpen = isCreateOpen || isEditOpen

  const form = useForm<DashboardSchema>({
    resolver: zodResolver(dashboardSchema),
    defaultValues: {
      layout: 1,
    },
  })

  const [createDashboard, createDashboardMutation] =
    useCreateDashboardMutation()

  const [updateDashboard, updateDashboardMutation] =
    useUpdateDashboardMutation()

  const isActionDisabled =
    createDashboardMutation.isLoading || updateDashboardMutation.isLoading

  const handleOpenCreateModal = useCallback(() => {
    setIsCreateOpen(true)
  }, [])

  const handleOpenEditModal = useCallback(
    (dashboard: Dashboard) => {
      form.setValue("name", dashboard.name)
      form.setValue("layout", dashboard.layout)

      setIsEditOpen(true)
    },
    [form]
  )

  const handleCloseModal = () => {
    if (isCreateOpen) setIsCreateOpen(false)
    if (isEditOpen) setIsEditOpen(false)
    form.reset()
  }

  const configure = async (
    data: DashboardSchema,
    dashboardId?: number
  ): Promise<Dashboard> => {
    if (isCreateOpen) {
      return await createDashboard(data).unwrap()
    }

    if (isEditOpen && dashboardId) {
      return await updateDashboard({
        dashboardId,
        body: data,
      }).unwrap()
    }

    throw new Error("Invalid action")
  }

  const configureDashboard = async (
    dashboardId?: number
  ): Promise<Dashboard> => {
    return new Promise((resolve, reject) => {
      form.handleSubmit(async (data) => {
        try {
          const configuredDashboard = await configure(data, dashboardId)

          toast.success(dashboard.configureDashboard.success)
          handleCloseModal()

          resolve(configuredDashboard)
        } catch (error) {
          console.error(error)
          toast.error(dashboard.configureDashboard.error)
          reject(error)
        }
      })()
    })
  }

  return {
    isConfigureModalOpen,
    isActionDisabled,
    isCreateOpen,
    isEditOpen,
    form,
    handleOpenCreateModal,
    handleOpenEditModal,
    handleCloseModal,
    configureDashboard,
  }
}
