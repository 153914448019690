import { Skeleton } from "@mui/material"
import {
  Content,
  Header,
  Actions,
  Container,
  Services,
  Details,
} from "./styles"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"

export const AssetWithSensorSkeleton = () => {
  return (
    <Content>
      <Header>
        <Skeleton variant='text' width={80} height={30} />
        <Actions>
          <Skeleton variant='rounded' height={22} width={22} />
        </Actions>
      </Header>
      <Details>
        <Container>
          <FlexContainer align='start'>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='text' width={100} />
          </FlexContainer>

          <Services>
            {Array.from({ length: 9 }, (_, index) => (
              <Skeleton variant='circular' height={32} width={32} key={index} />
            ))}
          </Services>
        </Container>

        <Skeleton variant='text' width={200} />
      </Details>
    </Content>
  )
}
