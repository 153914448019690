import { UseFormReturn } from "react-hook-form"
import { DashboardSchema, SCHEMA_ERRORS } from "./utils/schema"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { Input } from "../../../../components/ui/Input"
import { SelectLayoutOption } from "./components/SelectLayoutOption"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface ModalDashboardConfigProps {
  form: UseFormReturn<DashboardSchema>
}

export const ModalDashboardConfig = ({ form }: ModalDashboardConfigProps) => {
  const { dashboard } = useAppTranslate()

  const handleSelectLayoutOption = (index: number) => {
    form.setValue("layout", index)
  }

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.MIN_NAME:
        return dashboard.form.minName
      case SCHEMA_ERRORS.MAX_NAME:
        return dashboard.form.maxName
      default:
        return dashboard.form.required
    }
  }

  return (
    <FlexContainer direction='column' gap={12}>
      <Input
        label={dashboard.configureDashboard.nameLabel}
        placeholder={dashboard.configureDashboard.namePlaceholder}
        error={getErrorMessage(form.formState.errors.name?.message)}
        {...form.register("name")}
      />

      <SelectLayoutOption onChange={handleSelectLayoutOption} />
    </FlexContainer>
  )
}
