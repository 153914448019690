import { useForm, useWatch } from "react-hook-form"
import {
  chartSchema,
  ChartSchema,
  SCHEMA_ERRORS,
} from "../../../context/CreateChartContext"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export enum AnalyticPeriod {
  LAST_MINUTE = 60,
  LAST_10_MINUTES = 600,
  LAST_30_MINUTES = 1800,
  LAST_HOUR = 3600,
  LAST_5_HOURS = 18000,
  LAST_12_HOURS = 43200,
  LAST_DAY = 86400,
  LAST_WEEK = 604800,
}

export const useConfigureChart = ({
  defaultValues = {
    title: "",
    type: 1,
    orientedBy: "sensor",
    period: "",
  },
}: {
  defaultValues?: ChartSchema
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ChartSchema>({
    resolver: zodResolver(chartSchema),
    defaultValues,
  })

  const { modalAnalyticConfig, dashboard } = useAppTranslate()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.MIN_TITLE:
        return dashboard.configureChart.minTitle

      default:
        return dashboard.form.required
    }
  }

  const type = useWatch<ChartSchema>({ control, name: "type" })
  const orientedBy = useWatch<ChartSchema>({ control, name: "orientedBy" })

  const renderPeriodLabel = (period: string | number) => {
    switch (Number(period) as AnalyticPeriod) {
      case AnalyticPeriod.LAST_MINUTE:
        return modalAnalyticConfig.last_minute
      case AnalyticPeriod.LAST_10_MINUTES:
        return modalAnalyticConfig.last_10_minutes
      case AnalyticPeriod.LAST_30_MINUTES:
        return modalAnalyticConfig.last_30_minutes
      case AnalyticPeriod.LAST_HOUR:
        return modalAnalyticConfig.last_hour
      case AnalyticPeriod.LAST_5_HOURS:
        return modalAnalyticConfig.last_5_hours
      case AnalyticPeriod.LAST_12_HOURS:
        return modalAnalyticConfig.last_12_hours
      case AnalyticPeriod.LAST_DAY:
        return modalAnalyticConfig.last_day
      case AnalyticPeriod.LAST_WEEK:
        return modalAnalyticConfig.last_week
    }
  }

  const periods = Object.values(AnalyticPeriod)
    .filter((value) => !isNaN(Number(value)))
    .map((value) => Number(value))

  return {
    handleSubmit,
    getErrorMessage,
    register,
    setValue,
    renderPeriodLabel,
    errors,
    type,
    periods,
    orientedBy,
  }
}
