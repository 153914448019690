import styled from "styled-components"
import { media } from "../../../utils/media-queries"

export const MessageContainer = styled.div`
  font-size: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  justify-items: center;
  gap: 50px;
  justify-self: center;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  width: 100%;

  @media ${media.medium} {
    width: 650px;
  }
`
export const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: auto;
  gap: 20px;
`
