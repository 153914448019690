import { useContext } from "react"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"
import { useFetchAnalyticsQuery } from "../../../../store/store"
import { ValidatePermission } from "../../../../utils/validatePermission"
import {
  CreateChartContext,
  CreateChartContextDTO,
} from "../../context/CreateChartContext"
import { FloatingButton } from "../../../../components/Buttons/FloatingButton"
import { ModalWrapper } from "../ModalWrapper"
import { ContainerCharts } from "./styles"
import { NewChartContainer } from "../NewChartContainer"
import { ChartContainer } from "../ChartContainer"
import { useDeleteChart } from "../../hooks/useDeleteChart"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ContainerWrapper } from "../../styles"
import {
  ChartData,
  ChartType,
  OrientedBy,
} from "../../../../components/Chart/entities/ChartData"
import { NewChart } from "../../../../components/NewChart"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useAssetSeries } from "../../hooks/useAssetsSeries"
import { useReportADL } from "../../hooks/useReportADL"
import { Dashboard } from "../../entities/Dashboard"

interface DashboardWrapperProps {
  dashboard: Dashboard
}

export const DashboardWrapper = ({ dashboard }: DashboardWrapperProps) => {
  const translate = useAppTranslate()

  const { handleOpenConfigureChart } = useContext(
    CreateChartContext
  ) as CreateChartContextDTO

  const { handleDeleteChart, isDeleteDisabled } = useDeleteChart()

  const { handleSaveReportADL } = useReportADL()

  const {
    data: charts,
    isLoading: isLoadingAnalytics,
    isFetching: isFetchingAnalytics,
  } = useFetchAnalyticsQuery({
    dashboardId: dashboard.id,
  })

  const hasCharts = charts && charts.length > 0

  const isLoading = isLoadingAnalytics || isFetchingAnalytics

  const getXAxisTitle = (type: ChartType) => {
    if (type === ChartType.ADL) return ""

    return translate.chart.xAxisTitle[type]
  }

  const getYAxisTitle = (chart: ChartData) => {
    const typesSet = new Set<HDR_SERVICES_TYPE>()

    const services: HDR_SERVICES_TYPE[] = []

    if (!chart.assets) return "Valores"

    chart.assets.forEach((asset) => {
      Object.keys(asset.services).forEach((service) => {
        services.push(Number(service) as HDR_SERVICES_TYPE)
      })
    })

    services.forEach((service) => typesSet.add(service))

    //if you have several units mixed
    const typesArray = Array.from(typesSet)
    if (typesArray.length !== 1) return "Valores"

    const singleType = typesArray[0]
    return translate.chart.yAxisTitle[singleType]
  }

  return (
    <>
      <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
        <FloatingButton onClick={handleOpenConfigureChart} />
      </ValidatePermission>

      <ModalWrapper dashboardId={dashboard.id} />

      {hasCharts && !isLoading && (
        <ContainerCharts $graphics_line={dashboard.layout}>
          {charts.map((chart) => {
            if (chart.orientedBy === OrientedBy.ASSET) {
              return (
                <NewChartContainer
                  key={chart.id}
                  chartsPerLine={dashboard.layout}
                  chartId={chart.id}
                  onDelete={async () => {
                    await handleDeleteChart(chart.id, dashboard.id)
                  }}
                  title={chart.title}
                  type={chart.type}
                  isDeleteDisabled={false}
                >
                  <NewChart
                    chart={chart}
                    xAxisType={chart.type === 1 ? "datetime" : "linear"}
                    useChartSeries={useAssetSeries}
                    xAxisTitle={getXAxisTitle(chart.type)}
                    yAxisTitle={getYAxisTitle(chart)}
                  />
                </NewChartContainer>
              )
            }

            return (
              <NewChartContainer
                key={chart.id}
                chartsPerLine={dashboard.layout}
                chartId={chart.id}
                title={chart.title}
                type={chart.type}
                onDelete={async () => {
                  await handleDeleteChart(chart.id, dashboard.id)
                }}
                onDownloadADL={() => {
                  handleSaveReportADL()
                }}
                isDeleteDisabled={isDeleteDisabled}
                hideToogleFFT
              >
                <ChartContainer
                  graphics_line={dashboard.layout}
                  chart={chart}
                />
              </NewChartContainer>
            )
          })}
        </ContainerCharts>
      )}

      {!hasCharts && !isLoading && (
        <ContainerWrapper>
          <FlexContainer>
            {translate.dashboard.no_graphics_configured}
          </FlexContainer>
          {translate.dashboard.information_to_add_graph}
        </ContainerWrapper>
      )}
    </>
  )
}
