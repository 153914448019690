import { useCallback, useState } from "react"
import { useDeleteDashboardMutation } from "../../../store/store"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const useDeleteDashboard = () => {
  const { dashboard } = useAppTranslate()

  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const [deleteMutation, mutation] = useDeleteDashboardMutation()

  const isDeleteDisabled = mutation.isLoading

  const handleOpenDeleteModal = useCallback(() => {
    setIsDeleteOpen(true)
  }, [])

  const handleCloseDeleteModal = () => {
    setIsDeleteOpen(false)
  }

  const deleteDashboard = async (dashboardId: number) => {
    try {
      await deleteMutation(dashboardId).unwrap()
      toast.success(dashboard.deleteDashboard.success)
      handleCloseDeleteModal()
    } catch (error) {
      console.error(error)
      toast.error(dashboard.deleteDashboard.error)
    }
  }

  return {
    isDeleteOpen,
    isDeleteDisabled,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteDashboard,
  }
}
