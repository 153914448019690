import { HDR_SERVICES_TYPE } from "hdr-process-data"
import {
  ChartType,
  Service,
} from "../../../../components/Chart/entities/ChartData"
import { Axis } from "../../../../utils/entities/Axis"
import { left, Result, right } from "../../../../utils/either/result"
import {
  AnalyticsSensors,
  ExtraConfig,
} from "../../../../pages/Dashboard/entities/Analytics"

interface ServicesObject {
  [mac: string]: {
    [service: number]: {
      name: string
      axisData?: {
        axis?: Axis
        configs?: ExtraConfig
      }[]
    }
  }
}

export const getChartServices = (
  sensors: AnalyticsSensors,
  chartType: number
): Service[] => {
  const services: Service[] = []
  const servicesObject: ServicesObject = {}

  const processService = (service: string) => {
    const serviceType = convertServiceNameToServiceType(service)

    if (serviceType.isLeft()) {
      console.error(serviceType.value)
      return
    }

    const _sensors = sensors[service]

    for (let k = 0; k < _sensors.length; k++) {
      const sensor = _sensors[k]
      const mac = sensor.mac
      const axis = getServiceAxis(service)

      const configs = sensor.configs

      if (!servicesObject[mac]) {
        servicesObject[mac] = {}
      }

      if (!servicesObject[mac][serviceType.value]) {
        const data: {
          axis?: Axis
          configs?: ExtraConfig
        } = {}

        if (configs) {
          data.configs = configs
        }

        if (axis) {
          data.axis = axis
        }

        servicesObject[mac][serviceType.value] = axis
          ? { axisData: [data], name: sensor.name }
          : { name: sensor.name }
        continue
      }

      if (axis) {
        if (configs) {
          servicesObject[mac][serviceType.value].axisData?.push({
            axis,
            configs,
          })
        } else {
          servicesObject[mac][serviceType.value].axisData?.push({ axis })
        }
      }
    }
  }

  Object.keys(sensors).forEach(processService)

  Object.keys(servicesObject).forEach((mac) => {
    Object.keys(servicesObject[mac]).forEach((service) => {
      const type = Number(service)
      const axisData = servicesObject[mac][type]?.axisData

      services.push({
        mac,
        name: servicesObject[mac][type].name,
        type: chartType === ChartType.ADL ? undefined : type,
        axisData,
      })
    })
  })

  return services
}

const getServiceAxis = (serviceName: string): Axis | undefined => {
  switch (serviceName) {
    case "rms2x":
    case "rms2xOneAxis":
    case "rmmsx":
    case "rmmsxOneAxis":
    case "fftx":
    case "fftxOneAxis":
    case "accRawx":
    case "accRawxOneAxis":
      return "x"
    case "rms2y":
    case "rms2yOneAxis":
    case "rmmsy":
    case "rmmsyOneAxis":
    case "ffty":
    case "fftyOneAxis":
    case "accRawy":
    case "accRawyOneAxis":
      return "y"
    case "rms2z":
    case "rms2zOneAxis":
    case "rmmsz":
    case "rmmszOneAxis":
    case "fftz":
    case "fftzOneAxis":
    case "accRawz":
    case "accRawzOneAxis":
      return "z"
    case "rms2VectorModule":
    case "rmmsVectorModule":
    case "fftVectorModule":
    case "accRawVectorModule":
      return "module"
    case "tiltroll":
      return "roll"
    case "tiltpitch":
      return "pitch"
    case "pota":
    case "potaOneChannel":
    case "ntca":
    case "ntcaOneChannel":
    case "_4t20a":
    case "_4t20aOneChannel":
      return "a"
    case "potb":
    case "potbOneChannel":
    case "ntcb":
    case "ntcbOneChannel":
    case "_4t20b":
    case "_4t20bOneChannel":
      return "b"

    default:
      // Some services does not have axis
      return undefined
  }
}

const convertServiceNameToServiceType = (
  serviceName: string
): Result<Error, HDR_SERVICES_TYPE> => {
  if (serviceName === "adl") return right(HDR_SERVICES_TYPE.temp)

  switch (serviceName) {
    case "temp":
      return right(HDR_SERVICES_TYPE.temp)
    case "rms2x":
    case "rms2xOneAxis":
    case "rms2y":
    case "rms2yOneAxis":
    case "rms2z":
    case "rms2zOneAxis":
    case "rms2VectorModule":
      return right(HDR_SERVICES_TYPE.rms2)
    case "rmmsx":
    case "rmmsxOneAxis":
    case "rmmsy":
    case "rmmsyOneAxis":
    case "rmmsz":
    case "rmmszOneAxis":
    case "rmmsVectorModule":
      return right(HDR_SERVICES_TYPE.rmms)
    case "tiltroll":
    case "tiltpitch":
      return right(HDR_SERVICES_TYPE.tilt)
    case "fftx":
    case "fftxOneAxis":
    case "ffty":
    case "fftyOneAxis":
    case "fftz":
    case "fftzOneAxis":
    case "fftVectorModule":
      return right(HDR_SERVICES_TYPE.fft)
    case "accRawx":
    case "accRawxOneAxis":
    case "accRawy":
    case "accRawyOneAxis":
    case "accRawz":
    case "accRawzOneAxis":
    case "accRawVectorModule":
      return right(HDR_SERVICES_TYPE.accRaw)
    case "pota":
    case "potaOneChannel":
    case "potb":
    case "potbOneChannel":
      return right(HDR_SERVICES_TYPE.pot)
    case "ntca":
    case "ntcaOneChannel":
    case "ntcb":
    case "ntcbOneChannel":
      return right(HDR_SERVICES_TYPE.ntc)
    case "_4t20a":
    case "_4t20aOneChannel":
    case "_4t20b":
    case "_4t20bOneChannel":
      return right(HDR_SERVICES_TYPE._4t20)
  }

  return left(Error(`Invalid service name! {${serviceName}}`))
}
