import {
  Control,
  useFieldArray,
  useForm,
  UseFormRegister,
  useWatch,
} from "react-hook-form"
import { Input } from "../../../../../components/ui/Input"
import {
  assetSchema,
  AssetSchema,
  SCHEMA_ERRORS,
  getDefaultValues,
} from "../../utils/schema"
import {
  BasicInfo,
  AssetInfo,
  Content,
  PropertiesMessage,
  PropertiesFields,
  InputContainer,
  InputWrapper,
} from "./styles"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Divider } from "../../../../../components/ui/Divider"
import { TextArea } from "../../../../../components/ui/TextArea"
import { AssetFormSkeleton } from "./AssetForm.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Text } from "../../../../../components/ui/Text"
import { useFetchAssetTypesQuery } from "../../../../../store/store"
import { InputSelect } from "../../../../../components/ui/InputSelect"
import { useProperties } from "../../utils/useProperties"
import { LinkSensorForm } from "../LinkSensorForm"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  AssetDetailsData,
  AssetType,
} from "../../../../../utils/entities/assets"
import { Button } from "../../../../../components/ui/Button"
import { ImageContainer } from "../ImageContainer"

interface AssetFormProps {
  defaultDetails?: AssetDetailsData
  isRoot?: boolean
  isDisabled: boolean
  isCreating?: boolean
  handleSubmit: (
    data: AssetSchema,
    picture: File | null | undefined
  ) => Promise<void>
  handleCancel: () => void
}

const AssetForm = ({
  defaultDetails,
  isRoot = false,
  isDisabled,
  isCreating = false,
  handleSubmit,
  handleCancel,
}: AssetFormProps) => {
  const { assets, buttons } = useAppTranslate()

  const [picture, setPicture] = useState<File | null | undefined>(undefined)

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
  })

  useEffect(() => {
    if (!defaultDetails) return
    const value = getDefaultValues(defaultDetails)
    form.reset(value)
  }, [defaultDetails, form])

  const { getDefaultPropertyName } = useProperties()

  const { data: types, isLoading, isError } = useFetchAssetTypesQuery()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const {
    control,
    register,
    formState: { errors },
    setFocus,
  } = form

  const date = useWatch({
    control,
    name: "linkedAt",
  })

  const handleSelectSensor = (id: number) => {
    form.setValue("sensorId", id)
  }

  const handleChangePicture = (file: File | null) => {
    if (file) {
      setPicture(file)
      return
    }

    if (file === null && defaultDetails?.assetPicture) {
      setPicture(null)
      return
    }

    setPicture(undefined)
  }

  const options = useMemo(() => {
    if (!types) return []

    return types.map((type) => type.id)
  }, [types])

  const findTypeName = (type: number): string => {
    if (!types) return ""

    const assetType = types.find((item) => item.id === type)

    if (!assetType) return ""

    return assetType.name
  }

  const renderLabel = (type: number): string => {
    const defaultname = getDefaultPropertyName(type)
    if (defaultname) return defaultname

    return findTypeName(type)
  }

  const handleFocus = useCallback(() => {
    setFocus("type")
  }, [setFocus])

  if (isLoading) return <AssetFormSkeleton />

  if (!types || isError)
    return (
      <FlexContainer gap={12}>
        <Text>{assets.form.errors.types}</Text>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.back}
        </Button>
      </FlexContainer>
    )

  return (
    <>
      <AssetInfo>
        <ImageContainer
          src={defaultDetails?.assetPicture ?? undefined}
          editable
          onChange={handleChangePicture}
        />

        <BasicInfo>
          <Text fontSize='md' fontWeight='bold'>
            {assets.form.titles.informations}
          </Text>
          <InputContainer>
            <InputWrapper>
              <Input
                label={assets.form.name}
                placeholder={assets.form.namePlaceholder}
                error={getErrorMessage(errors.name?.message)}
                {...register("name")}
              />
            </InputWrapper>

            {!isRoot && (
              <InputWrapper>
                <InputSelect
                  label={assets.details.type}
                  placeholder={assets.form.addTypePlaceholder}
                  options={options}
                  error={getErrorMessage(errors.type?.message)}
                  renderLabel={(option) => renderLabel(Number(option))}
                  {...register("type")}
                />
              </InputWrapper>
            )}
          </InputContainer>
        </BasicInfo>
      </AssetInfo>

      <TextArea
        label={assets.details.description}
        rows={10}
        placeholder={assets.form.descriptionPlaceholder}
        error={getErrorMessage(errors.description?.message)}
        {...register("description")}
      />

      <Divider />

      {types && (
        <Properties
          register={register}
          control={control}
          types={types}
          defaultDetails={defaultDetails!}
          onFocus={handleFocus}
        />
      )}

      <Divider />

      {isCreating && (
        <Content>
          <Text fontSize='md' fontWeight='bold'>
            Sensor
          </Text>

          <LinkSensorForm
            onCheck={(id) => handleSelectSensor(Number(id))}
            dateInputProps={register("linkedAt")}
            dateInputError={errors.linkedAt && assets.form.errors.required}
            dateInputIsEmpty={!date}
          />
          <Text color='error.main' fontSize='xs'>
            {errors.sensorId && assets.form.errors.required}
          </Text>
        </Content>
      )}

      <FlexContainer direction='row' justify='space-between' fullWidth>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.cancel}
        </Button>

        <Button
          variant='primary'
          onClick={form.handleSubmit((data) => {
            handleSubmit(data, picture)
          })}
          disabled={isLoading || isDisabled}
        >
          {assets.form.saveChanges}
        </Button>
      </FlexContainer>
    </>
  )
}

interface PropertiesProps {
  register: UseFormRegister<AssetSchema>
  control: Control<AssetSchema>
  types: AssetType[]
  defaultDetails: AssetDetailsData
  onFocus: () => void
}

export const Properties = ({
  register,
  control,
  types,
  defaultDetails,
  onFocus,
}: PropertiesProps) => {
  const { assets } = useAppTranslate()

  const type = useWatch({
    control,
    name: "type",
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  useEffect(() => {
    onFocus()
  }, [fields, onFocus])

  useEffect(() => {
    if (!type) return
    if (!types) return

    remove()

    const assetType = types.find((item) => item.id === Number(type))

    if (assetType?.id === defaultDetails?.assetType.id) {
      const values = Object.keys(defaultDetails.properties).map((key) => {
        return {
          property: key,
          value: defaultDetails.properties[key],
        }
      })

      values.forEach((property) => {
        append(property)
      })

      return
    }

    if (!assetType) return

    const properties = Object.keys(assetType.properties)

    for (let i = 0; i < properties.length; i++) {
      append({ property: properties[i], value: "" })
    }
  }, [remove, append, type, types, defaultDetails])

  const { getPropertyTranslation } = useProperties()

  const getPropertyText = (property: string): string => {
    const translatedProperty = getPropertyTranslation(Number(type), property)
    if (translatedProperty) return translatedProperty

    return property.replaceAll("_", " ")
  }

  return (
    <Content>
      <Text fontSize='md' fontWeight='bold'>
        {assets.form.titles.properties}
      </Text>

      {!type && (
        <PropertiesMessage>
          <Text fontSize='sm' fontWeight='regular'>
            {assets.form.propertiesPlaceholder}
          </Text>
        </PropertiesMessage>
      )}

      {fields.length === 0 && type && (
        <PropertiesMessage>
          <Text fontSize='sm' fontWeight='regular'>
            {assets.form.propertiesEmpty}
          </Text>
        </PropertiesMessage>
      )}

      {fields.length > 0 && (
        <PropertiesFields>
          {fields.map((field, index) => {
            return (
              <Input
                key={field.id}
                label={getPropertyText(field.property)}
                placeholder={getPropertyText(field.property)}
                {...register(`properties.${index}.value`)}
              />
            )
          })}
        </PropertiesFields>
      )}
    </Content>
  )
}

export { AssetForm, AssetFormSkeleton }
