import { CloudX } from "@phosphor-icons/react"
import { Button } from "../Button"
import { IconComponent } from "../Icon"
import theme from "../../../styles/theme"
import { InfoContainer, MessageContainer } from "./styles"
import { useAppTranslate } from "../../../translate/useAppTranslate"

interface ErrorMessageProps {
  message: string
  handleReload: () => void
}

export const ErrorMessage = ({ message, handleReload }: ErrorMessageProps) => {
  const { buttons } = useAppTranslate()

  return (
    <MessageContainer>
      <IconComponent>
        <CloudX size={59} color={theme.palette.primary.main} />
      </IconComponent>
      <InfoContainer>
        <p>{message}</p>
        <Button variant='link' onClick={handleReload}>
          {buttons.reload}
        </Button>
      </InfoContainer>
    </MessageContainer>
  )
}
