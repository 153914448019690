import styled from "styled-components"
import { media } from "../../utils/media-queries"

export const Wrapper = styled.div`
  &:hover * {
    display: flex;
  }

  display: flex;
  flex-direction: row;
`

export const Container = styled.div<{
  $isConfigured: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.main : theme.palette.gray[400]};
  background-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.lightest : theme.palette.gray[100]};
  font-size: 16px;
  font-weight: bold;

  svg {
    color: ${({ $isConfigured, theme }) =>
      $isConfigured ? theme.palette.success.darker : theme.palette.gray[500]};
  }
`

export const TooltipWrapper = styled.div`
  position: relative;
  display: none;
`

export const Tooltip = styled.div`
  position: absolute;
  top: 36px;
  left: -32px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media ${media.medium} {
    right: 0px;
    left: unset;

    white-space: nowrap;
    width: fit-content;
  }
`
