import { FC } from "react"
import { Container, AnalyticRepresentation } from "./styles"

interface LayoutOptionProps {
  analyticsPerLine: 1 | 2 | 3
}

export const LayoutOption: FC<LayoutOptionProps> = ({ analyticsPerLine }) => {
  return (
    <Container $analyticsperline={analyticsPerLine}>
      {[...Array(analyticsPerLine * 2)].map((_, index) => (
        <AnalyticRepresentation key={index} />
      ))}
    </Container>
  )
}
