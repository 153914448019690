import { useRef } from "react"
import { useAppTranslate } from "../../translate/useAppTranslate"
import { toast } from "react-toastify"

export const useQRCode = () => {
  const { qrCode } = useAppTranslate()

  const svgRef = useRef<SVGSVGElement>(null)

  const downloadStringAsFile = (data: string, filename: string) => {
    let a = document.createElement("a")
    a.download = filename
    a.href = data
    a.click()
  }

  const handleDownloadQRCode = (filename: string) => {
    if (!svgRef.current) return

    try {
      const serializer = new XMLSerializer()

      const fileURI =
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(
          '<?xml version="1.0" standalone="no"?>' +
            serializer.serializeToString(svgRef.current)
        )

      downloadStringAsFile(fileURI, filename)
    } catch (error) {
      toast.error(qrCode.error)
    }
  }

  return {
    svgRef,
    handleDownloadQRCode,
  }
}
