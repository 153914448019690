import { useAppTranslate } from "../../translate/useAppTranslate"
import { CreateChartContextProvider } from "./context/CreateChartContext"
import { FlexContainer } from "../../components/ui/FlexContainer"
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { Button } from "../../components/ui/Button"
import { DashboardWrapper } from "./components/ChartsArea"
import { ModalHedro } from "../../components/ModalHedro"
import { ModalDashboardConfig } from "./components/ModalDashboardConfig"
import { ContainerWrapper } from "./styles"
import { Text } from "../../components/ui/Text"
import { ErrorMessage } from "../../components/ui/ErrorMessage"
import Header from "./components/Header"
import { useDashboard } from "./hooks/useDashboard"

export const DashboardPage = () => {
  const translate = useAppTranslate()

  const {
    dashboard,
    dashboards,
    hasError,
    isLoading,
    isConfigureModalOpen,
    isActionDisabled,
    form,
    isDeleteOpen,
    isDeleteDisabled,
    handleOpenEditModal,
    handleOpenCreateModal,
    handleCloseModal,
    handleConfigureDashboard,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleDeleteDashboard,
    handleRefreshDasboards,
    handleChangeDashboard,
  } = useDashboard()

  return (
    <>
      {isConfigureModalOpen && (
        <ModalHedro
          onClose={handleCloseModal}
          onAction={handleConfigureDashboard}
          actionLabel={translate.buttons.save}
          isActionsDisabled={isActionDisabled}
          title={translate.dashboard.configureDashboard.title}
          subtitle={translate.dashboard.configureDashboard.subtitle}
        >
          <ModalDashboardConfig form={form} />
        </ModalHedro>
      )}

      {isDeleteOpen && dashboard && (
        <ModalHedro
          onClose={handleCloseDeleteModal}
          onLeftButton={handleCloseDeleteModal}
          leftButtonLabel={translate.buttons.cancel}
          onAction={handleDeleteDashboard}
          isActionsDisabled={isDeleteDisabled}
          actionLabel={translate.buttons.delete}
          actionVariant='destructive'
          title={translate.dashboard.deleteDashboard.title}
        >
          <Text color='gray.700'>
            {translate.dashboard.deleteDashboard.content}
          </Text>
        </ModalHedro>
      )}

      {isLoading && !hasError && (
        <ContainerWrapper>
          <LoadingSpinner />
        </ContainerWrapper>
      )}

      <CreateChartContextProvider>
        {!isLoading && (
          <Header
            dashboard={dashboard!}
            dashboards={dashboards ?? []}
            onAddDashboard={handleOpenCreateModal}
            onChangeDashboard={handleChangeDashboard}
            onEditDashboard={handleOpenEditModal}
            onDeleteDashboard={handleOpenDeleteModal}
          />
        )}

        {hasError && !isLoading && (
          <ErrorMessage
            message={translate.dashboard.error}
            handleReload={handleRefreshDasboards}
          />
        )}

        {!dashboard && !hasError && (
          <ContainerWrapper>
            <FlexContainer direction='column' gap={12}>
              {translate.dashboard.configure_your_first_dashboard}
              <Button onClick={handleOpenCreateModal}>
                {translate.buttons.new_dashboard}
              </Button>
            </FlexContainer>
          </ContainerWrapper>
        )}

        {dashboard && <DashboardWrapper dashboard={dashboard} />}
      </CreateChartContextProvider>
    </>
  )
}
