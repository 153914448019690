import React from "react"
import { NewHeader } from "../../../../components/NewHeader"
import Row from "../../../../components/ui/Row"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Dashboard } from "../../entities/Dashboard"
import { DashDropdown } from "./components/DashDropdown"
import { DashTools } from "./components/DashTools"
import { Container, Menu } from "./styles"

interface HeaderProps {
  dashboard?: Dashboard
  dashboards: Dashboard[]
  onAddDashboard: () => void
  onChangeDashboard: (dashboard: Dashboard) => void
  onEditDashboard: (dashboard: Dashboard) => void
  onDeleteDashboard: () => void
}

const Header = ({
  dashboard,
  dashboards,
  onAddDashboard,
  onChangeDashboard,
  onEditDashboard,
  onDeleteDashboard,
}: HeaderProps) => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.dashboard,
  }

  return (
    <Row>
      <Container>
        <NewHeader page={page} />

        {dashboard && (
          <Menu>
            <DashDropdown
              selected={dashboard}
              dashboards={dashboards}
              onAddDashboard={onAddDashboard}
              onChangeDashboard={onChangeDashboard}
            />

            <DashTools
              onEdit={() => onEditDashboard(dashboard)}
              onDelete={onDeleteDashboard}
            />
          </Menu>
        )}
      </Container>
    </Row>
  )
}

export default React.memo(Header)
