import { configureStore } from "@reduxjs/toolkit"
import modalReducer from "./features/modal/modal.slice"
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist"

import { persistedReducer } from "./persister"
import { api } from "./api/GatewaysWsApi"
import { gatewaysApi } from "./api/GatewaysApi"
import { analyticsApi } from "./api/analytics/slice"
import { assetsApi } from "./api/assets/slice"
import { dashboardsApi } from "./api/dashboard/slice"
import { iotApi } from "./api/iot/slice"

import { setupListeners } from "@reduxjs/toolkit/query"
import socketMiddleware from "./middlewares/socketMiddleware"

import { adlApi } from "./api/adl/slice"

import { sensorsApi } from "./api/sensors/slice"

export const store = configureStore({
  reducer: {
    persistedReducer,
    modalReducer,
    [api.reducerPath]: api.reducer,
    [gatewaysApi.reducerPath]: gatewaysApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [dashboardsApi.reducerPath]: dashboardsApi.reducer,
    [iotApi.reducerPath]: iotApi.reducer,
    [adlApi.reducerPath]: adlApi.reducer,
    [sensorsApi.reducerPath]: sensorsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    })
      .concat(api.middleware)
      .concat(gatewaysApi.middleware)
      .concat(analyticsApi.middleware)
      .concat(assetsApi.middleware)
      .concat(dashboardsApi.middleware)
      .concat(iotApi.middleware)
      .concat(adlApi.middleware)
      .concat(sensorsApi.middleware)
      .concat(socketMiddleware),
})

export const persisted = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const { useGetMessagesQuery } = api

export { useFetchGatewaysQuery } from "./api/GatewaysApi"

export {
  useFetchAnalyticsQuery,
  useCreateSensorAnalyticMutation,
  useDeleteAnalyticMutation,
  useCreateAssetAnalyticMutation,
} from "./api/analytics/slice"

export {
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useFetchAssetDetailsQuery,
  useFetchAssetsQuery,
  useUpdateAssetMutation,
  useFetchUnlinkedSensorsQuery,
  useLinkSensorMutation,
  useFetchAssetChildrenQuery,
  useDeleteLinkSensorMutation,
  useFetchAssetTypesQuery,
  useUploadAssetImageMutation,
  useDeleteAssetImageMutation,
  useFetchAssetAttachmentsQuery,
  useUploadAssetAttachMutation,
  useDeleteAssetAttachmentMutation,
} from "./api/assets/slice"

export {
  useFetchDashboardsQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
} from "./api/dashboard/slice"

export { useFetchIotDataQuery } from "./api/iot/slice"

export {
  useListenADLQuery,
  useListenNTCThresholdQuery,
  useListenFilteredThresholdQuery,
} from "./api/adl/slice"

export { useFetchProvisionedSensorsQuery } from "./api/sensors/slice"
