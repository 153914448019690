import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import { DashboardRequest, UpdateDashboardRequest } from "./entities"
import { dashboardsApi } from "./slice"
import { Dashboard } from "../../../pages/Dashboard/entities/Dashboard"

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

type MutationType<Request, Response> = MutationLifecycleApi<
  Request,
  BaseQueryFnType,
  Response,
  "dashboardsApi"
>

export const onConfigureQueryStarted = async (
  request: DashboardRequest | UpdateDashboardRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<DashboardRequest | UpdateDashboardRequest, Dashboard>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      dashboardsApi.util.updateQueryData(
        "fetchDashboards",
        undefined,
        (draft) => {
          const index = draft.findIndex((item) => item.id === response.id)

          if (index === -1) {
            draft.push(response)
            return
          }

          draft[index] = response
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onDeleteQueryStarted = async (
  request: number,
  { dispatch, queryFulfilled }: MutationType<number, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      dashboardsApi.util.updateQueryData(
        "fetchDashboards",
        undefined,
        (draft) => {
          const index = draft.findIndex((item) => item.id === request)

          if (index !== -1) draft.splice(index, 1)
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}
