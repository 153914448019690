import {
  AccRaw,
  FFT,
  HDR_SERVICES_TYPE,
  Health,
  NTC,
  POT,
  RMMS,
  RMS2,
  Temperature,
  Tilt,
  _4T20,
  get4T20,
  getAccRaw,
  getFFT,
  getHealth,
  getNtc,
  getPot,
  getRMMS,
  getRMS2,
  getTemp,
  getTilt,
} from "hdr-process-data"
import { apiCall } from "../../../utils/axios"
import {
  ApiDataDTO,
  ContentConfigDTO,
  CsvDataDTO,
  PayloadDTO,
  ProcessedDataDTO,
  ServicesProcessedsDTO,
} from "./reports.interfaces"
import { Result } from "hdr-process-data/lib/utils/result"
import { UnsupportedError } from "hdr-process-data/lib/errors"
import { sensorRightJson } from "../../../pages/Reports/entities/sensorRightJson"
import { cleanAndPadSerialNumber } from "../../../utils/cleanAndPadSerialNumber"

export const request = async (payload: {
  startDate: string
  endDate: string
  sensors: sensorRightJson
}): Promise<CsvDataDTO[] | null> => {
  const { sensors } = payload
  const period = getPeriodArray(payload)

  const csv: CsvDataDTO[] = []

  for (const sensor in sensors) {
    // macs
    // Downloading data

    const fileName =
      sensors[sensor].name && sensors[sensor].name.length !== 0
        ? sensors[sensor].name
        : `S${cleanAndPadSerialNumber(sensors[sensor].serialNumber)}`

    const nameOrIdSensor =
      sensors[sensor].name && sensors[sensor].name.length !== 0
        ? "name"
        : "serial number"

    const dataCollected: ApiDataDTO[] = await getDataFromAllServicesForMac(
      sensor,
      period[0],
      period[1],
      sensors[sensor].services
    )

    if (dataCollected.length === 0) {
      continue
    }

    // Processing data
    const {
      processedData,
      servicesProcessed,
    }: {
      processedData: ProcessedDataDTO[]
      servicesProcessed: ServicesProcessedsDTO
    } = processData(dataCollected)

    const processedDataSorted = processedData.sort(
      (start, end) => Date.parse(start.time) - Date.parse(end.time)
    )

    // creating CSV
    const csvFile = await writeCSVFile(
      nameOrIdSensor,
      fileName ?? "",
      servicesProcessed,
      processedDataSorted
    )

    csv.push({
      data: csvFile!.csvDataComplete,
      filename: csvFile!.filename,
    })
  }

  if (!csv.length) return null

  return csv
}

const getPeriodArray = (payload: PayloadDTO): string[] => {
  const { startDate, endDate } = payload

  const period: string[] = [`${startDate} 00:00:00`, `${endDate} 23:59:59`]

  return period
}

const getDataFromAllServicesForMac = async (
  mac: string,
  startDate: string,
  endDate: string,
  servicesArray: string[]
) => {
  let dataToReturn: ApiDataDTO[] = []

  const api = apiCall()

  for (const service of servicesArray) {
    const response = await api.get(
      `${CONVERT_SENSOR_SERVICE_TO_API_CALL[service]}`,
      {
        headers: {
          macs: [mac],
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
        },
      }
    )

    dataToReturn = [...dataToReturn, ...(response.data as ApiDataDTO[])]
  }

  return dataToReturn
}

const CONVERT_SENSOR_SERVICE_TO_API_CALL: Record<string, string> = {
  temp: "/v1/temps",
  health: "/v1/healths",
  tempsMMM: "/v1/tempsmmm",
  rms2: "/v1/rms2s",
  rmms: "/v1/rmms",
  tilt: "/v1/tilts",
  fft: "/v1/ffts",
  accRaw: "/v1/accRaws",
  _4t20: "/v1/_4t20",
  ntc: "/v1/ntcs",
  pot: "/v1/pots",
}

const _defaultTimePosition = 1

const processData = (sensorData: ApiDataDTO[]) => {
  const processedData: ProcessedDataDTO[] = []
  const servicesProcessed: ServicesProcessedsDTO = { services: [] }

  for (const data of sensorData) {
    let resultService: Result<
      UnsupportedError,
      | Health
      | Temperature
      | RMS2
      | RMMS
      | Tilt
      | FFT
      | AccRaw
      | _4T20
      | NTC
      | POT
    >

    const ptBrDate = data.time

    switch (data.serviceType) {
      case HDR_SERVICES_TYPE.health:
        resultService = getHealth(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const healthProcessed = resultService.value as Health

          let lastReset: string | undefined

          if (healthProcessed.lastReset) {
            lastReset = healthProcessed.lastReset
          }

          processedData.push({
            time: healthProcessed.time,
            service: "health",
            temp: healthProcessed.temp,
            maxTemp: healthProcessed.maxTemp,
            voltage: healthProcessed.voltage,
            lastReset: lastReset,
            rssi: data.rssi,
          })
        }

        if (!servicesProcessed.health) {
          servicesProcessed.services.push(HDR_SERVICES_TYPE.health)
          servicesProcessed.health = { ok: 1 }
        }
        break
      case HDR_SERVICES_TYPE.temp:
        resultService = getTemp(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const tempProcessed = resultService.value as Temperature
          tempProcessed.temp.forEach((t, i) => {
            processedData.push({
              time: tempProcessed.time[i],
              service: "temp",
              temp: t,
              rssi: data.rssi,
            })
          })
        }
        if (!servicesProcessed.temp) {
          servicesProcessed.services.push(HDR_SERVICES_TYPE.temp)
          servicesProcessed.temp = { ok: 1 }
        }
        break
      case HDR_SERVICES_TYPE.rms2:
        resultService = getRMS2(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const rms2Processed = resultService.value as RMS2
          rms2Processed.rms2.forEach((rms, i) => {
            processedData.push({
              time: rms2Processed.time[i],
              service: "rms2",
              axis: rms2Processed.axis,
              rms2: rms,
              rssi: data.rssi,
            })
          })

          if (!servicesProcessed.rms2) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.rms2)
            servicesProcessed.rms2 = { axis: rms2Processed.axis }
          }
        }

        break
      case HDR_SERVICES_TYPE.rmms:
        resultService = getRMMS(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const rmmsProcessed = resultService.value as RMMS
          rmmsProcessed.rmms.forEach((rms, i) => {
            processedData.push({
              time: rmmsProcessed.time[i],
              service: "rmms",
              axis: rmmsProcessed.axis,
              rmms: rms,
              rssi: data.rssi,
            })
          })

          if (!servicesProcessed.rmms) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.rmms)
            servicesProcessed.rmms = { axis: rmmsProcessed.axis }
          }
        }

        break
      case HDR_SERVICES_TYPE.tilt:
        resultService = getTilt(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const tiltProcessed = resultService.value as Tilt
          tiltProcessed.pitch.forEach((pitch, i) => {
            processedData.push({
              time: tiltProcessed.time[i],
              service: "tilt",
              pitch: pitch,
              roll: tiltProcessed.roll[i],
              rssi: data.rssi,
            })
          })
          if (!servicesProcessed.tilt) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.tilt)
            servicesProcessed.tilt = { axis: tiltProcessed.axis }
          }
        }

        break
      case HDR_SERVICES_TYPE.fft:
        resultService = getFFT(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const fftProcessed = resultService.value as FFT
          processedData.push({
            time: fftProcessed.time,
            service: "fft",
            axis: fftProcessed.axis,
            fft: fftProcessed.fft,
            fftVeloc: fftProcessed.fftVeloc,
            freq: fftProcessed.freqArray,
            rssi: data.rssi,
          })

          if (!servicesProcessed.fft) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.fft)
            servicesProcessed.fft = { axis: fftProcessed.axis }
          }
        }

        break
      case HDR_SERVICES_TYPE.accRaw:
        resultService = getAccRaw(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const accRawProcessed = resultService.value as AccRaw
          processedData.push({
            time: accRawProcessed.time,
            service: "accRaw",
            axis: accRawProcessed.axis,
            accRaw: accRawProcessed.accRaw,
            xPlot: accRawProcessed.xPlot,
            rssi: data.rssi,
          })

          if (!servicesProcessed.accRaw) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.accRaw)
            servicesProcessed.accRaw = { axis: accRawProcessed.axis }
          }
        }

        break
      case HDR_SERVICES_TYPE._4t20:
        resultService = get4T20(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const _4t20Processed = resultService.value as _4T20
          _4t20Processed.current.forEach((current, i) => {
            processedData.push({
              time: _4t20Processed.time[i],
              service: "_4t20",
              channel: _4t20Processed.channel,
              current: current,
              rssi: data.rssi,
            })
          })
          if (!servicesProcessed._4t20) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE._4t20)
            servicesProcessed._4t20 = { channel: _4t20Processed.channel }
          }
        }

        break
      case HDR_SERVICES_TYPE.ntc:
        resultService = getNtc(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const ntcProcessed = resultService.value as NTC
          ntcProcessed.temp.forEach((temp, i) => {
            processedData.push({
              time: ntcProcessed.time[i],
              service: "ntc",
              channel: ntcProcessed.channel,
              temp: temp,
              rssi: data.rssi,
            })
          })
          if (!servicesProcessed.ntc) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.ntc)
            servicesProcessed.ntc = { channel: ntcProcessed.channel }
          }
        }

        break
      case HDR_SERVICES_TYPE.pot:
        resultService = getPot(data.raw, ptBrDate, {
          applicationVersion: data.applicationVersion,
          protocolVersion: data.protocolVersion,
        })
        if (resultService.isRight()) {
          const potProcessed = resultService.value as POT
          potProcessed.percent.forEach((percent, i) => {
            processedData.push({
              time: potProcessed.time[i],
              service: "pot",
              channel: potProcessed.channel,
              percent: percent,
              rssi: data.rssi,
            })
          })
          if (!servicesProcessed.pot) {
            servicesProcessed.services.push(HDR_SERVICES_TYPE.pot)
            servicesProcessed.pot = { channel: potProcessed.channel }
          }
        }

        break
      default:
        break
    }
  }

  return { processedData, servicesProcessed }
}

export const writeCSVFile = (
  nameOrIdSensor: "name" | "serial number" | "asset",
  fileName: string,
  servicesProcessed: ServicesProcessedsDTO,
  data: ProcessedDataDTO[]
) => {
  const { contentConfig, header } = _getProcessedDataConfigsAndCsvHeader(
    servicesProcessed,
    nameOrIdSensor
  )
  const content = _generateCsvData(fileName, data, contentConfig)
  return _writeToCsvFile(fileName, header, content)
}

function _getProcessedDataConfigsAndCsvHeader(
  servicesProcessed: ServicesProcessedsDTO,
  nameOrIdSensor: "name" | "serial number" | "asset"
) {
  let header = `${nameOrIdSensor}, time, rssi`
  const contentConfig: ContentConfigDTO = { amount: 2 }
  servicesProcessed.services.forEach((service) => {
    const positionLength = Object.keys(contentConfig).length
    switch (service) {
      case HDR_SERVICES_TYPE.health:
        contentConfig.amount++
        contentConfig.hTemp = positionLength + 2
        contentConfig.hMaxTemp = positionLength + 3
        contentConfig.hVoltage = positionLength + 4
        contentConfig.hLastReset = positionLength + 5
        header += ", hTemp, hMaxTemp, hVoltage, hLastReset"
        break
      case HDR_SERVICES_TYPE.temp:
        contentConfig.amount++
        contentConfig.temp = positionLength + 2
        header += ", temp"
        break
      case HDR_SERVICES_TYPE.rms2:
        contentConfig.amount += 3
        contentConfig.rms2X = positionLength + 2
        contentConfig.rms2Y = positionLength + 3
        contentConfig.rms2Z = positionLength + 4
        header += ", rms2X, rms2Y, rms2Z"
        break
      case HDR_SERVICES_TYPE.rmms:
        header += ", rmmsX, rmmsY, rmmsZ"
        contentConfig.amount += 3
        contentConfig.rmmsX = positionLength + 2
        contentConfig.rmmsY = positionLength + 3
        contentConfig.rmmsZ = positionLength + 4
        break
      case HDR_SERVICES_TYPE.tilt:
        header += ", pitch, roll"
        contentConfig.amount += 2
        contentConfig.pitch = positionLength + 2
        contentConfig.roll = positionLength + 3
        break
      case HDR_SERVICES_TYPE.fft:
        header +=
          ", freq, fftAccX, fftAccY, fftAccZ, fftVelocX, fftVelocY, fftVelocZ,"
        contentConfig.amount += 4
        contentConfig.freq = positionLength + 2
        contentConfig.fftAccX = positionLength + 3
        contentConfig.fftAccY = positionLength + 4
        contentConfig.fftAccZ = positionLength + 5
        contentConfig.fftVelocX = positionLength + 6
        contentConfig.fftVelocY = positionLength + 7
        contentConfig.fftVelocZ = positionLength + 8
        break
      case HDR_SERVICES_TYPE.accRaw:
        header += ", xPlot, accRawX, accRawY, accRawZ"
        contentConfig.amount += 4
        contentConfig.xPlot = positionLength + 2
        contentConfig.accRawX = positionLength + 3
        contentConfig.accRawY = positionLength + 4
        contentConfig.accRawZ = positionLength + 5
        break
      case HDR_SERVICES_TYPE._4t20:
        header += ", _4t20A, _4t20B"
        contentConfig.amount += 2
        contentConfig._4t20A = positionLength + 2
        contentConfig._4t20B = positionLength + 3
        break
      case HDR_SERVICES_TYPE.ntc:
        header += ", ntcA, ntcB"
        contentConfig.amount += 2
        contentConfig.ntcA = positionLength + 2
        contentConfig.ntcB = positionLength + 3
        break
      case HDR_SERVICES_TYPE.pot:
        header += ", potA, potB"
        contentConfig.amount += 2
        contentConfig.potA = positionLength + 2
        contentConfig.potB = positionLength + 3
        break
      default:
        break
    }
  })

  return { contentConfig, header }
}

function _generateCsvData(
  fileName: string,
  data: ProcessedDataDTO[],
  contentConfig: ContentConfigDTO
) {
  const content: (string | number | number[])[][] = []
  data.forEach((item: ProcessedDataDTO, index) => {
    switch (item.service) {
      case "health":
        if (
          index > 0 &&
          content[content.length - 1] &&
          content[content.length - 1][_defaultTimePosition] === item.time
        ) {
          content[content.length - 1][contentConfig.hTemp!] =
            item.temp! as number
          content[content.length - 1][contentConfig.hMaxTemp!] = item.maxTemp!
          content[content.length - 1][contentConfig.hVoltage!] = item.voltage!
          content[content.length - 1][contentConfig.hLastReset!] =
            item.lastReset!
        } else {
          const data = [fileName, item.time, item.rssi] as (
            | string
            | number
            | number[]
          )[]
          data[contentConfig.hTemp!] = item.temp! as number
          data[contentConfig.hMaxTemp!] = item.maxTemp!
          data[contentConfig.hVoltage!] = item.voltage!
          data[contentConfig.hLastReset!] = item.lastReset!
          content.push(data)
        }
        break
      case "temp":
        if (
          index > 0 &&
          content[content.length - 1] &&
          content[content.length - 1][_defaultTimePosition] === item.time
        ) {
          content[content.length - 1][contentConfig.temp!] = item.temp!
        } else {
          const data = [fileName, item.time, item.rssi] as (
            | string
            | number
            | number[]
          )[]
          data[contentConfig.temp!] = item.temp!
          content.push(data)
        }
        break
      case "rms2":
        if (item.axis === "all") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.rms2X!] = item.rms2![0]
            content[content.length - 1][contentConfig.rms2Y!] = item.rms2![1]
            content[content.length - 1][contentConfig.rms2Z!] = item.rms2![2]
          } else {
            const data = [fileName, item.time, item.rssi] as (
              | string
              | number
              | number[]
            )[]
            data[contentConfig.rms2X!] = item.rms2![0]
            data[contentConfig.rms2Y!] = item.rms2![1]
            data[contentConfig.rms2Z!] = item.rms2![2]
            content.push(data)
          }
        } else {
          const axisIndex = _getIndexofAxisInContentConfig(
            item.axis!,
            contentConfig.rms2X!,
            contentConfig.rms2Y!,
            contentConfig.rms2Z!
          )

          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][axisIndex] = item.rms2![0]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[axisIndex] = item.rms2![0]
            content.push(data)
          }
        }
        break
      case "rmms":
        if (item.axis === "all") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.rmmsX!] = item.rmms![0]
            content[content.length - 1][contentConfig.rmmsY!] = item.rmms![1]
            content[content.length - 1][contentConfig.rmmsZ!] = item.rmms![2]
          } else {
            const data = [fileName, item.time, item.rssi] as (
              | string
              | number
              | number[]
            )[]
            data[contentConfig.rmmsX!] = item.rmms![0]
            data[contentConfig.rmmsY!] = item.rmms![1]
            data[contentConfig.rmmsZ!] = item.rmms![2]
            content.push(data)
          }
        } else {
          const axisIndex = _getIndexofAxisInContentConfig(
            item.axis!,
            contentConfig.rmmsX!,
            contentConfig.rmmsY!,
            contentConfig.rmmsZ!
          )

          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][axisIndex] = item.rmms![0]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[axisIndex] = item.rmms![0]
            content.push(data)
          }
        }
        break
      case "tilt":
        if (
          index > 0 &&
          content[content.length - 1] &&
          content[content.length - 1][_defaultTimePosition] === item.time
        ) {
          content[content.length - 1][contentConfig.pitch!] = item.pitch!
          content[content.length - 1][contentConfig.roll!] = item.roll!
        } else {
          const data = [fileName, item.time, item.rssi] as (
            | string
            | number
            | number[]
          )[]
          data[contentConfig.pitch!] = item.pitch!
          data[contentConfig.roll!] = item.roll!
          content.push(data)
        }
        break
      case "fft":
        if (item.axis === "all") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            let contentStartIndex = content.length - 1
            item.fft![0].forEach((_, index) => {
              if (!content[contentStartIndex]) content[contentStartIndex] = []
              content[contentStartIndex][contentConfig.freq!] =
                item.freq![index]
              content[contentStartIndex][contentConfig.fftAccX!] =
                item.fft![0][index]
              content[contentStartIndex][contentConfig.fftAccY!] =
                item.fft![1][index]
              content[contentStartIndex][contentConfig.fftAccZ!] =
                item.fft![2][index]
              content[contentStartIndex][contentConfig.fftVelocX!] =
                item.fftVeloc![0][index]
              content[contentStartIndex][contentConfig.fftVelocY!] =
                item.fftVeloc![1][index]
              content[contentStartIndex][contentConfig.fftVelocZ!] =
                item.fftVeloc![2][index]
              contentStartIndex++
            })
          } else {
            const data = [[fileName, item.time, item.rssi]]
            item.fft![0].forEach((_, index) => {
              if (index >= 1) data[index] = []
              data[index][contentConfig.freq!] = item.freq![index]
              data[index][contentConfig.fftAccX!] = item.fft![0][index]
              data[index][contentConfig.fftAccY!] = item.fft![1][index]
              data[index][contentConfig.fftAccZ!] = item.fft![2][index]
              data[index][contentConfig.fftVelocX!] = item.fftVeloc![0][index]
              data[index][contentConfig.fftVelocY!] = item.fftVeloc![1][index]
              data[index][contentConfig.fftVelocZ!] = item.fftVeloc![2][index]
            })
            content.push(...data)
          }
        } else {
          let fftAccAxis = _getIndexofAxisInContentConfig(
            item.axis!,
            contentConfig.fftAccX!,
            contentConfig.fftAccY!,
            contentConfig.fftAccZ!
          )
          let fftVelocAxis = _getIndexofAxisInContentConfig(
            item.axis!,
            contentConfig.fftVelocX!,
            contentConfig.fftVelocY!,
            contentConfig.fftVelocZ!
          )

          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            let contentStartIndex = content.length - 1
            item.fft![0].forEach((_, index) => {
              if (!content[contentStartIndex]) content[contentStartIndex] = []
              content[contentStartIndex][contentConfig.freq!] =
                item.freq![index]
              content[contentStartIndex][fftAccAxis] = item.fft![0][index]
              content[contentStartIndex][fftVelocAxis] =
                item.fftVeloc![0][index]
              contentStartIndex++
            })
          } else {
            const data = [[fileName, item.time, item.rssi]]
            item.fft![0].forEach((_, index) => {
              if (index >= 1) data[index] = []
              data[index][contentConfig.freq!] = item.freq![index]
              data[index][fftAccAxis] = item.fft![0][index]
              data[index][fftVelocAxis] = item.fftVeloc![0][index]
            })
            content.push(...data)
          }
        }
        break
      case "accRaw":
        if (item.axis === "all") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            let contentStartIndex = content.length - 1
            item.accRaw![0].forEach((accRaw, index) => {
              if (!content[contentStartIndex]) content[contentStartIndex] = []
              content[contentStartIndex][contentConfig.xPlot!] =
                item.xPlot![index]
              content[contentStartIndex][contentConfig.accRawX!] = accRaw
              content[contentStartIndex][contentConfig.accRawY!] =
                item.accRaw![1][index]
              content[contentStartIndex][contentConfig.accRawZ!] =
                item.accRaw![2][index]
              contentStartIndex++
            })
          } else {
            const data = [[fileName, item.time, item.rssi]]
            item.accRaw![0].forEach((accRaw, index) => {
              if (index >= 1) data[index] = []
              data[index][contentConfig.xPlot!] = item.xPlot![index]
              data[index][contentConfig.accRawX!] = accRaw
              data[index][contentConfig.accRawY!] = item.accRaw![1][index]
              data[index][contentConfig.accRawZ!] = item.accRaw![2][index]
            })
            content.push(...data)
          }
        } else {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            let contentStartIndex = content.length - 1
            item.accRaw![0].forEach((accRaw, index) => {
              if (!content[contentStartIndex]) content[contentStartIndex] = []
              content[contentStartIndex][contentConfig.xPlot!] =
                item.xPlot![index]
              content[contentStartIndex][contentConfig.accRaw!] = accRaw
              contentStartIndex++
            })
          } else {
            const data = [[fileName, item.time, item.rssi]]

            const axisIndex = _getIndexofAxisInContentConfig(
              item.axis!,
              contentConfig.accRawX!,
              contentConfig.accRawY!,
              contentConfig.accRawZ!
            )

            item.accRaw![0].forEach((accRaw, index) => {
              if (index >= 1) data[index] = []
              data[index][contentConfig.xPlot!] = item.xPlot![index]
              data[index][axisIndex] = accRaw
            })
            content.push(...data)
          }
        }
        break
      case "_4t20":
        if (item.channel === "ab") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig._4t20A!] =
              item.current![0]
            content[content.length - 1][contentConfig._4t20B!] =
              item.current![1]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig._4t20A!] = item.current![0]
            data[contentConfig._4t20B!] = item.current![1]
            content.push(data)
          }
        } else {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig._4t20!] = item.current![0]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig._4t20!] = item.current![0]
            content.push(data)
          }
        }
        break
      case "ntc": {
        let ntcA = 0
        let ntcB = 0

        if (Array.isArray(item.temp)) {
          ntcA = item.temp[0]
          ntcB = item.temp[1]
        }

        if (item.channel === "ab") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.ntcA!] = ntcA
            content[content.length - 1][contentConfig.ntcB!] = ntcB
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig.ntcA!] = ntcA
            data[contentConfig.ntcB!] = ntcB
            content.push(data)
          }
        } else {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.ntc!] = ntcA
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig.ntc!] = ntcA
            content.push(data)
          }
        }
        break
      }
      case "pot":
        if (item.channel === "ab") {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.potA!] = item.percent![0]
            content[content.length - 1][contentConfig.potB!] = item.percent![1]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig.potA!] = item.percent![0]
            data[contentConfig.potB!] = item.percent![1]
            content.push(data)
          }
        } else {
          if (
            index > 0 &&
            content[content.length - 1] &&
            content[content.length - 1][_defaultTimePosition] === item.time
          ) {
            content[content.length - 1][contentConfig.pot!] = item.percent![0]
          } else {
            const data = [fileName, item.time, item.rssi]
            data[contentConfig.pot!] = item.percent![0]
            content.push(data)
          }
        }
        break
      default:
        break
    }
  })

  for (let i = 0; i < content.length; i++) {
    for (let j = 0; j < contentConfig.amount; j++) {
      if (content[i][j] === undefined || content[i][j] === null) {
        content[i][j] = ""
      }
    }
  }

  return content
}

async function _writeToCsvFile(
  fileName = "output",
  csvHeader = "",
  csvData: (string | number | number[])[][] = []
) {
  if (!csvData.length) {
    console.error(`No data available for: ${fileName}`)
    return
  }

  const rows = csvData
    .map((data) => {
      return data.join(",")
    })
    .join("\n")

  const csvDataComplete = [csvHeader].concat(rows).join("\n")
  const filename = `${fileName}.csv`

  return {
    csvDataComplete,
    filename,
  }
}

const _getIndexofAxisInContentConfig = (
  axis: string,
  x: number,
  y: number,
  z: number
) => {
  if (axis === "x") {
    return x
  }

  if (axis === "y") {
    return y
  }

  if (axis === "z") {
    return z
  }

  return x
}
