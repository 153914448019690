import { FormEvent, useState } from "react"
import { NameContainer, Input, PathContainer, Form } from "./styles"
import { Check, PencilSimple } from "phosphor-react"
import { Text } from "../../../ui/Text"
import { FlexContainer } from "../../../ui/FlexContainer"

interface ServiceNameProps {
  fullPath: string
  canEdit?: boolean
  onChangePath: (path: string) => void
}

export const ServiceName = ({
  fullPath,
  canEdit = false,
  onChangePath,
}: ServiceNameProps) => {
  const [edit, setEdit] = useState(false)

  const [path, setPath] = useState(fullPath)

  const handleEditIcon = () => {
    setEdit((prev) => !prev)
  }

  const handleEdit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setEdit(false)
  }

  const handleConfirm = () => {
    onChangePath(path)
    setEdit(false)
  }

  return (
    <NameContainer>
      <Form onSubmit={handleEdit}>
        {!edit && (
          <PathContainer>
            <Text fontWeight='medium' color='gray.800'>
              {path}
            </Text>

            {canEdit && (
              <FlexContainer padding='0 0 0 4px'>
                <PencilSimple size={18} onClick={handleEditIcon} />
              </FlexContainer>
            )}
          </PathContainer>
        )}

        {edit && (
          <>
            <Input
              value={path}
              onChange={(e) => {
                setPath(e.target.value)
                onChangePath(e.target.value)
              }}
            />

            <FlexContainer padding='0 0 0 4px'>
              <Check size={18} onClick={handleConfirm} weight='bold' />
            </FlexContainer>
          </>
        )}
      </Form>
    </NameContainer>
  )
}
