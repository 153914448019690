/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { SensorsConfigContext } from "../context/SensorConfigContext"
import { SensorsConfigContextDTO } from "../entities/SensorsConfigContextDTO"
import { useAppDispatch } from "../../../store/hooks"
import { openModal } from "../../../store/features/modal/modal.slice"
import { AlgorithmsDTO } from "../entities/AlgorithmsFormattedDTO"
import { ResetResquestDTO } from "../entities/ResetRequestDTO"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { getPercentPoint } from "./useGetPercentPoint"
import { ExtraConfig } from "../../Dashboard/entities/Analytics"

export const useAlgorithmCardController = (bleHdrMac: string) => {
  const {
    sensorsToBeDisplayed,
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    configServiceSelected,
    copyLastConfigServiceSelected,
    typeOfServiceSelected,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    transformHowTextWillBePresented,
    setModalType,
    setBleHdrMac,
    setResetRequest,
    setResetOnlyAlgorithm,
    titleModalDisableOrReset,
    setOpenModalConfigService,
    lastPOTDataSavedFromWS,
    localStorageUpdated,
  } = useContext(SensorsConfigContext) as SensorsConfigContextDTO

  const { buttons } = useAppTranslate()

  const [referencePOT, setReferencePOT] = useState<string | null>("")

  useEffect(() => {
    const referencePOTFromLocalStorage: string | null = localStorage.getItem(
      `${bleHdrMac} - pot - Reference`
    )
    const referenceInArray = stringToArray(
      referencePOTFromLocalStorage as string
    )
    const referenceWithCalculation = getPointWithCalculation([referenceInArray])
    if (referenceWithCalculation !== undefined)
      setReferencePOT(String(referenceWithCalculation))
  }, [localStorageUpdated])

  const sensor = sensorsToBeDisplayed.find(
    (sensor) => sensor.bleHdrMac === bleHdrMac
  )
  const dispatch = useAppDispatch()

  const algorithmAccordionArrayFromLocalStorage: string | null =
    localStorage.getItem(`${bleHdrMac} - AlgorithmExpander`)
  const [algorithmAccordion, setAlgorithmAccordion] = useState<string[]>(
    JSON.parse(algorithmAccordionArrayFromLocalStorage as string) ?? []
  )

  const getLastDataPOTSalved = (mac: string) => {
    if (!lastPOTDataSavedFromWS[mac]) return null
    return lastPOTDataSavedFromWS[mac]
  }

  const stringToArray = (str: string): number[] => {
    if (!str) return []
    str = str.trim()
    const parts = str.split(",")

    const numbers = parts.map((part) => parseFloat(part.trim()))
    const validNumbers = numbers.filter((number) => !isNaN(number))

    return validNumbers
  }

  const handleAlgorithAccordionClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    serviceName:
      | "temp"
      | "rms2"
      | "rmms"
      | "tilt"
      | "fft"
      | "accRaw"
      | "_4t20"
      | "ntc"
      | "pot",
    isConfigured: boolean
  ): void => {
    e.stopPropagation()

    if (isConfigured) {
      const updatedAccordion = [...algorithmAccordion]
      const index = updatedAccordion.indexOf(serviceName)

      if (index !== -1) {
        updatedAccordion.splice(index, 1)
      } else {
        updatedAccordion.push(serviceName)
      }

      setAlgorithmAccordion(updatedAccordion)
      localStorage.setItem(
        `${bleHdrMac} - AlgorithmExpander`,
        JSON.stringify(updatedAccordion)
      )
    } else {
      setBleHdrMac(bleHdrMac)

      dispatch(openModal({ modal: "ServiceConfig" }))
      typeOfServiceSelected.current = serviceName
      configServiceSelected.current = {} as AlgorithmsDTO
      copyLastConfigServiceSelected.current = {} as AlgorithmsDTO
      setModalType("reconfigure")

      const updatedAccordion = [...algorithmAccordion, serviceName]
      setModalType("configure")
      setAlgorithmAccordion(updatedAccordion)

      localStorage.setItem(
        `${bleHdrMac} - AlgorithmExpander`,
        JSON.stringify(updatedAccordion)
      )
    }
  }

  const handleOpenModalDesableOrReset = (
    type: "reset" | "disable",
    algorithm: string
  ) => {
    if (type === "disable") {
      _handleDisableOrReset(algorithm)
      dispatch(openModal({ modal: "DisableOrReset" }))
      titleModalDisableOrReset.current = `${buttons.disable} ${TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE[algorithm as keyof typeof TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE].toUpperCase()}`
      setResetOnlyAlgorithm(true)
    }
  }

  const _handleDisableOrReset = (algorithm: string) => {
    if (!sensor) return

    const mac = bleHdrMac as string
    const bleOriginalMac = sensor?.bleOriginalMac
    const serviceType =
      HDR_SERVICES_TYPE[algorithm as keyof typeof HDR_SERVICES_TYPE]
    typeOfServiceSelected.current = algorithm as
      | "temp"
      | "rms2"
      | "rmms"
      | "tilt"
      | "fft"
      | "accRaw"
      | "_4t20"
      | "ntc"
      | "pot"

    const request: ResetResquestDTO = {
      mac: mac,
      bleOriginalMac: bleOriginalMac,
      algorithm: serviceType,
    }
    setResetRequest(request)
  }

  const handleOpenModal = (
    serviceName:
      | "temp"
      | "rms2"
      | "rmms"
      | "tilt"
      | "fft"
      | "accRaw"
      | "_4t20"
      | "ntc"
      | "pot",
    configAlgorithm: AlgorithmsDTO | null
  ) => {
    if (configAlgorithm === null) return
    setBleHdrMac(bleHdrMac)
    setOpenModalConfigService(true)
    dispatch(
      openModal({
        modal: "ServiceConfig",
      })
    )

    typeOfServiceSelected.current = serviceName
    configServiceSelected.current = configAlgorithm
  }

  const getPointWithCalculation = (value: number | number[][] | null) => {
    const dataFromLS = localStorage.getItem(`${bleHdrMac} - pot - ExtraConfig`)
    const objDataFromLS = JSON.parse(dataFromLS as string)

    if (!objDataFromLS) return

    const ExtraConfigs: ExtraConfig = {
      inferiorLimit: objDataFromLS.inferiorLimit,
      superiorLimit: objDataFromLS.upperLimit,
      measureUnit: objDataFromLS.unitOfMeasurement,
    }

    const point = getPercentPoint({
      data: (value as number[][])[0],
      configs: ExtraConfigs,
    })

    if (point.isRight()) return point.value
  }

  const searchLastPOTData = (
    mac: string,
    value: number | number[][] | null
  ) => {
    const point = getPointWithCalculation(value)

    if (point !== undefined && point !== null) {
      handleChangeReferencePOT((value as number[][])[0], String(point), "pot")
    }
  }

  const handleChangeReferencePOT = (
    newReferencePOT: number[],
    pointWithCalculation: string,
    serviceName: "pot" | "_4t20"
  ) => {
    const newRefenteceFormated = JSON.stringify(newReferencePOT).replace(
      /\[|\]/g,
      ""
    )
    localStorage.setItem(
      `${bleHdrMac} - ${serviceName} - Reference`,
      newRefenteceFormated
    )
    setReferencePOT(pointWithCalculation)
  }

  const resetReferencePOT = () => {
    setReferencePOT("")
    localStorage.removeItem(`${bleHdrMac} - pot - Reference`)
  }

  return {
    algorithmAccordion,
    TRANSLATE_HDR_SERVICE_TYPE_INTO_PLAIN_PORTUGUESE,
    RIGHT_POSITION_OF_SENSORS_SERVICES_CONFIGS,
    sensor,
    setAlgorithmAccordion,
    handleOpenModal,
    handleAlgorithAccordionClick,
    transformHowTextWillBePresented,
    handleOpenModalDesableOrReset,
    searchLastPOTData,
    referencePOT,
    resetReferencePOT,
    getLastDataPOTSalved,
  }
}
