import theme from "../../../styles/theme"
import { formatedDate } from "../../../utils/date"

const getHeader = (title: string) => {
  return `<b>${title}</b> </br>`
}

const getDate = (date: number | undefined) => {
  if (!date) return ""
  return `${formatedDate(date)} </br>`
}

export function barChartTooltipFormater(
  this: Highcharts.TooltipFormatterContextObject,
  tooltip: Highcharts.Tooltip
) {
  const header = getHeader(this.point.name)

  const custom = (this.point as unknown as { custom: { time: number } }).custom

  if (!custom) {
    return header
  }

  const date = getDate(custom.time)

  return `
    ${header}
    ${date}
  `
}

export const barChartOptions = ({
  title,
  noDataMessage,
  loadingMessage,
}: {
  title: string
  noDataMessage: string
  loadingMessage: string
}): Highcharts.Options => {
  return {
    title: {
      text: undefined,
    },

    lang: {
      noData: noDataMessage,
      loading: loadingMessage,
    },

    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        color: theme.colors.black,
      },
    },

    credits: {
      enabled: false,
    },

    chart: {
      backgroundColor: theme.palette.gray[50],
      spacingTop: 16,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      type: "column",
      events: {
        load: function () {
          this.showLoading()
        },
        fullscreenOpen: function (this: Highcharts.Chart) {
          this.setTitle({
            text: title,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.text.lg.fontSize,
            },
          })
        },
        fullscreenClose: function (this: Highcharts.Chart) {
          this.setTitle({
            text: undefined,
          })
        },
      },
      panning: {
        enabled: true,
        type: "xy",
      },
      panKey: "shift",
      style: {
        fontFamily: "Roboto",
        fontSize: "12px",
      },
    },

    time: {
      useUTC: false,
    },

    accessibility: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: "Sensores",
        style: {
          fontSize: "12px",
        },
      },
      categories: [
        "Sensor1",
        "Sensor2",
        "Sensor3",
        "Sensor4",
        "Sensor5",
        "Sensor6",
      ],
    },

    yAxis: {
      title: {
        text: "Alarmes",
        style: {
          fontSize: "12px",
        },
      },
      stackLabels: {
        enabled: false,
      },
    },

    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: "transparent",
            color: theme.colors.black,
          },
        },
      },
    },

    legend: {
      enabled: true,
      itemStyle: {
        lineHeight: "16px",
        fontSize: "12px",
        fontWeight: "bold",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
    },

    exporting: {
      enabled: false,
    },
  }
}
