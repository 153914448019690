import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

const getMediumWidth = (chartsPerLine: number) => {
  const value = chartsPerLine > 2 ? 2 : chartsPerLine
  return `calc((100% - 16px * ${value - 1}) / ${value})`
}

const getLargeWidth = (chartsPerLine: number) => {
  return `calc((100% - 16px * ${chartsPerLine - 1}) / ${chartsPerLine})`
}

export const Container = styled.div<{ $chartsPerLine: number }>`
  width: 100%;

  min-height: 458px;

  background-color: ${(props) => props.theme.palette.gray[50]};
  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-radius: 4px;

  @media ${media.medium} {
    width: ${({ $chartsPerLine }) => getMediumWidth($chartsPerLine)};
  }

  @media ${media.large} {
    width: ${({ $chartsPerLine }) => getLargeWidth($chartsPerLine)};
  }
`

export const ChartWrapper = styled.div<{ $chartsPerLine: number }>`
  width: 100%;
  min-height: 402px;
  padding: 1px;
`

export const TooltipWrapper = styled.div<{ $open: boolean }>`
  position: relative;
  display: ${({ $open }) => ($open ? "block" : "none")};
`

export const Tooltip = styled.div`
  position: absolute;
  white-space: nowrap;
  z-index: 998;
  top: 8px;
  right: 0px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`

export const ButtonItem = styled.button`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[400]};

  background: none;

  padding: 8px 12px 8px 12px;

  display: flex;
  align-items: start;

  width: 100%;
  box-sizing: border-box;
  margin: 0;

  &:hover {
    background-color: ${({ theme }) => theme.palette.gray[100]};
  }
`
