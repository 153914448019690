import { unparse } from "papaparse"
import { apiCall } from "../../../utils/axios"
import { Result, right, left } from "../../../utils/either/result"
import { RegressionReport } from "../entities/RegressionReport"
import { formatedDate } from "../../../utils/date"

export const useReportADL = () => {
  const getApiDataADL = async (): Promise<
    Result<Error, RegressionReport[]>
  > => {
    try {
      const api = apiCall()

      const result = await api.get("/v1/adllogs")

      return right(result.data as RegressionReport[])
    } catch (error) {
      return left(new Error("Error calling adl api"))
    }
  }

  const handleSaveReportADL = async () => {
    const data = await getApiDataADL()

    if (data.isLeft()) {
      console.error(data.value.message)
      return
    }

    const rows = data.value.map((regression) => {
      return {
        "Nome do Sensor": regression.sensorName,
        "Número de Série": regression.sensorSerial,
        Hora: formatedDate(regression.hora),
        Observação: regression.oberservacao,
      }
    })

    const csvString = unparse(rows, {
      delimiter: ",",
      columns: ["Nome do Sensor", "Número de Série", "Hora", "Observação"],
    })

    const blob = new Blob([csvString], { type: "text/csv" })

    const a = document.createElement("a")
    a.download = "ouput.csv"
    a.href = window.URL.createObjectURL(blob)

    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    })

    a.dispatchEvent(clickEvt)
    a.remove()
  }

  return {
    handleSaveReportADL,
  }
}
