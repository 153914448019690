import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  flex-direction: column;
  padding: 16px;
`

export const AssetHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  .qr-code {
    display: none;
  }
`

export const ImageAndDescription = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  @media ${media.small} {
    flex-direction: row;
    align-items: normal;
    max-height: 223px;
  }
`
