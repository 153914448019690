import ReactDOM from "react-dom"
import { useEffect } from "react"
import {
  ModalCard,
  ModalOverlay,
  ModalContainer,
  ModalContent,
  ModalSize,
  ModalWrapper,
} from "./styles"

import { ModalHeader } from "./components/ModalHeader"
import { ModalFooter } from "./components/ModalFooter"
import { VariantType } from "../ui/Button"

export interface IModalHedro {
  onClose: () => void
  isActionsDisabled?: boolean
  actionLabel: string
  actionVariant?: VariantType
  leftButtonLabel?: string
  leftButtonVariant?: VariantType
  seconds?: number
  onAction?: () => void
  onLeftButton?: () => void
  children: React.ReactNode
  actionBar?: string
  size?: ModalSize
  title: string
  subtitle?: string
}

export const ModalHedro = ({
  onClose,
  onLeftButton,
  onAction,
  seconds,
  isActionsDisabled,
  actionLabel,
  actionVariant = "primary",
  leftButtonLabel,
  leftButtonVariant = "secondary",
  children,
  size = "md",
  title,
  subtitle,
}: IModalHedro) => {
  useEffect(() => {
    document.body.classList.add("overflow-hidden")

    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [])

  return ReactDOM.createPortal(
    <ModalWrapper>
      <ModalOverlay onClick={onClose} />
      <ModalCard $size={size}>
        <ModalContainer>
          <ModalHeader onClose={onClose} title={title} subtitle={subtitle} />
          <ModalContent>{children}</ModalContent>
          <ModalFooter
            isActionsDisabled={isActionsDisabled}
            actionLabel={actionLabel}
            actionVariant={actionVariant}
            leftButtonLabel={leftButtonLabel}
            leftButtonVariant={leftButtonVariant}
            seconds={seconds}
            onAction={onAction}
            onLeftButton={onLeftButton}
          />
        </ModalContainer>
      </ModalCard>
    </ModalWrapper>,
    document.querySelector(".modal-container") as HTMLElement
  )
}
