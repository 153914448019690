import { Result, left, right } from "../../../utils/either/result"
import { ExtraConfig } from "../../Dashboard/entities/Analytics"

export const getPercentPoint = ({
  data,
  configs,
}: {
  data: number[]
  configs: ExtraConfig
}): Result<Error, string | string[]> => {
  if (data.length === 0) return right("")

  if (data.length === 1) {
    const percent = data[0]
    const deltaPercent = (configs.superiorLimit - configs.inferiorLimit) / 100
    const y = configs.inferiorLimit + percent * deltaPercent
    if (isNaN(y)) return left(Error("Invalid data!"))
    return right(y.toFixed(3))
  }

  if (data.length === 2) {
    let points: string[] = []
    for (let i = 0; i < data.length; i++) {
      const percent = data[i]
      const deltaPercent = (configs.superiorLimit - configs.inferiorLimit) / 100
      const y = configs.inferiorLimit + percent * deltaPercent
      points.push(y.toFixed(3))
    }
    return right(points)
  }

  return left(Error("Invalid data!"))
}
