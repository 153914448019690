import { Fragment } from "react/jsx-runtime"
import { FlexContainer } from "../../../ui/FlexContainer"
import { ServiceName } from "../ServiceName"
import { Container, Table } from "./styles"
import { ServiceItem } from "../ServiceItem"
import { Trash } from "phosphor-react"
import theme from "../../../../styles/theme"
import { getHDRServiceAcronym } from "../../../../utils/hdr_services_name"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { Asset } from "../AssetsTable"
import { servicesTypes } from "../../../../store/api/utils"
import { useContext } from "react"
import { AssetsContext, AssetsContextDTO } from "../../context/assetsContext"
import { ChartType } from "../../../Chart/entities/ChartData"
import { ServiceProperties } from "../../../../utils/entities/assets"

export interface Service {
  type: HDR_SERVICES_TYPE
  isHide: boolean
  isDisabled: boolean
  properties?: ServiceProperties
}

interface AssetCardProps {
  asset: Asset
}

export const AssetCard = ({ asset }: AssetCardProps) => {
  const {
    handleIsServiceDisabled,
    handleRemoveInAssetList,
    handleServiceCheckbox,
    handleAxleCheckbox,
    handleChangePath,
    getServiceState,
    getAxlesState,
    getServiceAxes,
    hideHealthService,
    chartType,
    canEditPath,
  } = useContext(AssetsContext) as AssetsContextDTO

  const getServices = (asset: Asset): Service[] => {
    const aux: Record<number, Service> = {}

    for (const service of asset.services) {
      aux[service.type] = {
        type: service.type,
        isDisabled: handleIsServiceDisabled(service),
        isHide: false,
        properties: service.properties,
      }
    }

    for (const type of servicesTypes) {
      if (type === HDR_SERVICES_TYPE.health && hideHealthService) continue

      if (!aux[type]) {
        aux[type] = {
          type,
          isDisabled: type === HDR_SERVICES_TYPE.health ? false : true,
          isHide: type === HDR_SERVICES_TYPE.health ? hideHealthService : true,
        }
      }
    }

    const services: Service[] = Object.entries(aux).map(
      ([type, { isHide, isDisabled, properties }]) => ({
        type: parseInt(type),
        properties,
        isHide,
        isDisabled,
      })
    )

    if (chartType)
      return services.filter(({ type }) => {
        if (chartType === ChartType.ONE) return TYPE_ONE_SERVICE.includes(type)
        if (chartType === ChartType.TWO) return TYPE_TWO_SERVICES.includes(type)
        if (chartType === ChartType.THREE)
          return TYPE_THREE_SERVICES.includes(type)

        return false
      })

    return services
  }

  return (
    <Container>
      <FlexContainer>
        <ServiceName
          fullPath={asset.path}
          canEdit={canEditPath}
          onChangePath={(path) => handleChangePath(asset.id, path)}
        />

        <Table>
          <tbody>
            <Fragment>
              <tr>
                {getServices(asset).map(
                  ({ type, properties, isHide, isDisabled }) => (
                    <td
                      key={type}
                      style={{
                        width: "fit-content",
                        paddingRight: 8,
                      }}
                    >
                      <ServiceItem
                        axes={getServiceAxes(type, properties)}
                        isDisabled={isDisabled}
                        isHide={isHide}
                        label={getHDRServiceAcronym(type)}
                        checked={getServiceState(
                          asset.id,
                          type,
                          getServiceAxes(type, properties).length
                        )}
                        checkedAxes={getAxlesState(asset.id, type)}
                        onChange={(value) =>
                          handleServiceCheckbox(
                            asset.id,
                            type,
                            getServiceAxes(type, properties),
                            value
                          )
                        }
                        onChangeAxle={(axle, value) =>
                          handleAxleCheckbox(asset.id, type, axle, value)
                        }
                      />
                    </td>
                  )
                )}
              </tr>
            </Fragment>
          </tbody>
        </Table>
      </FlexContainer>

      <Trash
        size={24}
        color={theme.palette.gray[800]}
        weight='bold'
        onClick={() => handleRemoveInAssetList(asset.id)}
      />
    </Container>
  )
}

const TYPE_ONE_SERVICE = [
  HDR_SERVICES_TYPE.health,
  HDR_SERVICES_TYPE.temp,
  HDR_SERVICES_TYPE.tempMMM,
  HDR_SERVICES_TYPE.rms2,
  HDR_SERVICES_TYPE.rmms,
  HDR_SERVICES_TYPE.tilt,
  HDR_SERVICES_TYPE.gps,
  HDR_SERVICES_TYPE._4t20,
  HDR_SERVICES_TYPE.ntc,
  HDR_SERVICES_TYPE.pot,
]
const TYPE_TWO_SERVICES = [HDR_SERVICES_TYPE.fft]
const TYPE_THREE_SERVICES = [HDR_SERVICES_TYPE.accRaw]
