import theme from "../../../styles/theme"
import { formatedDate } from "../../../utils/date"
import { CustomTooltip } from "../entities/Serie"

interface linechartOptionsProps {
  title: string
  noDataMessage: string
  loadingMessage: string
  xAxisType: "datetime" | "linear"
  xAxisTitle: string
  yAxisTitle: string
}

const getHeader = (title: string): string => {
  return `<b>${title}</b> </br>`
}

const getDate = (date: string | number) => {
  return `${formatedDate(date)} </br>`
}

const getXInfo = (value: number, unit: string | undefined) => {
  if (!unit) return ""

  return `${value.toFixed(2)} ${unit} </br>`
}

const getYInfo = (value: number | undefined, unit: string) => {
  if (value === undefined) return ""

  return `${value.toFixed(2)} ${unit} </br>`
}

export function lineChartTooltipFormater(
  this: Highcharts.TooltipFormatterContextObject,
  tooltip: Highcharts.Tooltip
) {
  const customOptions = this.point.series.userOptions
    .custom as unknown as CustomTooltip

  const header = getHeader(this.series.name)
  const date = getDate(customOptions.date ?? this.point.x)
  const xInfo = getXInfo(this.point.x, customOptions.measureUnit.x)
  const yInfo = getYInfo(this.point.y, customOptions.measureUnit.y)

  return `
    ${header}
    ${date}
    ${xInfo}
    ${yInfo}
  `
}

export const linechartOptions = ({
  title,
  noDataMessage,
  loadingMessage,
  xAxisType,
  xAxisTitle,
  yAxisTitle,
}: linechartOptionsProps): Highcharts.Options => {
  return {
    title: {
      text: undefined,
    },

    lang: {
      noData: noDataMessage,
      loading: loadingMessage,
    },

    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        color: theme.colors.black,
      },
    },

    credits: {
      enabled: false,
    },

    chart: {
      backgroundColor: theme.palette.gray[50],
      spacingTop: 16,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      events: {
        load() {
          this.showLoading()
        },
        fullscreenOpen: function (this: Highcharts.Chart) {
          this.setTitle({
            text: title,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.text.lg.fontSize,
            },
          })
        },
        fullscreenClose: function (this: Highcharts.Chart) {
          this.setTitle({
            text: undefined,
          })
        },
      },
      panning: {
        enabled: true,
        type: "x",
      },
      zooming: {
        singleTouch: true,
        type: "x",
      },
      panKey: "shift",
    },

    time: {
      useUTC: false,
    },

    accessibility: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: xAxisTitle,
        style: {
          fontSize: "12px",
        },
      },

      type: xAxisType,
    },

    yAxis: {
      labels: {
        formatter: (e) => Number(e.value).toFixed(2),
      },
      title: {
        text: yAxisTitle,
        style: {
          fontSize: "12px",
        },
      },
    },

    boost: {
      enabled: true,
      useGPUTranslations: false,
      seriesThreshold: 1,
    },

    plotOptions: {
      series: {
        boostThreshold: 2000,
        marker: {
          enabled: false,
        },
      },
    },

    legend: {
      enabled: true,
      itemStyle: {
        lineHeight: "16px",
        fontSize: "12px",
        fontWeight: "bold",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
    },

    exporting: {
      enabled: false,
    },
  }
}
