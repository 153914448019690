import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
  border-radius: 4px;

  padding: 12px 16px;

  width: 100%;
`
