import {
  useCreateAssetMutation,
  useUploadAssetImageMutation,
} from "../../../../../store/store"

import { useMemo } from "react"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useCreateAsset = () => {
  const { assets } = useAppTranslate()

  const [createAsset, mutation] = useCreateAssetMutation()
  const [uplodImage] = useUploadAssetImageMutation()

  const isLoading = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const handleCreate = async (
    data: AssetSchema,
    parentId: number,
    picture: File | null | undefined
  ) => {
    if (!parentId) return

    try {
      const asset = await createAsset({
        parentId,
        data,
      }).unwrap()

      if (picture) {
        await uplodImage({
          assetId: asset.id,
          file: picture,
        }).unwrap()
      }

      toast.success(assets.form.toastSucessCreate)

      return asset.id
    } catch (error) {
      toast.error(assets.form.toastErrorCreate)
      console.error(error)
    }
  }

  return {
    handleCreate,
    isLoading,
  }
}
