import { useContext } from "react"
import {
  ChartSchema,
  CreateChartContext,
  CreateChartContextDTO,
} from "../../context/CreateChartContext"
import { ModalConfigureChart } from "../ModalConfigureChart"
import { ModalConfigureSensors } from "../ModalConfigureSensors"
import { ModalConfigureAssets } from "../ModalConfigureAssets"
import { useCreateSensorChart } from "../../hooks/useCreateSensorChart"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface ModalWrapperProps {
  dashboardId: number
}

export const ModalWrapper = ({ dashboardId }: ModalWrapperProps) => {
  const { dashboard } = useAppTranslate()

  const {
    chart,
    isLoading,
    isOpenConfigureChart,
    isOpenConfigureSensors,
    isOpenConfigureAssets,
    analyticToPlot,
    sensorAndServices,
    handleCreateChart,
    handleCloseAll,
    handleBackConfigureChart,
    handleChangeChart,
    handleCreateAssetChart,
  } = useContext(CreateChartContext) as CreateChartContextDTO

  const { handleCreateSensorChart, isCreateSensorDisabled } =
    useCreateSensorChart()

  const handleCreateSensor = () => {
    handleCreateSensorChart(
      analyticToPlot.current,
      sensorAndServices,
      dashboardId
    )
      .then(() => handleCreateChart())
      .catch((error) => {
        if (error.status && error.status === 409) {
          toast.error(dashboard.createChart.conflictError)
          return
        }

        toast.error(dashboard.createChart.error)
      })
  }

  const handleSaveChartData = (data: ChartSchema) => {
    analyticToPlot.current = {
      tag: data.title,
      type: data.type,
      period: data.type === 1 ? Number(data.period) : 0,
      dashboardId,
      sensors: {},
    }
  }

  const isDisabled = sensorAndServices.length === 0 || isCreateSensorDisabled

  return (
    <>
      {isOpenConfigureChart && (
        <ModalConfigureChart
          onClose={handleCloseAll}
          handleSubmitData={(data) => {
            handleChangeChart(data)
            handleSaveChartData(data)
          }}
          defaultValues={chart}
        />
      )}

      {isOpenConfigureSensors && (
        <ModalConfigureSensors
          onClose={handleCloseAll}
          onBack={handleBackConfigureChart}
          onAction={handleCreateSensor}
          isDisabled={isDisabled}
        />
      )}

      {isOpenConfigureAssets && chart && (
        <ModalConfigureAssets
          onClose={handleCloseAll}
          onBack={handleBackConfigureChart}
          onAction={(assets) => handleCreateAssetChart(assets, dashboardId)}
          chartType={chart.type}
          isLoading={isLoading}
        />
      )}
    </>
  )
}
