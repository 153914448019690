import axios, { AxiosInstance } from "axios"

export const apiCall = (): AxiosInstance => {
  const { access_token } = JSON.parse(
    sessionStorage.getItem(sessionStorage.key(0) as string) as string
  )

  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
}

export const apiThorCall = (): AxiosInstance => {
  const { access_token } = JSON.parse(
    sessionStorage.getItem(sessionStorage.key(0) as string) as string
  )

  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_THOR_URL,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
}
