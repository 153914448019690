import { Container, Header, Content } from "./styles"
import { Title } from "../../../../../components/Title"
import {
  TitleAndText,
  TitleAndTextSkeleton,
} from "../../../../../components/TitleAndText"
import { AssetPropertiesDTO } from "../../../../../utils/entities/assets"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Skeleton } from "@mui/material"
import { Text } from "../../../../../components/ui/Text"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"

interface AssetPropertiesProps {
  properties: AssetPropertiesDTO
  skeleton?: boolean
}

const SkeletonComponent = () => {
  return (
    <>
      <Header>
        <Skeleton variant='text' width={100} height={20} />
      </Header>

      <Content>
        {Array.from({ length: 4 }, (_, index) => (
          <TitleAndTextSkeleton key={index} />
        ))}
      </Content>
    </>
  )
}

export const AssetProperties = ({
  properties,
  skeleton,
}: AssetPropertiesProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      {skeleton ? (
        <SkeletonComponent />
      ) : (
        <>
          <Header>
            <Title
              label={assets.details.properties}
              size='sm'
              weight='medium'
            />
          </Header>

          {Object.keys(properties).length ? (
            <Content>
              {Object.entries(properties).map(([key, value]) => (
                <TitleAndText
                  key={key}
                  title={key.replaceAll("_", " ")}
                  text={value}
                />
              ))}
            </Content>
          ) : (
            <FlexContainer justify='center' fullWidth>
              <Text fontSize='sm' color='gray.500' fontWeight='regular'>
                {assets.form.propertiesEmpty}
              </Text>
            </FlexContainer>
          )}
        </>
      )}
    </Container>
  )
}
