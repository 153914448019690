import { Check, DownloadSimple, Funnel } from "phosphor-react"
import { DatetimeInput } from "../../../../../components/ui/DatetimeInput"
import { InputSelect } from "../../../../../components/ui/InputSelect"
import theme from "../../../../../styles/theme"
import {
  ActionsContainer,
  Container,
  FilterMobileWrapper,
  InputWrapper,
  InputsContainer,
} from "./styles"
import { IconButton } from "../../../../../components/ui/IconButton"
import {
  OrientedBy,
  ReportsContext,
  ReportsContextDTO,
} from "../../context/ReportsContext"
import { useContext, useState } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { InputSearch } from "../../../../../components/ui/InputSearch"
import { FilterServices } from "../FilterServices"
import { ProtectedComponent } from "../../../../../utils/authentication/ProtectedComponent"
import { PermissionsProteu } from "../../../../../store/features/user/user.interfaces"

export const HeaderActions = () => {
  const { reports } = useAppTranslate()

  const {
    register,
    handleSearch,
    handleDownloadReport,
    handleUpdateSelectedServices,
    handleChangeOrientedBy,
    orientedBy,
    isStartDateEmpty,
    isEndDateEmpty,
    showSuccessIcon,
    isDownloadDisabled,
  } = useContext(ReportsContext) as ReportsContextDTO

  const [open, setOpen] = useState(false)

  return (
    <Container>
      <InputsContainer>
        <ProtectedComponent permissions={[PermissionsProteu.AssetsEnable]}>
          <InputWrapper>
            <InputSelect
              options={["Sensor", "Asset"]}
              onChange={(e) =>
                handleChangeOrientedBy(e.target.value as OrientedBy)
              }
            />
          </InputWrapper>
        </ProtectedComponent>

        <InputWrapper>
          <DatetimeInput
            placeholder={reports.startDatePlaceholder}
            isEmpty={isStartDateEmpty}
            {...register("startDate")}
          />
        </InputWrapper>

        <InputWrapper>
          <DatetimeInput
            placeholder={reports.endDatePlaceholder}
            isEmpty={isEndDateEmpty}
            {...register("endDate")}
          />
        </InputWrapper>
      </InputsContainer>

      <FilterMobileWrapper>
        <ActionsContainer>
          {orientedBy === "Sensor" && (
            <>
              <InputWrapper>
                <InputSearch
                  onChangeSearch={handleSearch}
                  placeholder={reports.searchSensorPlaceholder}
                />
              </InputWrapper>
              <IconButton
                variant='secondary'
                onClick={() => setOpen((prev) => !prev)}
                icon={<Funnel size={20} />}
              />
            </>
          )}

          <FilterServices
            open={open}
            onUpdateSelectedServices={handleUpdateSelectedServices}
          />

          {!showSuccessIcon && (
            <IconButton
              onClick={handleDownloadReport}
              disabled={isDownloadDisabled}
              icon={
                <DownloadSimple
                  size={20}
                  weight='bold'
                  color={theme.palette.gray[50]}
                />
              }
            />
          )}

          {showSuccessIcon && (
            <IconButton
              disabled={false}
              color={theme.palette.success.main}
              icon={
                <Check size={20} weight='bold' color={theme.palette.gray[50]} />
              }
            />
          )}
        </ActionsContainer>

        <FilterServices
          open={open}
          onUpdateSelectedServices={handleUpdateSelectedServices}
          mobile
        />
      </FilterMobileWrapper>
    </Container>
  )
}
