import { SensorAndServices } from "./components/SensorAndServices"
import { ModalHedro } from "../../../../components/ModalHedro"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

interface ModalConfigureSensorsProps {
  onClose: () => void
  onBack: () => void
  onAction: () => void
  isDisabled: boolean
}

export const ModalConfigureSensors = ({
  onClose,
  onBack,
  onAction,
  isDisabled,
}: ModalConfigureSensorsProps) => {
  const {
    buttons,
    dashboard: { configureSensors },
  } = useAppTranslate()

  return (
    <ModalHedro
      onClose={onClose}
      title={configureSensors.title}
      subtitle={configureSensors.subtitle}
      onLeftButton={onBack}
      leftButtonLabel={buttons.back}
      onAction={onAction}
      actionLabel={configureSensors.submit}
      size='lg'
      isActionsDisabled={isDisabled}
    >
      <SensorAndServices />
    </ModalHedro>
  )
}
