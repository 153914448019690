import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  @media ${media.extraLarge} {
    height: 500px;
  }
`
