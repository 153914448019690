import React, { ReactNode } from "react"
import { ColorType, Size, Weight, Content } from "./styles"

type TextOwnProps<E extends React.ElementType> = {
  as?: E
  family?: string
  fontSize?: Size
  fontWeight?: Weight
  color?: ColorType
  children: ReactNode
  nowrap?: boolean
}

export type TextProps<E extends React.ElementType> = TextOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TextOwnProps<E>>

export const Text = <E extends React.ElementType>({
  as,
  color = "gray.600",
  family,
  fontSize = "sm",
  fontWeight = "regular",
  nowrap = false,
  children,
  ...props
}: TextProps<E>) => {
  const Component = as || "p"

  return (
    <Content
      as={Component}
      $color={color}
      $family={family}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $nowrap={nowrap}
      {...props}
    >
      {children}
    </Content>
  )
}
