import { PropsWithChildren } from "react"
import { useAppSelector } from "../../store/hooks"
import { Navigate } from "react-router-dom"
import { PermissionsProteu } from "../../store/features/user/user.interfaces"

interface ProtectedRouteProps {
  isAuthenticated: boolean
  permissions: PermissionsProteu[] | PermissionsProteu
  children: React.ReactNode
}

export const ProtectedRoute = ({
  isAuthenticated,
  permissions,
  children,
}: PropsWithChildren<ProtectedRouteProps>) => {
  const profile = useAppSelector((state) => state.persistedReducer.user.profile)

  if (!isAuthenticated) {
    localStorage.setItem("redirect_pathname", window.location.pathname)
    return <Navigate to='/login' />
  }

  if (!profile) return <Navigate to='/login' />
  if (profile.permissions.length === 0) return <Navigate to='/login' />

  if (Array.isArray(permissions)) {
    for (let i = 0; i < permissions.length; i++) {
      const permission = permissions[i]
      if (!profile.permissions.includes(permission))
        return <Navigate to='/login' />
    }

    return children
  }

  if (!profile.permissions.includes(permissions))
    return <Navigate to='/login' />

  return children
}
