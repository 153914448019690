import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { IoTData, IoTDataRequest } from "./entities"
import { thorBaseQuery } from "../utils"

export const iotApi = createApi({
  reducerPath: "iotApi",
  baseQuery: thorBaseQuery(),
  tagTypes: ["LIST"],
  endpoints: (builder) => ({
    fetchIotData: builder.query<IoTData[], IoTDataRequest>({
      query: ({ assetsIds, startDate, endDate, serviceType }) => ({
        url: "/v1/iot/assets",
        method: "GET",
        params: {
          asset_id: assetsIds,
          start_date: startDate,
          end_date: endDate,
          service_type: serviceType,
        },
      }),
    }),
  }),
})

export const { useFetchIotDataQuery } = iotApi
