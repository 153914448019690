import { useDeleteAnalyticMutation } from "../../../store/store"

export const useDeleteChart = () => {
  const [deleteChart, mutation] = useDeleteAnalyticMutation()

  const handleDeleteChart = async (chartId: string, dashboardId: number) => {
    await deleteChart({
      analyticId: chartId,
      request: {
        dashboardId,
      },
    }).unwrap()
  }

  return {
    handleDeleteChart,
    isDeleteDisabled: mutation.isLoading,
  }
}
