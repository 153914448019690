import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useRef,
  useState,
} from "react"
import * as z from "zod"
import { toast } from "react-toastify"
import { SensorAndService } from "../components/ModalConfigureSensors/components/SensorAndServices/entities/SensorAndService"
import { useCreateAssetAnalyticMutation } from "../../../store/store"
import {
  AssetAnalyticConfig,
  AssetChartDetails,
} from "../../../store/api/analytics/entities"
import { Analytics } from "../entities/Analytics"

export interface CreateChartContextDTO {
  isOpenConfigureChart: boolean
  isOpenConfigureSensors: boolean
  isOpenConfigureAssets: boolean
  analyticToPlot: MutableRefObject<Analytics | null>
  sensorAndServices: SensorAndService[]
  setSensorAndServices: Dispatch<SetStateAction<SensorAndService[]>>
  handleOpenConfigureChart: () => void
  handleCloseAll: () => void
  handleChangeChart: (data: ChartSchema) => void
  handleBackConfigureChart: () => void
  handleCreateChart: () => void
  handleCreateAssetChart: (
    assets: AssetChartDetails[],
    dashboardId: number
  ) => void
  chart?: ChartSchema
  isLoading: boolean
}

export const CreateChartContext = createContext<CreateChartContextDTO | null>(
  null
)

export const CreateChartContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isOpenConfigureChart, setIsOpenConfigureChart] = useState(false)
  const [isOpenConfigureSensors, setIsOpenConfigureSensors] = useState(false)
  const [isOpenConfigureAssets, setIsOpenConfigureAssets] = useState(false)

  const chart = useRef<ChartSchema | undefined>()

  // Configurantion for the sensor analytics

  const analyticToPlot = useRef<Analytics | null>(null)

  const [sensorAndServices, setSensorAndServices] = useState<
    SensorAndService[]
  >([])

  const [createAssetChart, createAssetChartMutation] =
    useCreateAssetAnalyticMutation()

  const handleOpenConfigureChart = () => {
    setIsOpenConfigureChart(true)
  }

  const handleCloseAll = () => {
    setIsOpenConfigureChart(false)
    setIsOpenConfigureSensors(false)
    setIsOpenConfigureAssets(false)

    chart.current = undefined
  }

  const handleBackConfigureChart = () => {
    setIsOpenConfigureSensors(false)
    setIsOpenConfigureAssets(false)

    setIsOpenConfigureChart(true)
  }

  const handleChangeChart = (data: ChartSchema) => {
    chart.current = data

    if (data.type !== 1) {
      chart.current.period = ""
    }

    setIsOpenConfigureChart(false)

    switch (data.orientedBy) {
      case "sensor":
        setIsOpenConfigureSensors(true)
        break
      case "asset":
        setIsOpenConfigureAssets(true)
        break
    }
  }

  const handleCreateChart = () => {
    chart.current = undefined
    setSensorAndServices([])

    setIsOpenConfigureSensors(false)
  }

  const handleCreateAssetChart = (
    assets: AssetChartDetails[],
    dashboardId: number
  ) => {
    if (!chart.current) return

    const configs: AssetAnalyticConfig[] = assets.map((asset) => {
      return {
        assetId: asset.id,
        caption: asset.path,
        services: asset.services,
      } as AssetAnalyticConfig
    })

    console.log(configs)

    createAssetChart({
      body: {
        dashboardId,
        tag: chart.current.title,
        period: Number(chart.current.period),
        type: chart.current.type,
        configs,
      },
      request: {
        dashboardId,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("Chart created successfully!")

        chart.current = undefined
        setIsOpenConfigureAssets(false)
      })
      .catch((e) => {
        toast.error("Failed to create")
      })
  }

  const defaultContext: CreateChartContextDTO = {
    isOpenConfigureChart,
    isOpenConfigureSensors,
    isOpenConfigureAssets,
    analyticToPlot,
    sensorAndServices,
    setSensorAndServices,
    handleOpenConfigureChart,
    handleCloseAll,
    handleBackConfigureChart,
    handleChangeChart,
    handleCreateChart,
    handleCreateAssetChart,
    chart: chart.current,
    isLoading: createAssetChartMutation.isLoading,
  }

  return (
    <CreateChartContext.Provider value={defaultContext}>
      {children}
    </CreateChartContext.Provider>
  )
}

export const enum SCHEMA_ERRORS {
  REQUIRED = "REQUIRED",
  MIN_TITLE = "MIN_TITLE",
}

export const chartSchema = z
  .object({
    title: z.string().min(3, SCHEMA_ERRORS.MIN_TITLE),
    type: z.number().min(1, SCHEMA_ERRORS.REQUIRED),
    period: z.string(),
    orientedBy: z.enum(["sensor", "asset"]),
  })
  .superRefine(({ type, period }, ctx) => {
    if (type === 1) {
      if (!period) {
        ctx.addIssue({
          message: SCHEMA_ERRORS.REQUIRED,
          code: "too_small",
          path: ["period"],
          minimum: 1,
          type: "number",
          inclusive: true,
        })

        return false
      }
    }
  })

export type ChartSchema = z.infer<typeof chartSchema>
