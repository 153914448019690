import {
  useDeleteAssetImageMutation,
  useUpdateAssetMutation,
  useUploadAssetImageMutation,
} from "../../../../../store/store"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useState } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useConfigureAsset = () => {
  const { assets } = useAppTranslate()

  const [updateAsset] = useUpdateAssetMutation()

  const [uploadImage] = useUploadAssetImageMutation()
  const [deleteImage] = useDeleteAssetImageMutation()

  const [isDisable, setIsDisable] = useState(false)

  const handleSubmit = async (
    data: AssetSchema,
    id: number,
    picture: File | null | undefined
  ) => {
    setIsDisable(true)

    try {
      if (picture) {
        await uploadImage({
          assetId: id,
          file: picture,
        }).unwrap()
      }

      if (picture === null) {
        await deleteImage(id).unwrap()
      }

      const properties: Record<string, string> = {}

      data.properties.forEach(({ property, value }) => {
        properties[property] = value
      })

      await updateAsset({
        id,
        data: {
          name: data.name,
          description: data.description,
          typeId: Number(data.type),
          properties,
        },
      }).unwrap()

      toast.success(assets.form.toastSucessConfigure)
    } catch (e) {
      console.log(e)
      toast.error(assets.form.toastErrorConfigure)
      return null
    } finally {
      setIsDisable(false)
    }
  }

  return {
    handleSubmit,
    isDisable,
  }
}
