import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  background-color: ${(props) => props.theme.palette.gray[300]};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.gray[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: ${(props) => props.theme.palette.gray[500]};
  overflow: hidden;

  width: 100%;
  height: 200px;

  @media ${media.small} {
    width: 243px;
  }

  @media ${media.medium} {
    width: 243px;
  }
`

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  position: relative;

  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  input {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: start;

  gap: 2px;

  width: 100%;

  @media ${media.small} {
    width: 243px;
  }

  @media ${media.large} {
    min-width: 243px;
  }
`

export const EditImage = styled.div`
  button {
    color: ${(props) => props.theme.palette.gray[500]};
  }

  &:hover {
    button {
      color: ${(props) => props.theme.palette.gray[600]};
    }
  }
`

export const RemoveImage = styled.div`
  button {
    color: ${(props) => props.theme.palette.error.main};
  }
`

export const ContainerStorybook = styled.div`
  display: flex;
  left: 0;
  justify-content: center;
  flex-direction: column;
  width: 223px;
  height: 215px;
  gap: 20px;
`

export const ImageOptions = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`
