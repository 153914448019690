import styled from "styled-components"

export const Footer = styled.footer`
  font-size: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const TextWrapper = styled.div<{ $align: "start" | "end" }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => $align};
  width: 100px;
`
