import { useEffect, useState } from "react"
import { Button, ButtonProps } from "../Button"
import { useAppTranslate } from "../../../translate/useAppTranslate"

interface ButtonTimeoutProps extends ButtonProps {
  seconds: number
}

export const ButtonTimeout = ({
  seconds,
  children,
  disabled,
  ...props
}: ButtonTimeoutProps) => {
  const [isDisabled, setIsDisabled] = useState(seconds > 0)
  const [counter, setCounter] = useState(seconds || 0)

  const { buttons } = useAppTranslate()

  useEffect(() => {
    if (seconds <= 0) return

    setIsDisabled(true)
    setCounter(seconds)

    const startTime = Date.now()

    const interval = setInterval(() => {
      const currentTime = Date.now()
      const elapsedTime = Math.floor((currentTime - startTime) / 1000)
      const remainingTime = seconds - elapsedTime

      if (remainingTime <= 0) {
        clearInterval(interval)
        setIsDisabled(false)
      } else {
        setCounter(remainingTime)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  return (
    <Button disabled={isDisabled || disabled} {...props}>
      {isDisabled ? `${buttons.wait} (${counter})` : children}
    </Button>
  )
}
