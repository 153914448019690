import { BsTrash } from "react-icons/bs"
import { BiSolidPencil } from "react-icons/bi"
import * as S from "./styles"
import { useDashToolsController } from "./controller/useDashToolsController"
import { GearSix } from "@phosphor-icons/react"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { IconButton } from "../../../../../../components/ui/IconButton"

interface DashToolsProps {
  onEdit: () => void
  onDelete: () => void
}

export const DashTools = ({ onEdit, onDelete }: DashToolsProps) => {
  const { buttons } = useAppTranslate()

  const { toggleDropdown, isOpen, dropdownRef } = useDashToolsController()

  const handleEdit = () => {
    onEdit()
    toggleDropdown()
  }

  const handleDelete = () => {
    onDelete()
    toggleDropdown()
  }

  return (
    <S.ConfigIconWrapper data-testid='tools-dashboard'>
      <IconButton
        variant='secondary'
        icon={<GearSix size={24} />}
        onClick={toggleDropdown}
      />
      {isOpen && (
        <S.Dropdown data-testid='tools-dashboard__dropdown' ref={dropdownRef}>
          <S.DropdownItem
            data-testid='tools-dashboard__edit-btn'
            onClick={handleEdit}
          >
            <BiSolidPencil /> {buttons.edit}
          </S.DropdownItem>
          <S.DropdownItem
            data-testid='tools-dashboard__delete-btn'
            onClick={handleDelete}
          >
            <BsTrash /> {buttons.delete}
          </S.DropdownItem>
        </S.Dropdown>
      )}
    </S.ConfigIconWrapper>
  )
}
