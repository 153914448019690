/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { differenceInDays, isBefore } from "date-fns"
import { MutableRefObject, useContext, useRef, useState } from "react"
import { toast } from "react-toastify"
import { HistoricalAnalysisContext } from "../context/modelContext"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { OrbitData } from "../entities/Orbit"
import { DataPlotDTO } from "../entities/dataSeriesDTO"
import { ServiceDTO } from "../entities/serviceDTO"
import { useDataAnalytics } from "./processDataAnalytics"
import { AnalyticConfigDTO } from "../entities/analyticConfigDTO"
import { AccRawData, FFTData } from "../entities/processDataWithMacDTO"
import { TemporarySensorsDTO } from "../entities/temporarySensorsDTO"
import { SensorAndServicesDTO } from "../entities/sensorAndServicesDTO"
import {
  setAnalyticsMetadata,
  setLastConfig,
} from "../../../store/features/historicalAnalysis/historicalAnalysis.slice"
import { useAppDispatch } from "../../../store/hooks"
import { SensorAvailablesServices } from "../../../store/features/reports/reports.interfaces"
import { LastConfigDTO } from "../entities/lastConfigDTO"
export const useConfigMenu = () => {
  const dispatchActions = useAppDispatch()
  const {
    setFFTProcessedData,
    setLinearGraphData,
    setAccRawProcessedData,
    getOrbitData,
  } = useDataAnalytics()

  const {
    sensors,
    setDataToPlot,
    analyticFullConfig,
    potArrayConfig,
    openConfigModal,
    setOpenConfigModal,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    formGraphType,
    setPlotGraph,
    selectedServices,
    setSelectedServices,
    setChartTypeSelected,
    setIsLoadingData,
    setFormGraphType,
    selectedOrbitItems,
    setSelectedOrbitItems,
    setOpenFilterSelection,
    setFilteredSensors,
    availablesServices,
    setSearchInput,
    setOpenFilterServices,
    filterSelected,
    setFilterSelected,
    filterServiceSelected,
    setFilterServiceSelected,
    handleCloseHistoricalAnalysisConfig,
    setSelectorIndex,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const sensorAndServices = useRef<SensorAndServicesDTO[]>([])

  let errorMessage = ""

  const [sensorsAndAvailablesServices] = useState({})

  const saveLastConfigInRedux = (
    data: DataPlotDTO[] | FFTData[] | AccRawData[] | OrbitData[],
    selectedServices: ServiceDTO[],
    startDate: Date,
    endDate: Date,
    formGraphType: number
  ) => {
    const lastConfig: LastConfigDTO = {
      data,
      selectedServices,
      startDate,
      endDate,
      formGraphType,
    }
    dispatchActions(setLastConfig(lastConfig))
  }

  const handleSetDataToPlot = (
    data: DataPlotDTO[] | FFTData[] | AccRawData[] | OrbitData[],
    typeChart: number
  ) => {
    setDataToPlot(data)
    setChartTypeSelected(typeChart)
  }

  const [isLoadingServices] = useState(true)

  const changeSelectedOrbit = (name: string) => {
    const newSelected = selectedOrbitItems

    newSelected.forEach((value) => {
      if (value.name === name) {
        value.checked = !value.checked
      }
    })

    setSelectedOrbitItems([...newSelected])
  }

  const changeSelectFormGraphType = (newType: number) => {
    setFormGraphType(newType)
  }

  const clearSelectedServices = () => {
    setSelectedServices([])
  }

  function changeSelectedServices(
    checked: boolean,
    service: string,
    sensorName: string | null,
    productionSerialNumber: string | null,
    mac: string
  ) {
    if (checked) {
      let nameSensor

      if (sensorName == null || sensorName == "") {
        if (productionSerialNumber !== null)
          nameSensor = productionSerialNumber.slice(-4)
      } else {
        nameSensor = sensorName
      }

      let new_service: ServiceDTO = {
        service: service,
        mac: mac,
        name: nameSensor as string,
      }

      if (service === "fft" || service === "accRaw") {
        setSelectedServices([new_service])
      } else {
        setSelectedServices([...selectedServices, new_service])
      }
    } else {
      const selectedServicesFiltered: ServiceDTO[] = []

      selectedServices.forEach((selectedService) => {
        if (selectedService.service === service) {
          if (selectedService.mac !== mac) {
            selectedServicesFiltered.push(selectedService)
          }
        } else {
          selectedServicesFiltered.push(selectedService)
        }
      })

      setSelectedServices(selectedServicesFiltered)
    }
  }

  function checkServiceSelected(service: string, mac: string) {
    let checked = false

    selectedServices.forEach((selectedService) => {
      if (selectedService.service === service) {
        if (selectedService.mac === mac) {
          checked = true
        }
      }
    })

    return checked
  }

  async function submitConfigs() {
    const analyticsData = verifyDataAndCreateAnalytic(
      selectedServices,
      startDate,
      endDate,
      formGraphType
    )

    if (Object.keys(analyticsData).length === 0) {
      return toast.info(errorMessage)
    }

    setIsLoadingData(true)

    setDataToPlot([] as DataPlotDTO[])
    switch (formGraphType) {
      case 1: {
        const response = await setLinearGraphData(
          analyticsData,
          startDate,
          endDate
        )

        if (response.length === 0) {
          setIsLoadingData(false)
          return toast.info("Não há dados")
        }

        let responseDTO = [] as DataPlotDTO[]
        response.map((element) => {
          let aux: DataPlotDTO = {
            sensorMac: element.mac,
            data: {
              type: element.service,
              name: element.name,
              data: element.data,
            },
          }
          responseDTO.push(aux)
        })
        setIsLoadingData(false)
        handleSetDataToPlot(responseDTO, 1)
        saveLastConfigInRedux(
          responseDTO,
          selectedServices,
          startDate,
          endDate,
          formGraphType
        )
        break
      }
      case 2: {
        const response = (await setFFTProcessedData(
          analyticsData,
          startDate,
          endDate
        )) as FFTData[]

        if (response.length === 0) {
          setIsLoadingData(false)
          return toast.info("Não há dados")
        }
        handleSetDataToPlot(response, 2)
        saveLastConfigInRedux(
          response,
          selectedServices,
          startDate,
          endDate,
          formGraphType
        )
        setSelectorIndex(0)
        setIsLoadingData(false)
        break
      }

      case 3: {
        const response = (await setAccRawProcessedData(
          analyticsData,
          analyticsData.id,
          startDate,
          endDate
        )) as AccRawData[]

        setIsLoadingData(false)

        if (response.length === 0) return toast.info("Não há dados")

        handleSetDataToPlot(response, 3)
        saveLastConfigInRedux(
          response,
          selectedServices,
          startDate,
          endDate,
          formGraphType
        )
        setSelectorIndex(0)

        break
      }

      /*Horímetro*/
      case 4: {
        const response = await setLinearGraphData(
          analyticsData,
          startDate,
          endDate
        )
        if (response.length === 0) {
          setIsLoadingData(false)
          return toast.info("Não há dados")
        }

        let responseDTO = [] as DataPlotDTO[]
        response.map((element) => {
          let aux: DataPlotDTO = {
            sensorMac: element.mac,
            data: {
              type: element.service,
              name: element.name,
              data: element.data,
            },
          }
          responseDTO.push(aux)
        })

        handleSetDataToPlot(responseDTO, 4)
        saveLastConfigInRedux(
          responseDTO,
          selectedServices,
          startDate,
          endDate,
          formGraphType
        )
        setIsLoadingData(false)
        break
      }

      case 5: {
        const response = await getOrbitData(analyticsData, startDate, endDate)

        handleSetDataToPlot(response as unknown as OrbitData[], 5)
        saveLastConfigInRedux(
          response as unknown as OrbitData[],
          selectedServices,
          startDate,
          endDate,
          formGraphType
        )
        setIsLoadingData(false)
        break
      }

      default: {
        setIsLoadingData(false)
        return toast.info("Não há dados")
      }
    }
    return true
  }

  function _cleanSensorAndServices() {
    sensorAndServices.current = []
  }

  function _getAllAxisFromService(services: ServiceDTO[]) {
    let newServices: ServiceDTO[] = []

    services.forEach((service) => {
      switch (service.service.toUpperCase()) {
        case "TEMP":
          newServices.push({ ...service, service: "temp" })
          break

        case "POT":
          newServices.push({ ...service, service: "pot" })
          break

        case "_4T20":
          newServices.push({ ...service, service: "_4t20ChanelA" })
          newServices.push({ ...service, service: "_4t20ChanelB" })

          break

        case "NTC":
          newServices.push({ ...service, service: "ntcChanelA" })
          newServices.push({ ...service, service: "ntcChanelB" })
          break

        case "RMMS":
          newServices.push({ ...service, service: "rmmsx" })
          newServices.push({ ...service, service: "rmmsy" })
          newServices.push({ ...service, service: "rmmsz" })
          newServices.push({ ...service, service: "rmmsVectorModule" })
          break

        case "RMS2":
          newServices.push({ ...service, service: "rms2x" })
          newServices.push({ ...service, service: "rms2y" })
          newServices.push({ ...service, service: "rms2z" })
          newServices.push({ ...service, service: "rms2VectorModule" })
          break

        case "FFT":
          newServices.push({ ...service, service: "fftx" })
          newServices.push({ ...service, service: "ffty" })
          newServices.push({ ...service, service: "fftz" })
          newServices.push({ ...service, service: "fftVectorModule" })
          break

        case "ACCRAW":
          newServices.push({ ...service, service: "accRawx" })
          newServices.push({ ...service, service: "accRawy" })
          newServices.push({ ...service, service: "accRawz" })
          newServices.push({ ...service, service: "accRawVectorModule" })
          break

        case "HEALTH":
          newServices.push({ ...service, service: "health" })
          break

        default:
          break
      }
    })

    return newServices
  }

  function _setAnalyticFullConfig(
    services: ServiceDTO[],
    selectedGraphType: number
  ) {
    services = _getAllAxisFromService(services)
    analyticFullConfig.current = {
      type: selectedGraphType,
      sensors: {},
    }

    if (selectedGraphType === 2 || selectedGraphType === 3) {
      analyticFullConfig.current = {
        ...analyticFullConfig.current,
        period: 0,
      }
    } else {
      analyticFullConfig.current = {
        ...analyticFullConfig.current,
        startDate: startDate,
        endDate: endDate,
      }
    }

    services.forEach((service) => {
      let response = _filteringFunction(
        /*checked*/ true,
        service.service,
        service.mac,
        service.name,
        sensorAndServices.current
      )
      sensorAndServices.current = response
    })
    analyticFullConfig.current = {
      ...analyticFullConfig.current,
      sensors: _createsObject(sensorAndServices, potArrayConfig),
    }
  }

  function _filteringFunction(
    checked: boolean,
    service: string,
    mac: string,
    name: string,
    sensorAndServices: any
  ) {
    if (checked) {
      let temp = {
        service: service,
        mac: mac,
        name: name,
      }

      sensorAndServices.push(temp)
    } else {
      let sensorAndServicesTempData = sensorAndServices
      sensorAndServices = []

      sensorAndServicesTempData.filter(
        (item: { mac: string; service: string }) => {
          if (item.mac !== mac) {
            sensorAndServices.push(item)
          } else {
            if (item.service !== service) {
              sensorAndServices.push(item)
            }
          }
          return null
        }
      )
    }
    return sensorAndServices
  }

  function _createsObject(
    sensorAndServices: MutableRefObject<SensorAndServicesDTO[]>,
    potArrayConfig: {}
  ) {
    let temporarySensors: TemporarySensorsDTO = {}

    sensorAndServices.current.forEach((item) => {
      if (
        temporarySensors[item.service] !== undefined &&
        temporarySensors[item.service] !== null
      ) {
        temporarySensors[item.service].push({
          mac: item.mac,
          name: item.name,
        })
      } else {
        temporarySensors[item.service] = [
          {
            mac: item.mac,
            name: item.name,
          },
        ]
      }
    })

    return temporarySensors
  }

  function _validateNumberOfServices(servicesLength: number) {
    if (servicesLength > 0) {
      return true
    } else {
      errorMessage = "Obrigátorio: Selecionar pelos menos um serviço!"
      return false
    }
  }

  function changeEndDate(date: Date) {
    let day = date.getDate()

    let now = new Date()

    if (day === now.getDate()) {
      date.setHours(now.getHours(), now.getMinutes(), now.getSeconds())
    } else {
      date.setHours(23, 59, 59)
    }

    setEndDate(date)
  }

  function _validateStartAndEndDate(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (differenceInDays(endDate, startDate) > 30) {
        errorMessage = "Atenção: Selecionar um período com no máximo 30 dias!"
        return false
      } else {
        if (isBefore(endDate, startDate)) {
          errorMessage = "Atenção: Data final anterior a data inicial!"
          return false
        }

        return true
      }
    } else {
      errorMessage = "Obrigátorio: Selecionar um período!"
      return false
    }
  }

  const _validateOrbitSelection = (selectedGraphType: number) => {
    if (selectedGraphType !== 5) return true

    let checked = false

    selectedOrbitItems.forEach((item) => {
      if (item.checked) checked = true
    })

    if (!checked) {
      errorMessage = "Obrigátorio: Selecionar pelos menos um eixo!"
      return false
    } else return true
  }

  function verifyDataAndCreateAnalytic(
    services: SensorAndServicesDTO[],
    startDate: Date,
    endDate: Date,
    selectedGraphType: number
  ) {
    let newAnalytic = {} as AnalyticConfigDTO

    if (_validateNumberOfServices(services.length)) {
      if (_validateStartAndEndDate(startDate, endDate)) {
        if (_validateOrbitSelection(selectedGraphType)) {
          newAnalytic = createAnalytic(services, selectedGraphType)
          handleCloseHistoricalAnalysisConfig()

          if (openConfigModal) {
            setOpenConfigModal(false)
          } else {
            setPlotGraph(true)
          }
          return newAnalytic
        }
      }
    }
    return newAnalytic
  }

  function createAnalytic(
    services: SensorAndServicesDTO[],
    selectedGraphType: number
  ) {
    _cleanSensorAndServices()

    _setAnalyticFullConfig(services, selectedGraphType)

    dispatchActions(setAnalyticsMetadata(analyticFullConfig.current))
    return analyticFullConfig.current
  }

  const clearFilter = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setOpenFilterSelection(false)
    setFilterSelected("")
    setFilteredSensors([])
    setSearchInput("")
    if (filterSelected !== "Serviço")
      (document.getElementById("input") as HTMLInputElement).value = ""
  }

  const handleFilterSelection = (
    e: React.MouseEvent<HTMLElement>,
    option: string,
    mac?: string
  ): void => {
    e.stopPropagation()
    setFilterSelected(option)
    setSearchInput("")
    if (filterSelected !== "Serviço")
      (document.getElementById("input") as HTMLInputElement).value = ""
    setOpenFilterSelection(false)
  }

  const handleFilteredSensors = (
    option: string
  ): string | number | undefined => {
    let searchSensorByFilter: SensorAvailablesServices[] = []

    setFilteredSensors([])

    availablesServices.forEach((sensor) => {
      sensor.services.forEach((service) => {
        if (service.nameService.toUpperCase() === option && service.hasData) {
          searchSensorByFilter.push(sensor)
          setFilteredSensors((prevState) => [...prevState, sensor])
        }
      })
    })
    if (searchSensorByFilter.length === 0)
      return toast.info(`Não há sensores com ${option} cadastrado `)
  }

  const handleFilterDropdown = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setOpenFilterSelection(true)
  }

  const handleFilterServicesDropdown = (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    e.stopPropagation()
    setOpenFilterSelection(false)
    setOpenFilterServices(true)
  }

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue)
    let aux: SensorAvailablesServices[] = []

    if (searchValue.length === 0) {
      setFilteredSensors([])
    } else {
      filterSelected === "Nome"
        ? availablesServices.forEach((element) => {
            if (
              element.name &&
              element.name.toLowerCase().startsWith(searchValue.toLowerCase())
            ) {
              aux.push(element)
            }
          })
        : availablesServices.forEach((element) => {
            if (
              element.mac &&
              element.mac.toLowerCase().startsWith(searchValue.toLowerCase())
            ) {
              aux.push(element)
            }
          })
      setFilteredSensors(aux)
    }
  }

  const handleFilterServiceSelection = (
    e: React.MouseEvent<HTMLElement>,
    option: string
  ): void => {
    e.stopPropagation()
    setFilterServiceSelected(option)
    setOpenFilterSelection(false)
    setOpenFilterServices(false)
    handleFilteredSensors(option)
  }

  return {
    changeSelectedServices,
    changeEndDate,
    checkServiceSelected,
    submitConfigs,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sensorsAndAvailablesServices,
    isLoadingServices,
    sensors,
    selectedServices,
    changeSelectFormGraphType,
    changeSelectedOrbit,
    clearSelectedServices,
    clearFilter,
    filterSelected,
    handleFilterSelection,
    handleFilterDropdown,
    searchItems,
    handleFilterServicesDropdown,
    handleFilterServiceSelection,
    filterServiceSelected,
  }
}
