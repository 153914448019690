import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react"
import { AssetData } from "../../../utils/entities/assets"
import { ApiAssetTree } from "./entities"
import { AssetTreeData } from "../../../components/ui/AssetsTree/entities"

export const transformAssetsStructure = (response: ApiAssetTree[]) => {
  const tree: AssetTreeData = {}

  const rootChildren: number[] = []

  response.forEach((asset) => {
    if (asset.isRoot) rootChildren.push(asset.id)

    tree[asset.id] = {
      index: asset.id,
      data: {
        path: asset.path,
        id: asset.id,
        name: asset.name,
        typeId: asset.typeId,
        services: asset.sensor ? asset.sensor.services : undefined,
      },
      canMove: false,
      canRename: false,
      children: asset.children,
      isFolder: asset.children.length > 0,
    }
  })

  tree["master"] = {
    index: "master",
    children: rootChildren,
    data: {
      id: -1,
      name: "Master",
      typeId: 1,
    },
  }

  return tree
}

export const transformAssetsChildren = (
  response: ApiAssetTree[],
  meta: FetchBaseQueryMeta | undefined,
  assetId: number
): AssetData[] | undefined => {
  const find = response.find((asset) => asset.id === assetId)

  if (!find) return undefined

  return response.filter((asset) => find.children.includes(asset.id))
}
