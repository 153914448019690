/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { HistoricalAnalysisContext } from "../context/modelContext"
import { BsGear } from "react-icons/bs"
import TimeSelector from "../components/TimeSelector"
import { AiOutlineAreaChart, AiOutlineBarChart } from "react-icons/ai"
import { useHistoricalAnalysis } from "../controllers/useHistoricalAnalysis"
import { ContextAnaliseHistorica } from "../entities/contextDTO"
import { Root } from "../../../components/Modal"
import { ToggleFFT } from "../../../components/ToggleFFT"

import {
  Container,
  Page,
  Config,
  Chart3DAxisChoice,
  AxisContainerChoice,
  ToggleDivHourMeter,
  ContainerOptnsFFT,
  Footer,
  FooterText,
  ToggleLabel,
  AxisLabel,
} from "./styles"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const HistoricalAnalysisView = () => {
  const {
    chartTypeSelect,
    axisFft,
    setAxisFftSelected,
    onChangeFftAccOrVeloc,
    fftAccOrVeloc,
    hmDetailedOrAccumuled,
    onChangeHmDetailedOrAccumulated,
    selectorText,
    isLoadingData,
    dataToPlot,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const {
    chart,
    setFft3D,
    fft3D,
    processSelectorIndex,
    handleOpenHistoricalAnalysisConfig,
  } = useHistoricalAnalysis()

  const { historicalAnalysis } = useAppTranslate()

  const [fftIcon, setFftIcon] = useState<JSX.Element | null>(null)
  const [axisChoice, setAxisChoice] = useState<JSX.Element | null>(null)

  const onChangeAxis = (axis: String) => {
    setAxisFftSelected(Number(axis))
  }

  useEffect(() => {
    if (axisFft === "all") {
      setAxisChoice(
        <AxisLabel>
          {historicalAnalysis.fft3d.selectAxis}
          <AxisContainerChoice>
            <input
              type='radio'
              value={0}
              name='axisChoice'
              onChange={(e) => onChangeAxis(e.target.value)}
              defaultChecked
            />
            X
            <input
              type='radio'
              value={1}
              name='axisChoice'
              onChange={(e) => onChangeAxis(e.target.value)}
            />
            Y
            <input
              type='radio'
              value={2}
              name='axisChoice'
              onChange={(e) => onChangeAxis(e.target.value)}
            />
            Z
          </AxisContainerChoice>
        </AxisLabel>
      )
    } else if (chartTypeSelect === 2) {
      setAxisChoice(<>EIXO {axisFft}</>)
    }
  }, [axisFft, chartTypeSelect])

  useEffect(() => {
    if (chartTypeSelect === 2) {
      setFftIcon(
        fft3D ? (
          <AiOutlineBarChart onClick={() => setFft3D(!fft3D)} size={30} />
        ) : (
          <AiOutlineAreaChart onClick={() => setFft3D(!fft3D)} size={30} />
        )
      )
    } else {
      setFftIcon(null)
    }
  }, [chartTypeSelect, fft3D])

  return (
    <>
      <Page>
        <Container>
          <Config>
            <BsGear onClick={handleOpenHistoricalAnalysisConfig} size={30} />
            {fftIcon}
          </Config>
          {chart}

          <ContainerOptnsFFT>
            {chartTypeSelect === 2 && !fft3D && !isLoadingData && (
              <Footer>
                <FooterText $isbold={fftAccOrVeloc !== "veloc"}>
                  FFT da Aceleração
                </FooterText>
                <ToggleFFT
                  onChange={onChangeFftAccOrVeloc}
                  checked={fftAccOrVeloc === "veloc"}
                />
                <FooterText $isbold={fftAccOrVeloc === "veloc"}>
                  FFT da Velocidade
                </FooterText>
              </Footer>
            )}

            {chartTypeSelect === 2 && fft3D && !isLoadingData && (
              <Chart3DAxisChoice>{axisChoice}</Chart3DAxisChoice>
            )}

            {(chartTypeSelect === 3 ||
              chartTypeSelect === 5 ||
              (chartTypeSelect === 2 && !fft3D)) &&
              dataToPlot.length !== 0 && (
                <TimeSelector
                  next={() => processSelectorIndex(1)}
                  previus={() => processSelectorIndex(-1)}
                  value={selectorText}
                />
              )}
          </ContainerOptnsFFT>

          {chartTypeSelect === 4 && !isLoadingData && (
            <ToggleDivHourMeter>
              <ToggleLabel>
                <FooterText $isbold={hmDetailedOrAccumuled === "acumulado"}>
                  Acumulado
                </FooterText>
                <ToggleFFT
                  onChange={onChangeHmDetailedOrAccumulated}
                  checked={hmDetailedOrAccumuled !== "acumulado"}
                />
                <FooterText $isbold={hmDetailedOrAccumuled !== "acumulado"}>
                  Detalhado
                </FooterText>
              </ToggleLabel>
            </ToggleDivHourMeter>
          )}
        </Container>
      </Page>
      <Root />
    </>
  )
}
