import { useEffect, useState } from "react"
import { useAuth } from "react-oidc-context"
import { useNavigate } from "react-router-dom"
import {
  fetchSensorsConfig,
  fetchSensorsProv,
} from "../../../store/features/sensors/sensors.api"
import { setUserProfile } from "../../../store/features/user/user.slice"
import { useAppDispatch } from "../../../store/hooks"
import { Profile } from "../../../store/features/user/user.interfaces"
import { fetchGateways } from "../../../store/features/gateways/gateways.api"
import {
  fetchProvisionedSensorsRequest,
  fetchSensorWithServices,
} from "../../../store/features/reports/reports.api"
import { resetLastConfig } from "../../../store/features/historicalAnalysis/historicalAnalysis.slice"
import { GetPayloadFromAccessToken } from "../../../utils/authentication/GetPayloadFromAccessToken"

export const useLoginController = () => {
  const [error, setError] = useState<string | null>(null)

  const auth = useAuth()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const getUserProfile = () => {
    const { user } = auth

    if (!user || typeof user.profile !== "object") {
      return null
    }
    const profile = GetPayloadFromAccessToken(user.access_token)
    if (profile.isLeft()) {
      return null
    }
    return profile.value as Profile
  }

  const loadData = async () => {
    const profile = getUserProfile()

    if (!profile) {
      // renderizar um aviso na tela de login que o usuario não está configurado no sistema favor informar ao gerente da aplicação
      setError(
        "Usuário não está cadastrado no sistema. Favor informar ao gerente da aplicação." // melhorar esse cara, colocar como modal um aviso mais bonitinho
      )
      return
    }

    /*
    Todo:
    * Melhorar a logica aqui,caso der algum erro para configurar o sistema, informar uma mensagem de erro ou redirecionar para uma tela de erro
    */
    dispatch(setUserProfile(profile))
    await dispatch(fetchProvisionedSensorsRequest())
    await dispatch(fetchSensorWithServices())
    await dispatch(fetchGateways())
    await dispatch(fetchSensorsConfig())
    await dispatch(fetchSensorsProv())
    dispatch(resetLastConfig()) // todo validar esse cara, me parece gambiarra

    const pathname = localStorage.getItem("redirect_pathname")

    if (pathname) {
      localStorage.removeItem("redirect_pathname")
      navigate(pathname)
    } else {
      navigate("/dashboard")
    }
  }

  useEffect(() => {
    if (auth.isLoading) return // se estiver carregando a lib ainda retornar do useEffect

    if (!auth.isAuthenticated) {
      auth.signinRedirect() // se estiver sem authentificação mandar para a pagina de login
      return //necessario ,se não ele executa as açoes abaixo sem poder toda ação de negação em um useEffect deve dar return para matar o restante
    }

    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated])

  return { error }
}
