import { formatedDate } from "../../../utils/date"
import { CustomTooltip } from "../../Chart/entities/Serie"

const getHeader = (title: string): string => {
  return `<b>${title}</b> </br>`
}

const getDate = (date: string | number) => {
  return `${formatedDate(date)} </br>`
}

const getXInfo = (value: number, unit: string | undefined) => {
  if (!unit) return ""

  return `${value.toFixed(2)} ${unit} </br>`
}

const getYInfo = (value: number | undefined, unit: string) => {
  if (value === undefined) return ""

  return `${value.toFixed(2)} ${unit} </br>`
}

export function lineChartTooltipFormater(
  this: Highcharts.TooltipFormatterContextObject,
  tooltip: Highcharts.Tooltip
) {
  const customOptions = this.point.series.userOptions
    .custom as unknown as CustomTooltip

  const header = getHeader(this.series.name)
  const date = getDate(customOptions.date ?? this.point.x)
  const xInfo = getXInfo(this.point.x, customOptions.measureUnit.x)
  const yInfo = getYInfo(this.point.y, customOptions.measureUnit.y)

  return `
    ${header}
    ${date}
    ${xInfo}
    ${yInfo}
  `
}
