import { FC } from "react"
import { useSensorAndServicesController } from "./controller/useSensorAndServicesController"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { TableSensors } from "../TableSensors"

import * as S from "./styles"

export const SensorAndServices: FC = () => {
  const { hasSensors } = useSensorAndServicesController()

  const { modalAnalyticConfig } = useAppTranslate()

  return (
    <>
      {hasSensors ? (
        <S.Container>
          <TableSensors />
        </S.Container>
      ) : (
        <S.Message>
          {modalAnalyticConfig.no_sensor_with_this_algorithm_available}
        </S.Message>
      )}
    </>
  )
}
