import styled from "styled-components"
import { media } from "../../utils/media-queries"

export type ModalSize = "sm" | "md" | "lg" | "xl"

const handleSizeType = (size: ModalSize) => {
  switch (size) {
    case "sm":
      return "400px"
    case "md":
      return "650px"
    case "lg":
      return "850px"
    case "xl":
      return "1260px"
  }
}

export const ModalWrapper = styled.div`
  position: absolute;
  z-index: 9999;
`

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${(props) => props.theme.palette.gray[800]};
  opacity: 30%;
`

export const ModalCard = styled.div<{ $size: ModalSize }>`
  position: fixed;
  background-color: ${(props) => props.theme.palette.gray[50]};
  padding: 24px;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  width: 100%;
  @media ${media.small} {
    max-height: 85vh;
    max-width: ${({ $size }) => handleSizeType($size)};
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const MHeadar = styled.div`
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    h1 {
      font-family: ${(props) => props.theme.typography.text.md.fontFamily};
      font-size: ${(props) => props.theme.typography.text.md.fontSize};
      line-height: ${(props) => props.theme.typography.text.md.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
    }

    h2 {
      font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
      font-size: ${(props) => props.theme.typography.text.sm.fontSize};
      line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    }
  }
`

export const MFooter = styled.div`
  padding-top: 24px;
  width: 100%;
`
