import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px 12px 0;

  @media ${media.small} {
    flex-direction: row;
    padding: 0 12px 0 0;
  }
`

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;

  padding: 0 0 0 12px;

  @media ${media.small} {
    width: fit-content;
  }
`
