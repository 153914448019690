import { useAppSelector } from "../../../../../../../store/hooks"

export const useSensorAndServicesController = () => {
  const sensorsConfig = useAppSelector(
    (state) => state.persistedReducer.sensors.config
  )

  const hasSensors = sensorsConfig.length !== 0

  return {
    hasSensors,
  }
}
