import { useAssetData } from "../../../../utils/hooks/useAssetData"
import {
  CsvDataDTO,
  ProcessedDataDTO,
  ServicesProcessedsDTO,
} from "../../../../store/features/reports/reports.interfaces"
import {
  _4T20,
  AccRaw,
  FFT,
  HDR_SERVICES_TYPE,
  Health,
  NTC,
  POT,
  RMMS,
  RMS2,
  Temperature,
  Tilt,
} from "hdr-process-data"
import { writeCSVFile } from "../../../../store/features/reports/reports.request"
import { downloadCSV } from "../../../../store/features/reports/reports.csv"
import { AssetChartDetails } from "../../../../store/api/analytics/entities"

export const useAssetsReport = () => {
  const { getProcessedDataFromApi } = useAssetData()

  const getServicesProcessedsDTO = (asset: AssetChartDetails) => {
    if (!asset.services) {
      console.error("Asset without services")

      return {
        services: [],
      }
    }

    const services = Object.keys(asset.services)

    const servicesProcessed: ServicesProcessedsDTO = {
      services: services.map((service) => Number(service)),
    }

    for (let index = 0; index < services.length; index++) {
      const type = Number(services[index]) as HDR_SERVICES_TYPE

      switch (type) {
        case HDR_SERVICES_TYPE.health:
          servicesProcessed.health = { ok: 1 }
          break

        case HDR_SERVICES_TYPE.temp:
          servicesProcessed.temp = { ok: 1 }
          break

        case HDR_SERVICES_TYPE.rms2:
          servicesProcessed.rms2 = { axis: "all" }
          break

        case HDR_SERVICES_TYPE.rmms:
          servicesProcessed.rmms = { axis: "all" }
          break

        case HDR_SERVICES_TYPE.tilt:
          servicesProcessed.tilt = { axis: "all" }
          break

        case HDR_SERVICES_TYPE.fft:
          servicesProcessed.fft = { axis: "all" }
          break

        case HDR_SERVICES_TYPE.accRaw:
          servicesProcessed.accRaw = { axis: "all" }
          break

        case HDR_SERVICES_TYPE._4t20:
          servicesProcessed._4t20 = { channel: "ab" }
          break

        case HDR_SERVICES_TYPE.ntc:
          servicesProcessed.ntc = { channel: "ab" }
          break

        case HDR_SERVICES_TYPE.pot:
          servicesProcessed.pot = { channel: "ab" }
          break
      }
    }

    return servicesProcessed
  }

  const getProcessedData = async (
    asset: AssetChartDetails,
    startDate: string,
    endDate: string
  ): Promise<ProcessedDataDTO[]> => {
    const processedData: ProcessedDataDTO[] = []

    const services = Object.keys(asset.services)

    for (let index = 0; index < services.length; index++) {
      const type = Number(services[index]) as HDR_SERVICES_TYPE

      const result = await getProcessedDataFromApi({
        assetIds: [asset.id],
        serviceType: type,
        startDate,
        endDate,
      })

      result.data.forEach((item, index) => {
        const time = Array.isArray(item.time) ? item.time[0] : item.time

        switch (type) {
          case HDR_SERVICES_TYPE.health:
            processedData.push({
              rssi: result.rssiList[index],
              service: "health",
              time,
              temp: (item as Health).temp,
              maxTemp: (item as Health).maxTemp,
              voltage: (item as Health).voltage,
              lastReset: (item as Health).lastReset,
            })
            break
          case HDR_SERVICES_TYPE.temp:
            processedData.push({
              rssi: result.rssiList[index],
              service: "temp",
              time,
              temp: (item as Temperature).temp,
            })
            break
          case HDR_SERVICES_TYPE.rms2:
            ;(item as RMS2).rms2.forEach((value) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "rms2",
                time,
                axis: (item as RMS2).axis,
                rms2: value,
              })
            })
            break
          case HDR_SERVICES_TYPE.rmms:
            ;(item as RMMS).rmms.forEach((value) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "rmms",
                time,
                axis: (item as RMMS).axis,
                rmms: value,
              })
            })
            break
          case HDR_SERVICES_TYPE.tilt:
            ;(item as Tilt).pitch.forEach((value, i) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "tilt",
                time,
                axis: (item as RMMS).axis,
                pitch: value,
                roll: (item as Tilt).roll[i],
              })
            })
            break
          case HDR_SERVICES_TYPE.fft:
            processedData.push({
              rssi: result.rssiList[index],
              service: "fft",
              time,
              axis: (item as FFT).axis,
              fft: (item as FFT).fft,
              fftVeloc: (item as FFT).fftVeloc,
              freq: (item as FFT).freqArray,
            })
            break

          case HDR_SERVICES_TYPE.accRaw:
            processedData.push({
              rssi: result.rssiList[index],
              service: "accRaw",
              time,
              axis: (item as AccRaw).axis,
              accRaw: (item as AccRaw).accRaw,
              xPlot: (item as AccRaw).xPlot,
            })
            break

          case HDR_SERVICES_TYPE._4t20:
            ;(item as _4T20).current.forEach((value, i) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "_4t20",
                time,
                channel: (item as _4T20).channel,
                current: value,
              })
            })
            break

          case HDR_SERVICES_TYPE.ntc:
            ;(item as NTC).temp.forEach((value, i) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "ntc",
                time,
                channel: (item as NTC).channel,
                temp: value,
              })
            })
            break

          case HDR_SERVICES_TYPE.pot:
            ;(item as POT).percent.forEach((value, i) => {
              processedData.push({
                rssi: result.rssiList[index],
                service: "pot",
                time,
                channel: (item as POT).channel,
                percent: value,
              })
            })
            break
        }
      })
    }

    return processedData
  }

  const handleDownloadAssetsReport = async (
    selectedAssets: AssetChartDetails[],
    startDate: string,
    endDate: string
  ) => {
    const csvs: CsvDataDTO[] = []

    for (let index = 0; index < selectedAssets.length; index++) {
      const asset = selectedAssets[index]

      const servicesProcessed = getServicesProcessedsDTO(asset)
      const processedData: ProcessedDataDTO[] = await getProcessedData(
        asset,
        startDate,
        endDate
      )

      if (processedData.length === 0) continue

      const csvFile = await writeCSVFile(
        "asset",
        asset.path,
        servicesProcessed,
        processedData
      )

      if (!csvFile) {
        console.error("Error generating CSV file")
        continue
      }

      csvs.push({
        data: csvFile.csvDataComplete,
        filename: csvFile.filename,
      })
    }

    if (csvs.length === 0) return null

    downloadCSV(csvs)
  }

  return {
    handleDownloadAssetsReport,
  }
}
