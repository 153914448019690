import { useState } from "react"

export const useDashDropdownController = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return {
    toggleDropdown,
    isOpen,
  }
}
