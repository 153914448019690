import React from "react"
import {
  DestructiveButton,
  InputButton,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from "./styles"

export type VariantType =
  | "primary"
  | "secondary"
  | "link"
  | "destructive"
  | "input"

export type ButtonOwnProps = {
  variant?: VariantType
  children?: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
}

export type ButtonProps = ButtonOwnProps &
  Omit<React.ComponentProps<"button">, keyof ButtonOwnProps>

const getStyleVariant = ({
  variant,
}: {
  variant: VariantType
}): React.ElementType => {
  switch (variant) {
    case "primary":
      return PrimaryButton
    case "secondary":
      return SecondaryButton
    case "link":
      return LinkButton
    case "destructive":
      return DestructiveButton
    case "input":
      return InputButton
  }
}

export const Button = ({
  children,
  variant = "primary",
  leftIcon,
  rightIcon,
  ...props
}: ButtonProps): JSX.Element => {
  const Variant = getStyleVariant({ variant })

  return (
    <Variant {...props} $color={props.color}>
      {leftIcon}
      {children}
      {rightIcon}
    </Variant>
  )
}
