import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  CreateAssetData,
  DeleteAssetAttachmentRequest,
  DeleteAssetResponse,
  LinkSensorData,
  UnlinkSensorData,
  UploadAssetImageRequest,
  UploadAssetImageResponse,
  UploadAssetAttachmentRequest,
  UpdateAssetRequest,
  AssetAttachmentsResponse,
} from "./entities"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import { AssetDetailsData } from "../../../utils/entities/assets"
import { assetsApi } from "./slice"

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

type MutationType<Request, Response> = MutationLifecycleApi<
  Request,
  BaseQueryFnType,
  Response,
  "assetsApi"
>

export const onUpdateQueryStarted = async (
  request: UpdateAssetRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<UpdateAssetRequest, AssetDetailsData>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssets",
        { services: false },
        (draft) => {
          draft[response.id].data = {
            id: response.id,
            name: response.name,
            typeId: response.assetType.id,
          }
        }
      )
    )

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        response.id,
        (draft) => {
          draft.name = response.name
          draft.assetType = response.assetType
          draft.description = response.description
          draft.properties = response.properties ? response.properties : {}
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onUploadAssetImageQueryStarted = async (
  request: UploadAssetImageRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<UploadAssetImageRequest, UploadAssetImageResponse>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        request.assetId,
        (draft) => {
          draft.assetPicture = response.url
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onDeleteAssetImageQueryStarted = async (
  request: number,
  { dispatch, queryFulfilled }: MutationType<number, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData("fetchAssetDetails", request, (draft) => {
        draft.assetPicture = null
      })
    )
  } catch (error) {
    console.log(error)
  }
}

export const onCreateQueryStarted = async (
  { parentId }: CreateAssetData,
  { dispatch, queryFulfilled }: MutationType<CreateAssetData, AssetDetailsData>
) => {
  try {
    const { id, name, assetType } = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssets",
        { services: false },
        (draft) => {
          const children = draft[parentId].children ?? []

          draft[parentId].children = [...children, id]
          draft[parentId].isFolder = true

          draft[id] = {
            data: {
              id,
              name,
              typeId: assetType.id,
            },
            index: id,
            children: [],
            isFolder: false,
          }
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onDeleteQueryStarted = async (
  id: number,
  { dispatch, queryFulfilled }: MutationType<number, DeleteAssetResponse>
) => {
  await queryFulfilled

  dispatch(
    assetsApi.util.updateQueryData(
      "fetchAssets",
      { services: false },
      (draft) => {
        const keys = Object.keys(draft)

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]

          if (!draft[key].children) continue
          if (!draft[key].children!.includes(id)) continue

          draft[key].isFolder = draft[key].children!.length > 1

          draft[key].children = draft[key].children!.filter(
            (child) => child !== id
          )
        }

        delete draft[id]
      }
    )
  )
}

export const onUnlinkSensorQueryStarted = async (
  request: UnlinkSensorData,
  { dispatch, queryFulfilled }: MutationType<LinkSensorData, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        request.id,
        (draft) => (draft.linkedSensor = undefined)
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onUploadAssetAttachmentQueryStarted = async (
  request: UploadAssetAttachmentRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<UploadAssetAttachmentRequest, AssetAttachmentsResponse>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetAttachments",
        request.assetId,
        (draft) => {
          draft.push(response)
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}

export const onDeleteAssetAttachmentQueryStarted = async (
  request: DeleteAssetAttachmentRequest,
  { dispatch, queryFulfilled }: MutationType<DeleteAssetAttachmentRequest, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetAttachments",
        request.assetId,
        (draft) => {
          const index = draft.findIndex(
            (item) => item.id === request.attachmentId
          )
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }
      )
    )
  } catch (error) {
    console.log(error)
  }
}
