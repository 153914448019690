import { createAsyncThunk } from "@reduxjs/toolkit"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { fetchApiData, processApiDataArray } from "./data.utilities"
import { ServiceData } from "./data.interfaces"

export const fetchData = createAsyncThunk(
  "data/temps",
  async ({
    serviceType,
    period,
    mac,
  }: {
    serviceType: HDR_SERVICES_TYPE
    period: number
    mac: string
  }): Promise<ServiceData[]> => {
    const apiDataResponse = await fetchApiData({
      serviceType,
      period,
      mac,
    })

    if (apiDataResponse.isLeft()) throw Error(apiDataResponse.value.message)

    const processedDataResponse = processApiDataArray({
      data: apiDataResponse.value,
      type: serviceType,
    })

    if (processedDataResponse.isLeft())
      throw Error(processedDataResponse.value.message)

    return processedDataResponse.value.data
  }
)
