import { ModalHedro } from "../../../../../../components/ModalHedro"
import { useFetchAssetChildrenQuery } from "../../../../../../store/store"
import { Text } from "../../../../../../components/ui/Text"
import { Asset, Body } from "./styles"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { AssetData } from "../../../../../../utils/entities/assets"
import { useDeleteAsset } from "../../controllers/useDeleteAsset"
import { AssetIcon } from "../../../../../../components/ui/AssetIcon"

interface ModalDeleteAssetProps {
  id: number
  handleOnClose: () => void
}

export const ModalDeleteAsset = ({
  id,
  handleOnClose,
}: ModalDeleteAssetProps) => {
  const { assets, buttons } = useAppTranslate()

  const { data, isLoading, isError } = useFetchAssetChildrenQuery(id)

  const { handleDelete, isDisable } = useDeleteAsset()

  const handleAssetDelete = async () => {
    await handleDelete(id)
    handleOnClose()
  }

  return (
    <ModalHedro
      onClose={handleOnClose}
      onLeftButton={handleOnClose}
      leftButtonLabel={buttons.cancel}
      onAction={handleAssetDelete}
      actionLabel={assets.form.deleteAsset}
      title={assets.form.deleteModalTitle}
      isActionsDisabled={isLoading || isError || isDisable}
    >
      <Content data={data} isLoading={isLoading} isError={isError} />
    </ModalHedro>
  )
}

const Content = ({
  isError,
  isLoading,
  data,
}: {
  isLoading: boolean
  isError: boolean
  data: AssetData[] | undefined
}) => {
  const { assets } = useAppTranslate()

  if (isLoading) return <Text>{assets.details.loading}</Text>

  if (isError || !data) return <Text>{assets.details.error}</Text>

  return (
    <Body>
      <Text color='gray.600'>
        {data.length === 0
          ? assets.form.deleteMessage
          : assets.form.deleteMessageWithChildren}
      </Text>

      {data.map((item) => (
        <Asset key={item.id}>
          <AssetIcon typeId={item.typeId} />
          <Text color='gray.800'>{item.name}</Text>
        </Asset>
      ))}
    </Body>
  )
}
