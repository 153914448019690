import { NewHeader } from "../../../components/NewHeader"
import { PageContent, PageWrapper } from "../../../components/ui/utils"
import { HeaderActions } from "./components/HeaderActions"
import { HeaderContainer } from "./styles"
import { SensorTable } from "./components/SensorsTable"
import {
  ReportsContext,
  ReportsContextDTO,
  ReportsContextProvider,
} from "./context/ReportsContext"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { useContext } from "react"
import { ConfigureAssets } from "../../../components/ConfigureAssets"
import { ServiceConfigured } from "../../../utils/entities/assets"

export const Reports = () => {
  return (
    <ReportsContextProvider>
      <ReportsPageContent />
    </ReportsContextProvider>
  )
}

export const ReportsPageContent = () => {
  const { lateralMenu } = useAppTranslate()

  const { orientedBy, selectedAssets, handleChangeSelectedAssets } = useContext(
    ReportsContext
  ) as ReportsContextDTO

  const page = {
    title: lateralMenu.routes.reports.title,
    subTitle: lateralMenu.routes.reports.download,
  }

  const handleIsServiceDisabled = (service: ServiceConfigured): boolean => {
    return !service.isConfigured
  }

  return (
    <PageWrapper>
      <HeaderContainer>
        <NewHeader page={page} />
        <HeaderActions />
      </HeaderContainer>

      <PageContent>
        {orientedBy === "Sensor" && <SensorTable />}
        {orientedBy === "Asset" && (
          <ConfigureAssets
            selectedAssets={selectedAssets}
            handleChangeSelectedAssets={handleChangeSelectedAssets}
            handleIsServiceDisabled={handleIsServiceDisabled}
          />
        )}
      </PageContent>
    </PageWrapper>
  )
}
