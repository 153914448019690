import { createContext, useCallback, useMemo, useState } from "react"
import { useForm, UseFormRegister, useWatch } from "react-hook-form"
import { reportsSchema, ReportsSchema } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { sensorRightJson } from "../../../Reports/entities/sensorRightJson"
import { Sensor } from "../../../../store/api/sensors/entities"
import { useAppDispatch } from "../../../../store/hooks"
import { reportRequest } from "../../../../store/features/reports/reports.api"
import { format } from "date-fns"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useAssetsReport } from "../hooks/useAssetsReport"
import { differenceInDays } from "date-fns"
import { AssetChartDetails } from "../../../../store/api/analytics/entities"

export type OrientedBy = "Sensor" | "Asset"

export interface ReportsContextDTO {
  register: UseFormRegister<{
    startDate: string
    endDate: string
  }>
  handleChangeOrientedBy: (orientedBy: OrientedBy) => void
  handleChangeSelectedAssets: (selectedAssets: AssetChartDetails[]) => void
  handleSearch: (value: string) => void
  handleDownloadReport: () => void
  handleCheckbox: (sensor: Sensor, service: string, checked: boolean) => void
  handleChangeCheckbox: (value: boolean, data: Sensor[]) => void
  handleUpdateSelectedServices: (services: HDR_SERVICES_TYPE[]) => void
  getCheckboxState: (sensor: Sensor, service: string) => boolean
  orientedBy: OrientedBy
  selectedAssets: AssetChartDetails[]
  hasSensorsSelected: boolean
  getServiceCheckbox: (data: Sensor[]) => "true" | "false" | "indeterminate"
  isStartDateEmpty: boolean
  isEndDateEmpty: boolean
  showSuccessIcon: boolean
  isDownloadDisabled: boolean
  search: string
  selectedServices: HDR_SERVICES_TYPE[]
}

export const ReportsContext = createContext<ReportsContextDTO | null>(null)

export const ReportsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { reports } = useAppTranslate()

  const dispatch = useAppDispatch()

  const { control, register, handleSubmit } = useForm<ReportsSchema>({
    resolver: zodResolver(reportsSchema),
  })

  const [orientedBy, setOrientedBy] = useState<OrientedBy>("Sensor")

  const startDate = useWatch({ control, name: "startDate" })
  const endDate = useWatch({ control, name: "endDate" })

  const { handleDownloadAssetsReport } = useAssetsReport()

  const isStartDateEmpty = !startDate
  const isEndDateEmpty = !endDate

  const [search, setSearch] = useState("")

  const [sensors, setSensors] = useState<sensorRightJson>({})

  const [selectedAssets, setSelectedAssets] = useState<AssetChartDetails[]>([])

  const checkSelectAnyAssetsService = (
    selectedAssets: AssetChartDetails[]
  ): boolean => {
    for (let i = 0; i < selectedAssets.length; i++) {
      if (Object.keys(selectedAssets[i].services).length > 0) return true
    }

    return false
  }

  const handleChangeOrientedBy = (orientedBy: OrientedBy) => {
    if (orientedBy === "Sensor") {
      setSelectedAssets([])
    } else {
      setSensors({})
    }

    setOrientedBy(orientedBy)
  }

  const handleChangeSelectedAssets = (assets: AssetChartDetails[]) => {
    setSelectedAssets(assets)
  }

  const hasSensorsSelected = !!Object.keys(sensors).length

  const [showSuccessIcon, setShowSuccessIcon] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const isDownloadDisabled = useMemo(
    () =>
      isStartDateEmpty ||
      isEndDateEmpty ||
      (!Object.keys(sensors).length &&
        !checkSelectAnyAssetsService(selectedAssets)) ||
      isLoading,
    [isEndDateEmpty, isLoading, isStartDateEmpty, selectedAssets, sensors]
  )

  const [selectedServices, setSelectedServices] = useState<HDR_SERVICES_TYPE[]>(
    []
  )

  const handleUpdateSelectedServices = (services: HDR_SERVICES_TYPE[]) => {
    setSelectedServices([...services])
  }

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const getCheckboxState = (sensor: Sensor, service: string) => {
    if (!sensors[sensor.mac]) return false

    return sensors[sensor.mac].services.includes(service)
  }

  const handleChangeCheckbox = useCallback((value: boolean, data: Sensor[]) => {
    if (value) {
      data.forEach((sensor) => {
        sensor.services.forEach((service) => {
          if (!service.disabled) selectService(sensor, service.name)
        })
      })
    } else {
      setSensors({})
    }
  }, [])

  const getServiceCheckbox = useCallback(
    (data: Sensor[]): "true" | "false" | "indeterminate" => {
      if (Object.keys(sensors).length === 0) return "false"

      for (let i = 0; i < data.length; i++) {
        const sensor = data[i]
        for (let j = 0; j < sensor.services.length; j++) {
          const service = sensor.services[j]
          if (!service.disabled) {
            const sensorObject = sensors[sensor.mac]

            if (!sensorObject) return "indeterminate"

            const find = sensors[sensor.mac].services.find(
              (s) => s === service.name
            )

            if (!find) return "indeterminate"
          }
        }
      }

      return "true"
    },
    [sensors]
  )

  const handleCheckbox = (
    sensor: Sensor,
    service: string,
    checked: boolean
  ) => {
    if (checked) {
      selectService(sensor, service)
    } else {
      unselectService(sensor, service)
    }
  }

  const selectService = (sensor: Sensor, service: string) => {
    setSensors((prev) => {
      if (!prev[sensor.mac]) {
        return {
          ...prev,
          [sensor.mac]: {
            id: sensor.id.toString(),
            name: sensor.name,
            serialNumber: sensor.serialNumber,
            services: [service],
          },
        }
      }

      return {
        ...prev,
        [sensor.mac]: {
          ...prev[sensor.mac],
          services: [...prev[sensor.mac].services, service],
        },
      }
    })
  }

  const unselectService = (sensor: Sensor, service: string) => {
    setSensors((prev) => {
      if (sensors[sensor.mac].services.length === 1) {
        delete prev[sensor.mac]
        return { ...prev }
      }

      return {
        ...prev,
        [sensor.mac]: {
          ...prev[sensor.mac],
          services: prev[sensor.mac].services.filter((s) => s !== service),
        },
      }
    })
  }

  const handleDownloadSensorsReport = async (
    startDate: string,
    endDate: string
  ) => {
    await dispatch(
      reportRequest({
        startDate: format(startDate, "yyy-MM-dd"),
        endDate: format(endDate, "yyy-MM-dd"),
        sensors,
      })
    ).unwrap()
  }

  const onSuccessDownload = (result: void | null) => {
    if (result === null) {
      toast.warning(reports.toast.warning)
      return
    }

    setShowSuccessIcon(true)

    setTimeout(() => {
      setShowSuccessIcon(false)
    }, 3000)
  }

  const handleDownloadReport = () => {
    handleSubmit(async ({ startDate, endDate }) => {
      setIsLoading(true)

      const diff = differenceInDays(new Date(endDate), new Date(startDate))

      if (diff > 31) {
        toast.warning(reports.toast.maxRange)
        return
      }

      if (orientedBy === "Sensor") {
        handleDownloadSensorsReport(startDate, endDate)
          .then((result) => onSuccessDownload(result))
          .catch(() => toast.error(reports.toast.error))
          .finally(() => setIsLoading(false))
      } else {
        handleDownloadAssetsReport(selectedAssets, startDate, endDate)
          .then((result) => onSuccessDownload(result))
          .catch(() => toast.error(reports.toast.error))
          .finally(() => setIsLoading(false))
      }
    })()
  }

  const defaultContext: ReportsContextDTO = {
    register,
    handleChangeOrientedBy,
    handleChangeSelectedAssets,
    handleSearch,
    handleDownloadReport,
    handleCheckbox,
    handleChangeCheckbox,
    handleUpdateSelectedServices,
    getCheckboxState,
    orientedBy,
    selectedAssets,
    hasSensorsSelected,
    getServiceCheckbox,
    isStartDateEmpty,
    isEndDateEmpty,
    showSuccessIcon,
    isDownloadDisabled,
    search,
    selectedServices,
  }

  return (
    <ReportsContext.Provider value={defaultContext}>
      {children}
    </ReportsContext.Provider>
  )
}
