import * as z from "zod"

export const enum SCHEMA_ERRORS {
  REQUIRED = "REQUIRED",
  MIN_NAME = "MIN_NAME",
  MAX_NAME = "MAX_NAME",
}

export const dashboardSchema = z.object({
  name: z
    .string()
    .min(3, SCHEMA_ERRORS.MIN_NAME)
    .max(20, SCHEMA_ERRORS.MAX_NAME),
  layout: z.number().min(1, SCHEMA_ERRORS.REQUIRED),
})

export type DashboardSchema = z.infer<typeof dashboardSchema>
