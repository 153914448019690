import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const CustomSelectContainer = styled.div`
  display: inline-block;
  margin: 0;
  top: 0;
  z-index: 10;
  flex: 1;

  @media ${media.small} {
    max-width: 300px;
  }
`

export const DropdownButton = styled.button`
  margin-bottom: 0px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  color: ${(props) => props.theme.palette.gray[800]};
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 4px;
  z-index: 999;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  height: 48px;

  border: 1px solid ${(props) => props.theme.palette.gray[300]};

  @media ${media.small} {
    min-width: 300px;
  }

  h1 {
    display: flex;
    font-family: ${(props) => props.theme.typography.display.xs.fontFamily};
    font-size: ${(props) => props.theme.typography.display.xs.fontSize};
    line-height: ${(props) => props.theme.typography.display.xs.lineHeight};
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[800]};
    margin: 0 auto;
    font-size: 2em;

    @media (max-width: 1200px) {
      font-size: 1.6em;
      margin-top: 4px;
    }

    text-transform: uppercase;
  }
`

export const DropdownContainer = styled.div`
  position: absolute;
  width: auto;
  color: ${(props) => props.theme.palette.gray[800]};
  min-width: 120px;
  width: 300px;
`

export const DropdownList = styled.ul<{ $isopen: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.theme.palette.gray[50]};
  font-weight: bold;
`

export const DropdownItem = styled.li`
  min-width: 120px;
  padding: 10px;
  font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.palette.gray[300]};
  color: ${(props) => props.theme.palette.gray[800]};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.gray[300]};
    transition: background-color 0.3s;
  }
`

export const TitleDashboard = styled.span`
  display: flex;
  font-family: ${(props) => props.theme.typography.text.lg.fontFamily};
  font-size: ${(props) => props.theme.typography.text.lg.fontSize};
  line-height: ${(props) => props.theme.typography.text.lg.lineHeight};
  font-weight: 600;
  color: ${(props) => props.theme.palette.gray[800]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DropdownItemBtn = styled.div`
  min-width: 120px;
  display: flex;
  flex-direction: row;
  text-align: left;
  flex-wrap: wrap;
  align-content: center;
`

export const AddButton = styled.button`
  height: 44px;
  width: 100%;
  padding: 12px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  color: ${(props) => props.theme.palette.success.main};
  border: 1px solid ${(props) => props.theme.palette.gray[300]};
  text-align: left;
  flex-wrap: wrap;
  align-content: center;
  font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  font-weight: 500;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row;
  &:hover {
    background-color: ${(props) => props.theme.palette.gray[300]};
    transition: background-color 0.3s;
  }
`

export const IconDropDown = styled.div`
  color: ${(props) => props.theme.palette.gray[800]};
  display: flex;
`
