import { memo } from "react"
import { Checkbox } from "../../../../../components/ui/Checkbox"
import { Sensor } from "../../../../../store/api/sensors/entities"

interface SensorsSelectionChecxboxProps {
  data: Sensor[] | undefined
  getServiceCheckbox: (data: Sensor[]) => "true" | "false" | "indeterminate"
  handleChangeCheckbox: (value: boolean, data: Sensor[]) => void
}

export const SensorsSelectionChexbox = memo(
  ({
    data,
    getServiceCheckbox,
    handleChangeCheckbox,
  }: SensorsSelectionChecxboxProps) => {
    const state = getServiceCheckbox(data ?? [])

    return (
      <Checkbox
        onChange={(e) => handleChangeCheckbox(e.target.checked, data ?? [])}
        checked={state === "true"}
        indeterminate={state === "indeterminate"}
      />
    )
  }
)

SensorsSelectionChexbox.displayName = "SensorsSelectionChexbox"
