import { SeriesOptionsType } from "highcharts"
import theme from "../../../styles/theme"
import { lineChartTooltipFormater } from "./tooltip"

interface linechartOptionsProps {
  title: string
  noDataMessage: string
  xAxisType: Highcharts.AxisTypeValue
  xAxisTitle: string
  yAxisTitle: string
  series: SeriesOptionsType[]
}

export const linechartOptions = ({
  title,
  noDataMessage,
  xAxisType,
  xAxisTitle,
  yAxisTitle,
  series,
}: linechartOptionsProps): Highcharts.Options => {
  return {
    title: {
      text: "",
    },

    series,

    lang: {
      noData: noDataMessage,
    },

    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "16px",
        color: theme.colors.black,
      },
    },

    credits: {
      enabled: false,
    },

    chart: {
      backgroundColor: theme.palette.gray[50],
      spacingTop: 16,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      panning: {
        enabled: true,
        type: "x",
      },
      zooming: {
        singleTouch: true,
        type: "x",
      },
      panKey: "shift",
      events: {
        fullscreenOpen: function (this: Highcharts.Chart) {
          this.setTitle({
            text: title,
            style: {
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.text.lg.fontSize,
            },
          })
        },
        fullscreenClose: function (this: Highcharts.Chart) {
          this.setTitle({
            text: undefined,
          })
        },
      },
    },

    time: {
      useUTC: false,
    },

    accessibility: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: xAxisTitle,
        style: {
          fontSize: "12px",
        },
      },

      type: xAxisType,
    },

    yAxis: {
      labels: {
        formatter: (e) => Number(e.value).toFixed(2),
      },
      title: {
        text: yAxisTitle,
        style: {
          fontSize: "12px",
        },
      },
    },

    boost: {
      enabled: true,
      useGPUTranslations: false,
      seriesThreshold: 1,
    },

    plotOptions: {
      series: {
        boostThreshold: 2000,
        marker: {
          enabled: false,
        },
      },
    },

    legend: {
      enabled: true,
      itemStyle: {
        lineHeight: "16px",
        fontSize: "12px",
        fontWeight: "bold",
      },
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      formatter: lineChartTooltipFormater,
    },

    exporting: {
      enabled: false,
    },
  }
}
