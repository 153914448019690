import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 12px;

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
  border-radius: 4px;

  width: 100%;
`

export const IconBackground = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`
