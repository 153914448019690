import {
  AccRaw,
  FFT,
  HDR_SERVICES_TYPE,
  NTC,
  POT,
  RMMS,
  RMS2,
  Temperature,
  Tilt,
  _4T20,
} from "hdr-process-data"
import { Result, left, right } from "../either/result"
import { Axis } from "../entities/Axis"
import { ServiceData } from "../../store/features/data/data.interfaces"
import { ExtraConfig } from "../../pages/Dashboard/entities/Analytics"
import { FftType } from "../entities/FftType"

export const transformDataArrayInPoints = ({
  data,
  type,
  axis,
  configs,
  fftType,
  mac,
}: {
  data: ServiceData[]
  type: HDR_SERVICES_TYPE
  axis?: Axis
  configs?: ExtraConfig
  fftType?: FftType
  mac?: string
}): Result<Error, number[][]> => {
  let graphicData: number[][] = []

  for (let index = 0; index < data.length; index++) {
    if (Object.keys(data[index]).includes("axis") && axis === undefined) {
      return left(Error("Invalid axis!"))
    }

    const points = transformDataInPoints({
      data: data[index],
      serviceType: type,
      axis,
      configs,
      fftType,
      mac,
    })

    if (points.isLeft()) return left(points.value)

    graphicData = graphicData.concat(points.value)
  }

  return right(graphicData)
}

/**
 * Format the data in a point [x1, y1]
 */
export const transformDataInPoints = ({
  data,
  serviceType,
  axis,
  configs,
  fftType,
  mac,
}: {
  data: ServiceData
  serviceType: HDR_SERVICES_TYPE
  axis?: Axis
  configs?: ExtraConfig
  fftType?: FftType
  mac?: string
}): Result<Error, number[][]> => {
  const points: number[][] = []

  switch (serviceType) {
    case HDR_SERVICES_TYPE.temp: {
      const tempData = data as Temperature

      for (let index = 0; index < tempData.temp.length; index++) {
        const point = getTempPoint({
          data: tempData.temp[index],
          time: tempData.time[index],
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.rms2: {
      if (!axis) break

      const rms2Data = data as RMS2

      for (let index = 0; index < rms2Data.rms2.length; index++) {
        const point = getPointWithAxis({
          axis: axis,
          data: rms2Data.rms2[index],
          dataAxis: rms2Data.axis,
          x: new Date(rms2Data.time[index]).getTime(),
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.rmms: {
      if (!axis) break

      const rmmsData = data as RMMS

      for (let index = 0; index < rmmsData.rmms.length; index++) {
        const point = getPointWithAxis({
          axis: axis,
          data: rmmsData.rmms[index],
          dataAxis: rmmsData.axis,
          x: new Date(rmmsData.time[index]).getTime(),
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.tilt: {
      if (!axis) break

      const tiltData = data as Tilt

      for (let index = 0; index < tiltData.nSample; index++) {
        const point = getTiltPoint({
          pitch: tiltData.pitch[index],
          roll: tiltData.roll[index],
          time: tiltData.time[index],
          axis,
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.fft: {
      if (!axis) break
      if (!fftType) break

      const fftData = data as FFT

      const length = fftData.nsCalc / 2 + 1

      // Skip first point
      for (let index = 1; index < length; index++) {
        let acceleration: number[] = []
        let velocity: number[] = []

        if (fftData.axis === "all" && fftData.fft.length === 3) {
          acceleration = [
            fftData.fft[0][index],
            fftData.fft[1][index],
            fftData.fft[2][index],
          ]

          velocity = [
            fftData.fftVeloc[0][index],
            fftData.fftVeloc[1][index],
            fftData.fftVeloc[2][index],
          ]
        } else if (fftData.fft.length === 1) {
          acceleration = [fftData.fft[0][index]]
          velocity = [fftData.fftVeloc[0][index]]
        }

        const point = getPointWithAxis({
          data: fftType === "Acceleration" ? acceleration : velocity,
          dataAxis: fftData.axis,
          axis: axis,
          x: fftData.freqArray[index],
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.accRaw: {
      if (!axis) break

      const accRawData = data as AccRaw

      for (let index = 0; index < accRawData.nsCalc; index++) {
        let pointData: number[] = []

        if (accRawData.axis === "all" && accRawData.accRaw.length === 3) {
          pointData = [
            accRawData.accRaw[0][index],
            accRawData.accRaw[1][index],
            accRawData.accRaw[2][index],
          ]
        } else if (accRawData.accRaw.length === 1) {
          pointData = [accRawData.accRaw[0][index]]
        }

        const point = getPointWithAxis({
          data: pointData,
          dataAxis: accRawData.axis,
          axis: axis,
          x: accRawData.xPlot[index],
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.ntc: {
      if (!axis) break

      const ntcData = data as NTC

      for (let index = 0; index < ntcData.temp.length; index++) {
        const point = getPointWithChannel({
          data: ntcData.temp[index],
          dataChannel: ntcData.channel,
          channel: axis,
          x: new Date(ntcData.time[index]).getTime(),
        })

        if (point.isLeft()) {
          points.push([new Date(ntcData.time[index]).getTime(), NaN])
          continue
        }

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE.pot: {
      if (!axis) break
      const potData = data as POT

      for (let index = 0; index < potData.nSample; index++) {
        const point = getPercentPoint({
          data: potData.percent[index],
          dataChannel: potData.channel,
          channel: axis,
          x: new Date(potData.time[index]).getTime(),
          serviceName: "pot",
          mac: mac as string,
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }

    case HDR_SERVICES_TYPE._4t20: {
      if (!axis || !configs) break

      const _4t20Data = data as _4T20

      for (let index = 0; index < _4t20Data.nSample; index++) {
        const point = getPercentPoint({
          data: _4t20Data.current[index],
          dataChannel: _4t20Data.channel,
          channel: axis,
          x: new Date(_4t20Data.time[index]).getTime(),
          serviceName: "_4t20",
          mac: mac as string,
        })

        if (point.isLeft()) return left(point.value)

        if (point.value.length > 0) points.push(point.value)
      }

      break
    }
  }

  return right(points)
}

const getTempPoint = ({
  data,
  time,
}: {
  data: number
  time: string
}): Result<Error, number[]> => {
  const x = new Date(time).getTime()
  const y = data

  if (isNaN(x)) {
    return left(Error("Invalid date!"))
  }

  return right([x, y])
}

const getPointWithAxis = ({
  axis,
  data,
  dataAxis,
  x,
}: {
  axis: string
  dataAxis: string
  data: number[]
  x: number
}): Result<Error, number[]> => {
  let y: number = NaN

  if (data.length === 0) return right([])

  if (axis !== dataAxis && dataAxis !== "all") return right([])

  if (dataAxis !== "all") {
    if (isNaN(x)) {
      return left(Error("Invalid date!"))
    }

    return right([x, data[0]])
  }

  switch (axis) {
    case "x": {
      y = data[0]
      break
    }
    case "y": {
      y = data[1]
      break
    }
    case "z": {
      y = data[2]
      break
    }
    case "module": {
      const rms2x = data[0]
      const rms2y = data[1]
      const rms2z = data[2]

      const sum = Math.pow(rms2x, 2) + Math.pow(rms2y, 2) + Math.pow(rms2z, 2)
      const module = Math.sqrt(sum)

      y = module
      break
    }
  }

  if (isNaN(y)) return right([x, NaN])

  if (isNaN(x)) {
    return left(Error("Invalid data!"))
  }

  return right([x, y])
}

const getPointWithChannel = ({
  data,
  dataChannel,
  x,
  channel,
}: {
  data: number[]
  dataChannel: string
  x: number
  channel: string
}): Result<Error, number[]> => {
  if (data.length === 0) return right([])

  if (channel !== dataChannel && dataChannel !== "ab") return right([])

  if (dataChannel !== "ab") {
    if (isNaN(x) || isNaN(data[0])) {
      return left(Error("Invalid data!"))
    }

    return right([x, data[0]])
  }

  let y: number = NaN

  switch (channel) {
    case "a":
      y = data[0]
      break

    case "b":
      y = data[1]
      break
  }

  if (isNaN(x) || isNaN(y)) {
    return left(Error("Invalid data!"))
  }

  return right([x, y])
}

function convertStringToNumber(value: string | null): number | number[] {
  if (!value) return 0
  const separatedValues = value.split(",")

  if (separatedValues.length === 1) {
    return Math.abs(parseFloat(separatedValues[0]))
  }
  return separatedValues.map((valueString) => Math.abs(parseFloat(valueString)))
}

const getExtraConfigFromLocalStorage = (mac: string, serviceName: string) => {
  const extraConfigFromLocalStorage = localStorage.getItem(
    `${mac} - ${serviceName} - ExtraConfig`
  )
  const objExtraConfig = JSON.parse(extraConfigFromLocalStorage as string)

  const superiorLimit = objExtraConfig?.upperLimit
    ? objExtraConfig?.upperLimit
    : 100
  const inferiorLimit = objExtraConfig?.inferiorLimit
    ? objExtraConfig?.inferiorLimit
    : 0

  return {
    superiorLimit,
    inferiorLimit,
  }
}

const getReferenceFromLocalStorage = (mac: string, serviceName: string) => {
  const referencePointFromLocalStorage = localStorage.getItem(
    `${mac} - ${serviceName} - Reference`
  )
  const referencePoint = convertStringToNumber(referencePointFromLocalStorage)
  return referencePoint
}

const getPercentPoint = ({
  data,
  dataChannel,
  x,
  channel,
  mac,
  serviceName,
}: {
  data: number[]
  dataChannel: string
  x: number
  channel: string
  mac: string
  serviceName: "pot" | "_4t20"
}): Result<Error, number[]> => {
  if (data.length === 0) return right([])

  if (channel !== dataChannel && dataChannel !== "ab") return right([])

  const { superiorLimit, inferiorLimit } = getExtraConfigFromLocalStorage(
    mac,
    serviceName
  )
  const referencePoint = getReferenceFromLocalStorage(mac, serviceName)

  if (dataChannel !== "ab") {
    const percent = data[0] - (referencePoint as number)
    const deltaPercent = (superiorLimit - inferiorLimit) / 100

    const y = inferiorLimit + percent * deltaPercent

    if (isNaN(x) || isNaN(y)) {
      return left(Error("Invalid data!"))
    }

    return right([x, y])
  }
  let percent: number = NaN
  let reference: number = NaN
  switch (channel) {
    case "a":
      reference = (referencePoint as number[])[0]
        ? (referencePoint as number[])[0]
        : 0
      percent = data[0] - reference
      break

    case "b":
      reference = (referencePoint as number[])[1]
        ? (referencePoint as number[])[1]
        : 0
      percent = data[1] - reference

      break
  }

  if (isNaN(x) || isNaN(percent)) {
    return left(Error("Invalid data!"))
  }

  const deltaPercent = (superiorLimit - inferiorLimit) / 100

  const y = inferiorLimit + percent * deltaPercent

  if (isNaN(y)) {
    return left(Error("Invalid data!"))
  }

  return right([x, y])
}

const getTiltPoint = ({
  pitch,
  roll,
  time,
  axis,
}: {
  pitch: number
  roll: number
  time: string
  axis: string
}): Result<Error, number[]> => {
  const x = new Date(time).getTime()

  if (isNaN(x)) {
    return left(Error("Invalid date!"))
  }

  if (axis === "pitch") {
    return right([x, pitch])
  }

  if (axis === "roll") {
    return right([x, roll])
  }

  return left(Error("Invalid axis to tilt data"))
}
