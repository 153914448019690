import { useEffect, useState } from "react"
import { ToggleFFT } from "../../../../../../components/ToggleFFT"
import { Text } from "../../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { Footer, TextWrapper } from "./styles"
import { FftType } from "../../../../../../utils/entities/FftType"

interface FooterFFTProps {
  chartId: string
  onToggleFFT: () => void
}

export const FooterFFT = ({ chartId, onToggleFFT }: FooterFFTProps) => {
  const { chart } = useAppTranslate()

  const [fftType, setFftType] = useState<FftType>(FftType.Acceleration)

  const handleToggleFFT = () => {
    if (fftType === FftType.Velocity) {
      localStorage.setItem(`FFT-TYPE-${chartId}`, FftType.Acceleration)
      setFftType(FftType.Acceleration)
    } else {
      localStorage.setItem(`FFT-TYPE-${chartId}`, FftType.Velocity)
      setFftType(FftType.Velocity)
    }

    onToggleFFT()
  }

  useEffect(() => {
    localStorage.setItem(`FFT-TYPE-${chartId}`, FftType.Acceleration)
  }, [chartId])

  return (
    <Footer>
      <TextWrapper $align='end'>
        <Text
          fontWeight={fftType === FftType.Acceleration ? "bold" : "regular"}
        >
          {chart.fftAcceleration}
        </Text>
      </TextWrapper>
      <ToggleFFT
        onChange={handleToggleFFT}
        checked={fftType === FftType.Velocity}
      />
      <TextWrapper $align='start'>
        <Text fontWeight={fftType === FftType.Velocity ? "bold" : "regular"}>
          {chart.fftVelocity}
        </Text>
      </TextWrapper>
    </Footer>
  )
}
