import React from "react"
import {
  PrimaryIconButton,
  SecondaryIconButton,
  DestructiveIconButton,
} from "./styles"
type VariantType = "primary" | "secondary" | "destructive"

type IconButtonOwnProps = {
  icon: React.ReactNode
  variant?: VariantType
}

type IconButtonProps = IconButtonOwnProps &
  Omit<React.ComponentProps<"button">, keyof IconButtonOwnProps>

const getStyleVariant = ({ variant }: { variant: VariantType }) => {
  switch (variant) {
    case "primary":
      return PrimaryIconButton
    case "secondary":
      return SecondaryIconButton
    case "destructive":
      return DestructiveIconButton
  }
}

export const IconButton = ({
  icon,
  variant = "primary",
  ...props
}: IconButtonProps): React.ReactNode => {
  const Variant = getStyleVariant({ variant })

  return (
    <Variant
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      $color={props.color}
    >
      {icon}
    </Variant>
  )
}
