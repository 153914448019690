import {
  HighchartsReact,
  HighchartsReactRefObject,
} from "highcharts-react-official"
import Boost from "highcharts/modules/boost"
import Highcharts from "highcharts"
import { linechartOptions } from "./options/linechart"
import {
  useCallback,
  memo,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react"
import { FlexContainer } from "../ui/FlexContainer"
import { Text } from "../ui/Text"
import { useAppTranslate } from "../../translate/useAppTranslate"
import { Button } from "../ui/Button"
import { ChartData } from "../Chart/entities/ChartData"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)
require("highcharts/modules/full-screen")(Highcharts)

Boost(Highcharts)

export interface UseChartSeries {
  series: Highcharts.SeriesOptionsType[]
  isLoading: boolean
  isError: boolean
  createWebSocketConnection?: (
    chart: ChartData,
    chartRef: Highcharts.Chart
  ) => void
  closeWebSocketConnection?: (chart: ChartData) => void
  refetch: () => void
}

interface NewChartProps {
  chart: ChartData
  xAxisType?: Highcharts.AxisTypeValue
  xAxisTitle: string
  yAxisTitle: string
  useChartSeries: (chart: ChartData) => UseChartSeries
}

export type ChartRef = {
  openFullscreen: () => void
  printChart: () => void
  downloadCSV: () => void
  downloadXLS: () => void
  refetch: () => void
}

const ChartComponent = forwardRef<ChartRef, NewChartProps>(
  (
    { chart, xAxisType = "linear", xAxisTitle, yAxisTitle, useChartSeries },
    ref
  ) => {
    const {
      series,
      isLoading,
      isError,
      createWebSocketConnection,
      closeWebSocketConnection,
      refetch,
    } = useChartSeries(chart)

    const translate = useAppTranslate()

    const chartLocalRef = useRef<HighchartsReactRefObject | null>(null)

    const chartRef = useCallback(
      (node: HighchartsReactRefObject | null) => {
        if (!node) return

        chartLocalRef.current = node

        if (createWebSocketConnection && node.chart.series.length > 0) {
          createWebSocketConnection(chart, node.chart)
        }
      },
      [chart, createWebSocketConnection]
    )

    useEffect(() => {
      return () => {
        if (closeWebSocketConnection) closeWebSocketConnection(chart)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
      openFullscreen: () => {
        if (!chartLocalRef.current) return

        chartLocalRef.current.chart.fullscreen.open()
      },
      printChart: () => {
        if (!chartLocalRef.current) return

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart = chartLocalRef.current.chart as any

        chart.print()
      },
      downloadCSV: () => {
        if (!chartLocalRef.current) return

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart = chartLocalRef.current.chart as any

        chart.downloadCSV()
      },
      downloadXLS: () => {
        if (!chartLocalRef.current) return

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const chart = chartLocalRef.current.chart as any

        chart.downloadXLS()
      },
      refetch: () => {
        if (closeWebSocketConnection) closeWebSocketConnection(chart)
        refetch()
      },
    }))

    if (isLoading)
      return (
        <FlexContainer align='center' fullHeight fullWidth>
          <Text fontSize='lg' color='gray.800'>
            {translate.chart.loadingMessage}
          </Text>
        </FlexContainer>
      )

    if (isError)
      return (
        <FlexContainer align='center' fullHeight fullWidth>
          <Text fontSize='lg' color='gray.800'>
            {translate.chart.error}
          </Text>

          <Button onClick={refetch} variant='link'>
            {translate.buttons.reload}
          </Button>
        </FlexContainer>
      )

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={linechartOptions({
          title: chart.title,
          noDataMessage: translate.chart.noDataMessage,
          xAxisTitle,
          yAxisTitle,
          xAxisType,
          series,
        })}
        ref={chartRef}
      />
    )
  }
)

ChartComponent.displayName = "NewChart"

export const NewChart = memo(ChartComponent)
