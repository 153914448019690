import { ComponentProps, useEffect, useRef } from "react"
import { Container } from "./styles"

interface CheckboxProps extends Omit<ComponentProps<"input">, "type"> {
  type?: "checkbox" | "radio"
  indeterminate?: boolean
}

export const Checkbox = ({
  type = "checkbox",
  indeterminate = false,
  ...props
}: CheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!ref.current) return
    ref.current.indeterminate = indeterminate
  }, [ref, indeterminate])

  return (
    <Container>
      <input ref={ref} type={type} {...props} />
    </Container>
  )
}
