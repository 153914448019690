import { FormattedSensorsAndServices } from "../ModalConfigureSensors/components/SensorAndServices/entities/FormattedSensorsAndServices"
import { SensorAndService } from "../ModalConfigureSensors/components/SensorAndServices/entities/SensorAndService"

/**
 * Function that formats services and sensors to send to the request
 * @param {SensorAndService[]} sensorAndServices - Selected sensors and services
 * @returns {FormattedSensorsAndServices} - Returns the formatted sensors and services
 */
export const createsObject = (
  sensorAndServices: SensorAndService[]
): FormattedSensorsAndServices => {
  let temporarySensors: FormattedSensorsAndServices = {}

  sensorAndServices.forEach((item) => {
    const sensor = item.configs
      ? { mac: item.mac, name: item.name, configs: item.configs }
      : { mac: item.mac, name: item.name }

    let service =
      item.axis && item.axis === "module"
        ? item.name + "VectorModule"
        : item.service

    if (
      temporarySensors[service] !== undefined &&
      temporarySensors[service] !== null
    ) {
      temporarySensors[service].push(sensor)
    } else {
      temporarySensors[service] = [sensor]
    }
  })
  return temporarySensors
}
