import { MFooter } from "../../styles"
import { Button, VariantType } from "../../../ui/Button"
import { FlexContainer } from "../../../ui/FlexContainer"
import { useMemo } from "react"
import { ButtonTimeout } from "../../../ui/ButtonTimeout"

interface IModalFooter {
  isActionsDisabled?: boolean
  actionLabel: string
  actionVariant?: VariantType
  leftButtonLabel?: string
  leftButtonVariant?: VariantType
  seconds?: number
  onAction?: () => void
  onLeftButton?: () => void
}

export const ModalFooter = ({
  isActionsDisabled,
  actionLabel,
  actionVariant = "primary",
  leftButtonLabel,
  leftButtonVariant = "secondary",
  seconds,
  onAction,
  onLeftButton,
}: IModalFooter) => {
  const justify = useMemo(() => {
    if (onAction && onLeftButton) return "space-between"
    else return "center"
  }, [onAction, onLeftButton])

  if (!onAction && !onLeftButton) return null

  return (
    <MFooter>
      <FlexContainer direction='row' justify={justify}>
        {onLeftButton && (
          <Button onClick={onLeftButton} variant={leftButtonVariant}>
            {leftButtonLabel ?? ""}
          </Button>
        )}

        {onAction && (
          <>
            {seconds ? (
              <ButtonTimeout
                seconds={seconds}
                onClick={onAction}
                disabled={isActionsDisabled}
                variant={actionVariant}
              >
                {actionLabel}
              </ButtonTimeout>
            ) : (
              <Button
                onClick={onAction}
                disabled={isActionsDisabled}
                variant={actionVariant}
              >
                {actionLabel}
              </Button>
            )}
          </>
        )}
      </FlexContainer>
    </MFooter>
  )
}
