import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { thorBaseQuery } from "../utils"
import { DashboardRequest, UpdateDashboardRequest } from "./entities"
import { onConfigureQueryStarted, onDeleteQueryStarted } from "./updates"
import { Dashboard } from "../../../pages/Dashboard/entities/Dashboard"

export const dashboardsApi = createApi({
  reducerPath: "dashboardsApi",
  baseQuery: thorBaseQuery(),
  endpoints: (build) => ({
    fetchDashboards: build.query<Dashboard[], void>({
      query: () => "/v1/proteu/dashboard",
    }),
    createDashboard: build.mutation<Dashboard, DashboardRequest>({
      query: (body) => ({
        url: "/v1/proteu/dashboard",
        method: "POST",
        body,
      }),
      onQueryStarted: onConfigureQueryStarted,
    }),
    updateDashboard: build.mutation<Dashboard, UpdateDashboardRequest>({
      query: ({ dashboardId, body }) => ({
        url: `/v1/proteu/dashboard/${dashboardId}`,
        method: "PUT",
        body,
      }),
      onQueryStarted: onConfigureQueryStarted,
    }),
    deleteDashboard: build.mutation<void, number>({
      query: (dashboardId) => ({
        url: `/v1/proteu/dashboard/${dashboardId}`,
        method: "DELETE",
      }),
      onQueryStarted: onDeleteQueryStarted,
    }),
  }),
})

export const {
  useFetchDashboardsQuery,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useDeleteDashboardMutation,
} = dashboardsApi
