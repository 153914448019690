import { AxiosRequestConfig } from "axios"
import {
  HDR_SERVICES_TYPE,
  get4T20,
  getAccRaw,
  getFFT,
  getNtc,
  getPot,
  getRMMS,
  getRMS2,
  getTemp,
  getTilt,
  getHealth,
  getTempMMM,
} from "hdr-process-data"
import { Result, left, right } from "../../../utils/either/result"
import { ServiceData } from "./data.interfaces"
import { apiCall } from "../../../utils/axios"
import { VersionProtocolApplication } from "hdr-process-data/lib/interfaces/version"

export interface MessageRaw {
  protocolVersion: string
  applicationVersion: string
  raw: string
  time: number
  serviceType: HDR_SERVICES_TYPE
  rssi: number
}

const ServicesRoutes: {
  [key: number]: string | undefined
} = {
  [HDR_SERVICES_TYPE.temp]: process.env.REACT_APP_API_TEMP,
  [HDR_SERVICES_TYPE.rms2]: process.env.REACT_APP_API_RMS2,
  [HDR_SERVICES_TYPE.rmms]: process.env.REACT_APP_API_RMMS,
  [HDR_SERVICES_TYPE.tilt]: process.env.REACT_APP_API_TILT,
  [HDR_SERVICES_TYPE.fft]: process.env.REACT_APP_API_FFT,
  [HDR_SERVICES_TYPE.accRaw]: process.env.REACT_APP_API_ACC_RAW,
  [HDR_SERVICES_TYPE.health]: process.env.REACT_APP_API_HEALTH,
  [HDR_SERVICES_TYPE.tempMMM]: process.env.REACT_APP_API_TEMP_MMM,
  [HDR_SERVICES_TYPE._4t20]: process.env.REACT_APP_API_4T20,
  [HDR_SERVICES_TYPE.ntc]: process.env.REACT_APP_API_NTC,
  [HDR_SERVICES_TYPE.pot]: process.env.REACT_APP_API_POT,
}

export const fetchApiData = async ({
  serviceType,
  mac,
  period,
}: {
  serviceType: HDR_SERVICES_TYPE
  mac: string
  period: number
}): Promise<Result<Error, MessageRaw[]>> => {
  const axios = apiCall()

  if (!Object.keys(ServicesRoutes).includes(serviceType.toString())) {
    return left(Error(`Erro ao buscar dados tipo ${serviceType}!`))
  }

  if (ServicesRoutes[serviceType] === undefined) {
    return left(Error(`Erro ao buscar dados tipo ${serviceType}!`))
  }

  try {
    const result = await axios.get(
      ServicesRoutes[serviceType]!,
      getAxiosRequestConfig(serviceType, mac, period)
    )

    return right(result.data as MessageRaw[])
  } catch (e) {
    return left(Error(`Erro ao buscar dados tipo ${serviceType}!`))
  }
}

export const processApiDataArray = ({
  data,
  type,
}: {
  data: MessageRaw[]
  type: HDR_SERVICES_TYPE
}): Result<
  Error,
  {
    rssiList: number[]
    data: ServiceData[]
  }
> => {
  if (data.length === 0)
    return right({
      rssiList: [],
      data: [],
    })

  if (type === HDR_SERVICES_TYPE.fft) {
    const result = processData({
      protocolVersion: data[0].protocolVersion,
      applicationVersion: data[0].applicationVersion,
      raw: data[0].raw,
      time: data[0].time,
      serviceType: type,
    })

    if (result.isLeft()) {
      return left(result.value)
    }

    return right({
      rssiList: [data[0].rssi],
      data: [result.value],
    })
  }

  if (type === HDR_SERVICES_TYPE.accRaw) {
    const result = processData({
      protocolVersion: data[0].protocolVersion,
      applicationVersion: data[0].applicationVersion,
      raw: data[0].raw,
      time: data[0].time,
      serviceType: type,
    })

    if (result.isLeft()) {
      return left(result.value)
    }

    return right({
      rssiList: [data[0].rssi],
      data: [result.value],
    })
  }

  let rssiList: number[] = []
  let processDataArray: ServiceData[] = []

  for (let index = 0; index < data.length; index++) {
    const element = data[index]

    const result = processData({
      applicationVersion: element.applicationVersion,
      protocolVersion: element.protocolVersion,
      raw: element.raw,
      serviceType: element.serviceType,
      time: element.time,
    })

    if (result.isLeft()) {
      console.error(result.value)
      continue
    }

    rssiList.push(element.rssi)
    processDataArray.push(result.value)
  }

  return right({
    rssiList,
    data: processDataArray,
  })
}

export const processData = ({
  applicationVersion,
  protocolVersion,
  raw,
  time,
  serviceType,
}: {
  applicationVersion: string
  protocolVersion: string
  raw: string
  time: number
  serviceType: HDR_SERVICES_TYPE
}): Result<Error, ServiceData> => {
  let processData: Result<Error, ServiceData> = left(new Error())

  let configs: VersionProtocolApplication = {
    applicationVersion,
    protocolVersion,
  }

  switch (serviceType) {
    case HDR_SERVICES_TYPE.temp: {
      processData = getTemp(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.rms2: {
      processData = getRMS2(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.rmms: {
      processData = getRMMS(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.tilt: {
      processData = getTilt(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.fft: {
      processData = getFFT(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.accRaw: {
      processData = getAccRaw(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.ntc: {
      processData = getNtc(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.pot: {
      processData = getPot(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE._4t20: {
      processData = get4T20(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.health: {
      processData = getHealth(raw, time, configs)
      break
    }

    case HDR_SERVICES_TYPE.tempMMM: {
      processData = getTempMMM(raw, time, configs)
      break
    }

    default:
      return left(Error(`Process ${serviceType} not implemented yet!`))
  }

  if (processData.isLeft()) return left(Error(processData.value.message))

  return right(processData.value)
}

const getAxiosRequestConfig = (
  serviceType: HDR_SERVICES_TYPE,
  mac: string,
  period: number
): AxiosRequestConfig => {
  if (
    serviceType === HDR_SERVICES_TYPE.fft ||
    serviceType === HDR_SERVICES_TYPE.accRaw
  ) {
    return {
      headers: {
        macs: [mac],
      },
    }
  }

  const { startDate, endDate } = getStartAndEndDate(period)

  return {
    headers: {
      startDate: startDate,
      endDate: endDate,
      macs: [mac],
    },
  }
}

const getStartAndEndDate = (
  period: number
): { startDate: string; endDate: string } => {
  const dateNow = Date.now()

  const periodInMiliseconds = period * 1000

  const startDate = new Date(dateNow - periodInMiliseconds)
  const endDate = new Date(dateNow)

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }
}
