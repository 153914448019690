import { useContext, useState } from "react"
import { TooltipWrapper, Tooltip, Container } from "./styles"
import { Text } from "../../../ui/Text"
import { CaretDown, CaretUp } from "phosphor-react"
import { Checkbox } from "../../../ui/Checkbox"
import { FlexContainer } from "../../../ui/FlexContainer"
import { AssetsContext, AssetsContextDTO } from "../../context/assetsContext"
import { ChartConfig } from "../../../../store/api/analytics/entities"

interface ServiceItemProps {
  label: string
  isDisabled: boolean
  isHide: boolean
  axes: string[]
  checked: "true" | "false" | "indeterminate"
  checkedAxes: ChartConfig[]
  onChange: (checked: boolean) => void
  onChangeAxle: (axle: string, checked: boolean) => void
}

export const ServiceItem = ({
  label,
  isDisabled,
  isHide,
  axes,
  checked,
  checkedAxes,
  onChange,
  onChangeAxle,
}: ServiceItemProps) => {
  const { hasAxes } = useContext(AssetsContext) as AssetsContextDTO

  const [showAxes, setShowAxes] = useState(false)

  const handleOpenAxes = () => {
    if (axes.length === 0) return
    setShowAxes((state) => !state)
  }

  if (!hasAxes)
    return (
      <Container $hide={isHide}>
        <Checkbox
          disabled={isDisabled}
          checked={checked === "true"}
          onChange={(e) => onChange(e.target.checked)}
        />

        <FlexContainer
          direction='row'
          justify='space-between'
          padding='0 0 0 8px'
          gap={8}
          onClick={handleOpenAxes}
        >
          <Text>{label}</Text>
        </FlexContainer>
      </Container>
    )

  return (
    <Container $hide={isHide}>
      <Checkbox
        disabled={isDisabled}
        checked={checked === "true"}
        onChange={(e) => onChange(e.target.checked)}
        indeterminate={checked === "indeterminate"}
      />

      {showAxes && (
        <TooltipWrapper>
          <Tooltip>
            <FlexContainer gap={4} align='start'>
              {axes.map((axle) => (
                <FlexContainer key={axle} direction='row' gap={8}>
                  <Checkbox
                    checked={!!checkedAxes.find((e) => e === axle)}
                    onChange={(e) => onChangeAxle(axle, e.target.checked)}
                  />
                  <Text>{axle}</Text>
                </FlexContainer>
              ))}
            </FlexContainer>
          </Tooltip>
        </TooltipWrapper>
      )}

      <FlexContainer
        direction='row'
        justify='space-between'
        padding='0 0 0 8px'
        gap={8}
        onClick={handleOpenAxes}
      >
        <Text>{label}</Text>

        {showAxes && axes.length !== 0 && <CaretUp size={12} />}
        {!showAxes && axes.length !== 0 && <CaretDown size={12} />}
      </FlexContainer>
    </Container>
  )
}
