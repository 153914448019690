import { useCreateSensorAnalyticMutation } from "../../../store/store"
import { SensorAndService } from "../components/ModalConfigureSensors/components/SensorAndServices/entities/SensorAndService"
import { createsObject } from "../components/ModalWrapper/utils"
import { Analytics } from "../entities/Analytics"

export const useCreateSensorChart = () => {
  const [createSensorAnalytic, mutation] = useCreateSensorAnalyticMutation()

  const handleCreateSensorChart = async (
    analyticToPlot: Analytics | null,
    sensorAndServices: SensorAndService[],
    dashboardId: number
  ): Promise<void> => {
    if (!analyticToPlot) return

    analyticToPlot = {
      ...analyticToPlot,
      sensors: createsObject(sensorAndServices),
    }

    const configs: {
      [key: string]: Array<{
        mac: string
        name: string
        serial_number: string
      }>
    } = {}

    const algorithms = Object.keys(analyticToPlot.sensors)

    algorithms.forEach((algorithm) => {
      configs[algorithm] = analyticToPlot.sensors[algorithm].map((sensor) => ({
        mac: sensor.mac,
        serial_number: "",
        name: sensor.name,
      }))
    })

    await createSensorAnalytic({
      body: {
        type: analyticToPlot.type,
        period: analyticToPlot.period,
        dashboardId: analyticToPlot.dashboardId,
        tag: analyticToPlot.tag,
        configs,
      },
      request: { dashboardId },
    }).unwrap()
  }

  return {
    handleCreateSensorChart,
    isCreateSensorDisabled: mutation.isLoading,
  }
}
