import { ChangeEventHandler, useEffect, useRef, useState } from "react"
import {
  Container,
  PlaceholderContainer,
  Wrapper,
  ImageOptions,
} from "./styles"
import { Image, PencilSimple, Trash } from "phosphor-react"
import { Button } from "../../../../../components/ui/Button"
import { ImageContainerSkeleton } from "./ImageContainer.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import theme from "../../../../../styles/theme"
import { toast } from "react-toastify"
import { IconButton } from "../../../../../components/ui/IconButton"

interface ImageProps {
  src?: string
  editable?: boolean
  onChange?: (file: File | null) => void
}

const ImageContainer = ({ src, editable = false, onChange }: ImageProps) => {
  const { assets } = useAppTranslate()

  const inputRef = useRef<HTMLInputElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  const [file, setFile] = useState<File | undefined>(undefined)
  const [source, setSource] = useState<string | undefined>()

  useEffect(() => {
    setSource(src)
  }, [src])

  const hasImage = file || source

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const files = event.target.files

    if (!files) return
    if (files.length === 0) return

    const imageValidTypes = ["image/png", "image/jpeg", "image/jpg"]

    const file = files[0]

    if (!imageValidTypes.includes(file.type)) {
      toast.error(assets.form.imageError)
      return
    }

    setFile(file)
    onChange?.(file)
  }

  const handleAddImage = () => {
    inputRef.current?.click()
  }

  const handleDeleteImage = () => {
    setFile(undefined)
    setSource(undefined)
    if (inputRef.current) inputRef.current.value = ""

    onChange?.(null)
  }

  const handleOnError = () => {
    setFile(undefined)
    if (inputRef.current) inputRef.current.value = ""
  }

  const getImageUrl = (): string | undefined => {
    if (file) return URL.createObjectURL(file)
    if (source) return source

    return undefined
  }

  return (
    <Wrapper>
      <>
        <Container>
          <PlaceholderContainer>
            {hasImage && editable && (
              <ImageOptions>
                <FlexContainer direction='row' gap={10}>
                  <IconButton
                    variant='secondary'
                    onClick={handleAddImage}
                    icon={
                      <PencilSimple
                        size={24}
                        weight='bold'
                        color={theme.palette.gray[800]}
                      />
                    }
                  />

                  <IconButton
                    variant='secondary'
                    onClick={handleDeleteImage}
                    icon={
                      <Trash
                        size={24}
                        weight='bold'
                        color={theme.palette.error.main}
                      />
                    }
                  />
                </FlexContainer>
              </ImageOptions>
            )}

            {!hasImage && <Image size={96} />}

            {hasImage && (
              <img
                ref={imageRef}
                src={getImageUrl()}
                onError={handleOnError}
                alt='asset'
              />
            )}

            <>
              <input type='file' onChange={handleChange} ref={inputRef} />

              {!hasImage && editable && (
                <Button variant='link' onClick={handleAddImage} type='button'>
                  {assets.form.addImage}
                </Button>
              )}
            </>
          </PlaceholderContainer>
        </Container>
      </>
    </Wrapper>
  )
}

export { ImageContainer, ImageContainerSkeleton }
